<aside class="inquiry-aside m-aside fill" style="z-index: 100;">
	<section class="m-conversations-nav">
		<div [hidden]="!showConversationSection">
			<h2>Conversations</h2>
			<a class="nav-expand-f" (click)="showConversations=!showConversations">&#x2261;</a>
			<nav class="conversations-list" [hidden]="!showConversations">
				<ul>
					<li class="active-conversation">
						<span>{{activeSubject}}</span>
					</li>
					<li *ngFor="let item of conversations; index as i">
						<a [attr.href]="item.inquiryUrl">{{item.subject}}</a>
					</li>
				</ul>
			</nav>
		</div>

		<div style="display: none;">
			<h2>View Past Conversations</h2>
			<a class="nav-expand-f">&#x2261;</a>
			<p class="past-conversations">You have no past conversations</p>
			<nav class="conversations-list past-list">
				<ul>
					<li><a>Conversation Title 1</a></li>
					<li><a>Conversation Title 2</a></li>
					<li><a>Conversation Title 3</a></li>
				</ul>
			</nav>
		</div>
	</section>
</aside>