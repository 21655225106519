export class NavBarData {
	token: string;
	premiumUser: string;
	userName: string;
	isAdmin: string;
	isJitRecruiter: string;

	constructor(token: string, premiumUser: string, userName: string, isAdmin: string, isJitRecruiter: string) {
		this.token = token;
		this.premiumUser = premiumUser;
		this.userName = userName;
		this.isAdmin = isAdmin;
		this.isJitRecruiter = isJitRecruiter;
	}
}
  