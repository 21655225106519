import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value){ 
      var val = value.toString();
      val = val.toLowerCase();
      return val.charAt(0).toLocaleUpperCase() + val.slice(1);
    }else{
      return null;
    }
  }

}
