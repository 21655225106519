import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Output() emitUrl: EventEmitter<any> = new EventEmitter<any>();
	items: any[]
	imgLogo;
	constructor( private router: Router,private themeService: ThemeService,) {
		this.items = [
			{ label: "sign up", url: "signup" },
			{ label: "sign in", url: "signin" },
			{ label: "home", url: "" },
			{ label: "services", url: "signUpPage" },
			{ label: "get a quote", url: "signUpPage" },
		];
	}

	ngOnInit(): void {
		this.imgLogo = this.themeService.logoNavBar;
	}

	goTo(url: string) {
		this.router.navigate([url]);
	}

}