import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CookiesManagementService } from '../../../core/services/cookies-management.service';
import { HttpService } from '../../../core/services/http.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { InquiryStepOneRequest } from 'src/app/core/models/inquiry-step1-request.model';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { Subject } from 'rxjs';
import { BannerNotificationType } from 'src/app/modules/shared/components/error-banner/notification-banner.component';
import { InquiryStepOneResponse } from 'src/app/core/models/inquiry-step1-response.model';
import { HttpHeaders } from '@angular/common/http';
import { ModalService } from 'src/app/modules/shared/modal/modal.service';
import { ActivatedRoute } from '@angular/router';


const SIGNIN_MODAL = "signin_modal";

@Component({
	selector: 'app-inquiry',
	templateUrl: './inquiry.component.html',
	styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {

	eventsSubject: Subject<NotificationData> = new Subject<NotificationData>();

	inquirySubmitForm = this.formBuilder.group({
		projectDescription: ''
	}, { updateOn: 'submit' });

	submitted: Boolean = false;
	recipientId: string | undefined;

	constructor(private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document, private formBuilder: UntypedFormBuilder, private httpClient: HttpService,
		private cookieService: CookiesManagementService, private modalService: ModalService) { }

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.recipientId = params['expertId'];
		});
		if (this.recipientId) console.log("EXPERT ID > ", this.recipientId);
		if (this.cookieService.getCookie(GlobalConstants.INQUIRY_1_DESCRIPTION).length > 3) {
			this.inquirySubmitForm.controls.projectDescription.setValue(this.cookieService.getCookie(GlobalConstants.INQUIRY_1_DESCRIPTION));
		}
	}

	onSubmit(): void {
		this.cookieService.deleteCookie(GlobalConstants.INQUIRY_1_DESCRIPTION);
		if (this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN).length > 5) { 
			this.submitted = true;
			if (this.inquirySubmitForm.valid) {
				console.log("VALID");

				let owner = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_EMAIL);
				let inquiryStepOneRequest = new InquiryStepOneRequest(this.inquirySubmitForm.controls.projectDescription.value, owner, "FAKE2345345", "s3cr3t", "WEB", "New Inquiry" );
				if (this.recipientId) {
					console.log("Posting 1to1 inquiry from owner: " + owner + " to: " + this.recipientId);
					inquiryStepOneRequest.recipientId = this.recipientId;
				}

				let headers = new HttpHeaders({
					'Content-Type': 'application/json',
					'app-token': this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN) 
				});

				// let options = { headers: headers };
				this.httpClient.postWithHeader(GlobalConstants.URL_INQUIRY1_SUBMIT, inquiryStepOneRequest, null, headers).subscribe(data => {
					let inquiryStepOneResponse = <InquiryStepOneResponse> data ;
					window.location.href = GlobalConstants.URL_INQUIRY_STEP_2 + "/" + inquiryStepOneResponse.inquiryUrl;
				}, error => {
					this.eventsSubject.next(new NotificationData(BannerNotificationType.Error, error.message));
				});
			} else {
				this.inquirySubmitForm.markAllAsTouched();
			}
		} else {
			this.cookieService.setCookie(GlobalConstants.INQUIRY_1_DESCRIPTION, this.inquirySubmitForm.controls.projectDescription.value);
			this.modalService.open(SIGNIN_MODAL);
		}
	}

	uponSignin(): void {
		window.location.reload();
	}
}
