import { Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { FooterMainLayoutComponent } from '../layout/footer-main-layout/footer-main-layout.component';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { ContentLayoutComponent } from '../modules/content-layout/content-layout.component';
import { AlchemerSurveyComponent } from '../root/pages/alchemer-survey/alchemer-survey.component';
import { AreasResolver } from '../resolvers/areas.resolver';
import { LogoutResolver } from '../resolvers/logout.resolver';
import { AreaComponent } from '../root/pages/area/area.component';
import { ForgotComponent } from '../root/pages/forgot/forgot.component';
import { InquiryComponent } from '../root/pages/inquiry/inquiry.component';
import { LoginComponent } from '../root/pages/login/login.component';
import { LogoutComponent } from '../root/pages/logout/logout.component';
import { RecoverPwComponent } from '../root/pages/recover-pw/recover-pw.component';
import { RegisterComponent } from '../root/pages/register/register.component';
import { UnsubscribeComponent } from '../root/pages/unsubscribe/unsubscribe.component';
import { VerificationComponent } from '../root/pages/verification/verification.component';
import { ConfirmAccountComponent } from '../root/pages/confirm-account/confirm-account.component';
import { AccountConfirmResolver } from '../resolvers/account-confirmation.resolver';
import { SurveyInformationResolver } from '../resolvers/survey-information.resolver';
import { WelcomeSurveyResolver } from '../resolvers/welcome-survey.resolver';

export const MAIN_ROUTES: Routes = [
	{
		path: "content",
		component: ContentLayoutComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import("../modules/content-layout/content-layout.module").then(m => m.ContentLayoutModule)
	},
	// {
	// 	path: "signin",
	// 	component: LoginComponent,
	// },
	{
		path: "payment-signup",
		component: RegisterComponent,
		data: { fromPaymentSignup: true }
	},
	{
		path: "signin",
		component: RegisterComponent,
	},
	{
		path: "signup",
		component: RegisterComponent
	},
	{
		path: "login",
		component: RegisterComponent
	},
	{
		path: "register",
		component: RegisterComponent
	},
	{
		path: "forgot",
		component: RegisterComponent
	},
	{
		path: "changePassword",
		component: RecoverPwComponent
	},
	{
		path: "verification",
		component: VerificationComponent
	},
	{
		path: "logout",
		component: LogoutComponent,
		resolve: { data: LogoutResolver }
	},
	{
		path: "inquiry1",
		component: MainLayoutComponent,
		children: [
			{ path: '', component: InquiryComponent },
			{ path: ':expertId', component: InquiryComponent }
		]
	},
	{
		path: 'confirmAccount/:user_id/:token',
		component: ConfirmAccountComponent,
		resolve: {
			activationInfo: AccountConfirmResolver
		}
	},
	{
		path: 'new_area',
		component: FooterMainLayoutComponent,
		children: [
			{ path: '', resolve: { areas: AreasResolver }, component: AreaComponent },
			{ path: ':areaName', resolve: { areas: AreasResolver }, component: AreaComponent }
		]
	},
	{
		path: "unsubscribe",
		component: UnsubscribeComponent
	},
	{
		path: "alchemerSurvey/:prop_id",
		component: AlchemerSurveyComponent,
		resolve: { info: SurveyInformationResolver }
	},
	{
		path: "survey/:prop_id",
		component: AlchemerSurveyComponent,
		resolve: { info: SurveyInformationResolver }
	},
	{
		path: "welcome_survey",
		component: AlchemerSurveyComponent,
		resolve: { info: WelcomeSurveyResolver }
	},
	{
		path: "",
		component: RegisterComponent
	},
];

export const routingComponents = [InquiryComponent, AreaComponent];

export function inquiryTwoUrlMatcher(segments: UrlSegment[]) {
	// match urls like "/inquiry2/:inquiry_url" where inquiry_url can contain '/'
	if (segments.length > 0) {
		// if first segment is 'inquiry2', then concat all the next segments into a single one and return it as a parameter named 'inquiry_url'
		if (segments[0].path == "inquiry2") {
			return {
				consumed: segments,
				posParams: {
					inquiry_url: new UrlSegment(segments.slice(1).join("/"), {})
				}
			}
		}
	}

	return null;
}