import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../core/services/http.service';
import { UserInfo } from '../core/models/user-info.model';
import { environment as env } from '../../environments/environment';

const apiUrl: string = env.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private userInfo: UserInfo = new UserInfo();
	updateImage: Subject<boolean> = new Subject<boolean>();
	showUserName: Subject<string> = new Subject<string>();

	constructor(private httpService: HttpService) { }

	public getUserLocalInfo() {
		return this.userInfo;
	}

	public changeImage(value: boolean) {
		this.updateImage.next(value);
	}

	public getProfileUserInfo(): Observable<UserInfo> {
		let action: string = '/profile_user_information';
		return this.httpService.get(apiUrl + action);
	}

	public getMyAccountUserInfo(): Observable<UserInfo> {
		let action: string = '/account_user_information';
		return this.httpService.get(apiUrl + action);
	}

	public saveAccInformation(user: any): Observable<any> {
		let action: string = '/acc_information';
		return this.httpService.post(apiUrl + action, user);
	}

	public savePrivateInformation(user: any): Observable<any> {
		let action: string = '/private_information';
		return this.httpService.post(apiUrl + action, user);
	}

	public saveProfessionalInfo(user: any): Observable<any> {
		let action: string = '/professional_information';
		return this.httpService.post(apiUrl + action, user);
	}

	public savePersonalInfo(user: any): Observable<any> {
		let action: string = '/personal_information';
		return this.httpService.post(apiUrl + action, user);
	}

	public sendPhoneSMS(user?: any): Observable<any> {
		let action: string = '/verification/send_sms';
		return this.httpService.post(apiUrl + action, user);
	}

	public validatePhoneCode(code: string): Observable<any> {
		let action: string = '/verification/validate_phone/' + code;
		return this.httpService.get(apiUrl + action);
	}

	public updateUserPicture(pic): Observable<any> {
		let action = "/user_picture"
		return this.httpService.post(apiUrl + action, pic);
	}

	public updateUserPhone(phone): Observable<any> {
		let action: string = "/user_phone_number";
		return this.httpService.post(apiUrl + action, phone);
	}

	public updatePassword(pw: any): Observable<any> {
		let action: string = "/update_password";
		return this.httpService.post(apiUrl + action, pw)
	}

	public getScreening(): Observable<any> {
		let action: string = "/get_screening";
		return this.httpService.post(apiUrl + action)
	}

	public unsubscribeEmail(data: any): Observable<any> {
		let action: string = "/mail_open_tracking/check_unsubscribe";
		return this.httpService.post(apiUrl + action, data)
	}

	public createNewFop(data: any): Observable<any> {
		let action: string = "/new_admin/save_email_or_phone";
		return this.httpService.post(apiUrl + action, data)
	}

	public removeFop(data: any): Observable<any> {
		let action: string = "/new_admin/delete_fop";
		return this.httpService.post(apiUrl + action, data)
	}

	public setUserInfo(userLogin: UserInfo) {
		localStorage.setItem("userInfo", JSON.stringify(userLogin));

		this.userInfo.first_name = userLogin.first_name;
		this.userInfo.last_name = userLogin.last_name;
		this.userInfo.email = userLogin.email_address;
		this.userInfo.email_address = userLogin.email_address;
		this.userInfo.is_admin = userLogin.is_admin;
		this.userInfo.is_root_admin = userLogin.is_root_admin;
		this.userInfo.is_jit_recruiter = userLogin.is_jit_recruiter;
		this.userInfo.is_am = userLogin.is_am;
		this.userInfo.id = userLogin.id;
		this.userInfo.always_skip = userLogin.always_skip;
		this.userInfo.always_skip_date = userLogin.always_skip_date;
		this.userInfo.always_skip_my_account = userLogin.always_skip_my_account;
		this.userInfo.always_skip_projects = userLogin.always_skip_projects;
		this.userInfo.always_skip_profile = userLogin.always_skip_profile;
		this.showUserName.next(this.userInfo.first_name + " " + this.userInfo.last_name);
	}

	public updateUserNameAndEmail(info: any) {
		let user = JSON.parse(localStorage.getItem("userInfo"));
		user.first_name = info.first_name;
		user.last_name = info.last_name;
		user.email = info.email;
		user.email_address = info.email;
		this.showUserName.next(user.first_name + " " + user.last_name);
		localStorage.setItem("userInfo", JSON.stringify(user));
	}

	public skipWizard(data: any) {
		let action = "/skip_first_login";
		return this.httpService.post(apiUrl + action, data);
	}

	public setSkipWizard(userLogin) {
		this.userInfo.always_skip = userLogin.always_skip;
		this.userInfo.always_skip_my_account = userLogin.always_skip_my_account;
		this.userInfo.always_skip_projects = userLogin.always_skip_projects;
		this.userInfo.always_skip_profile = userLogin.always_skip_profile;
	}

	public setIntercomHash(info: any) {
		this.userInfo.user_intercom_hash = info.user_intercom_hash;
	}

}
