export class SignupRequest {
	emailAddress: string;
	password: string;
	device: string;
	isSignUp: boolean;
	pepe: string;
	accountForPayment: boolean = false;
	first_name: string | 'New';
	last_name: string | 'User';
	age: number | undefined;
	country: string | undefined;
	state: string | undefined;
	current_job_title: string | undefined;
	seniority: string | undefined;
	job_description : string | undefined;
	industry: string | undefined;
	company_name: string | undefined;
	company_headcount: number | undefined;
	zipCode: string | undefined;

	constructor(emailAddress: string, password: string, device: string, isSignUp: boolean, captchaToken: string) {
		this.emailAddress = emailAddress.toLocaleLowerCase();
		this.password = password;
		this.device = device;
		this.isSignUp = isSignUp;
		//this.pepe = captchaToken;
	}
}
