import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './components/banner/banner.component';
import { NotificationBannerComponent } from './components/error-banner/notification-banner.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrimengModule } from './primeng/primeng.module';
import { LoggedHeaderComponent } from './components/logged-header/logged-header.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './components/table/table.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { TwoFactorPhoneComponent } from './components/two-factor-phone/two-factor-phone.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { EditorModule } from 'primeng/editor';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { CountryFlagDirective } from 'src/app/core/directives/country-flag.directive';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProjectInformationComponent } from './components/project-information/project-information.component';
import { ScreeningViewerComponent } from './components/screening-viewer/screening-viewer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DefaultImageDirective } from 'src/app/core/directives/default-image.directive';
import { StateSelectorComponent } from './components/state-selector/state-selector.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LinkifyPipe } from "../../linkify.pipe";
import { AutocompleteComponent } from "../../autocomplete/autocomplete.component";
import { HighlightPipe } from './pipes/highlight.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';


@NgModule({
	declarations: [
		BannerComponent,
		NotificationBannerComponent,
		HeaderComponent,
		FooterComponent,
		LoggedHeaderComponent,
		DatepickerComponent,
		TwoFactorPhoneComponent,
		UploadFilesComponent,
		TableComponent,
		CountrySelectorComponent,
		CountryFlagDirective,
		ResetPasswordComponent,
		ProjectInformationComponent,
		ScreeningViewerComponent,
		DefaultImageDirective,
		StateSelectorComponent,
		LanguageSelectorComponent,
		LinkifyPipe,
		AutocompleteComponent,
		HighlightPipe,
		CapitalizePipe
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PrimengModule,
		NgxIntlTelInputModule,
		ReactiveFormsModule,
		EditorModule,
		MomentTimezonePickerModule,
	],
	exports: [
		BannerComponent,
		NotificationBannerComponent,
		HeaderComponent,
		FooterComponent,
		PrimengModule,
		FormsModule,
		LoggedHeaderComponent,
		DatepickerComponent,
		TwoFactorPhoneComponent,
		UploadFilesComponent,
		NgxIntlTelInputModule,
		ReactiveFormsModule,
		EditorModule,
		MomentTimezonePickerModule,
		CountrySelectorComponent,
		CountryFlagDirective,
		ResetPasswordComponent,
		ProjectInformationComponent,
		ScreeningViewerComponent,
		ImageCropperModule,
		DefaultImageDirective,
		StateSelectorComponent,
		LanguageSelectorComponent,
		LinkifyPipe,
		AutocompleteComponent,
		HighlightPipe,
		TableComponent,
		CapitalizePipe

	]
})
export class SharedModule { }
