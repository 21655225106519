<div class="new_login_container">
    <div class="row">
        <div [ngClass]="loginFormContainer">
            <div class="logo_container">
                <img [src]="imgLogo" style="max-width: 80px;">
                <span class="new_logo_text">{{brandName}}</span>
            </div>
            <div class="new_info_container">
                <form [formGroup]="signinForm" (ngSubmit)="onSubmit()" style="width: 100%;">
                    <div class="item">
                        <label class="new_form_sub_title">Email</label>
                        <input required ngModel email=true type="email" class="emailname new_input" formControlName="email" name="email"/>
                    </div>
                    <div *ngIf="submitted && signinForm.controls.email.errors?.required" class="m-form-error error-e input_error" style="display: block; margin-top: -20px;">Email is Required</div>
                    <div *ngIf="submitted && signinForm.controls.email.errors?.email" class="m-form-error error-e input_error" style="display: block; margin-top: -20px;">Enter a valid email</div>
                    <div *ngIf="otlError" class="m-form-error error-e input_error" style="display: block; margin-top: -20px;">Email is Required</div>
                    <div class="item" style="position: relative;">
                        <label class="new_form_sub_title">Password</label>
                        <input required minlength="6" [type]="passwordType" class="new_input" formControlName="pswd" id="pswd" />
                        <i [ngClass]="passwordType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="togglePassword()"></i>
                    </div>
                    <div *ngIf="submitted && signinForm.controls.pswd.errors?.required" class="m-form-error error-e input_error" style="display: block; margin-top: -20px;">Password is Required</div>
    
                    <div class="btn_with_icon">
                        <button class="btn_signin">Sign In</button>
                    </div>
                    <div>
                        <button type="button" class="btn_otlogin" (click)="oneTimeLogin()">One Time Login</button>
                    </div>
                    <div class="forgot">
                        <span (click)="goTo('forgot')">Forgot password?</span>
                    </div>
                    <hr style="border: solid 1px gray">
                    <div class="btn_with_icon">
                        <button (click)="goTo('signup')" class="btn_createAccount">New to {{brandName}}? Create Account</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-7 banner" [style.background]="'url(' + backgroundUrl + ')'" *ngIf="showImage">
            <div class="marketing-banner-text">
                <!-- Lorem Ipsum
                <span>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."</span> -->
                <!-- Let's create the future <br> <strong>together.</strong> -->
            </div>
        </div>
    </div>
</div>