import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Intercom } from 'ng-intercom';
import { environment } from 'src/environments/environment';

declare global {
	interface Window {
		intercomSettings: any;
	}
}

@Injectable({
	providedIn: 'root',
})

export class IntercomService {
	intercomSettings: any = {
		api_base: 'https://api-iam.intercom.io',
		app_id: environment.intercomKey,
		name: undefined,
		email: undefined
	};

	constructor(private userService: UserService, private intercom: Intercom) { }

	initializeIntercom(name: string = undefined, email: string = undefined): void {
		if (name != undefined) {
			this.intercomSettings.name = name;
			this.intercomSettings.email = email;
		}

		window.intercomSettings = this.intercomSettings;
		const w: any = window;
		const ic: any = w.Intercom;

		if (typeof ic === 'function') {
			ic('reattach_activator');
			ic('update', w.intercomSettings);
		} else {
			const d = document;
			const i = function () {
				i.c(arguments);
			};

			i.q = [];
			i.c = function (args: any) {
				i.q.push(args);
			};

			w.Intercom = i;

			const l = function () {
				const s = d.createElement('script');
				s.type = 'text/javascript';
				s.async = true;
				s.src = 'https://widget.intercom.io/widget/i7kc6wj5';

				const x = d.getElementsByTagName('script')[0];
				x.parentNode.insertBefore(s, x);
			};

			if (document.readyState === 'complete') {
				l();
			} else if (w.attachEvent) {
				w.attachEvent('onload', l);
			} else {
				w.addEventListener('load', l, false);
			}
		}
	}

	bootIntercom(): void {
		setTimeout(() => {
			let userInfo = this.userService.getUserLocalInfo();
			let userName = userInfo.first_name + ' ' + userInfo.last_name;
			if (userInfo.first_name){
				userName = `${userInfo.first_name}`;
				if (userInfo.last_name){
					userName += ` ${userInfo.last_name}`;
				}
			}else{
				if (userInfo.last_name){
					userName = `${userInfo.last_name}`;
				}else{
					if(userInfo.email_address){
						userName = `${userInfo.email_address}`;
					}else{
						userName = `no_data`;
					}
				}
			}
			if (userName == 'New User') {
				userName = userInfo.email_address;
			}
			if (userName != `no_data`){
				let infoIntercom = {
					appId: environment.intercomKey,
					email: userInfo.email_address,
					user_hash: userInfo.user_intercom_hash,
					name: userName,
          tags: ['TestITP']
				};
				this.intercom.boot(infoIntercom);
			}
		}, 700);
	}

}
