import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { StepsModule } from 'primeng/steps';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		DropdownModule,
		CalendarModule,
		TabViewModule,
		TableModule,
		DialogModule,
		InputTextModule,
		AccordionModule,
		InputTextareaModule,
		MultiSelectModule,
		FileUploadModule,
		TooltipModule,
		InputSwitchModule,
		RadioButtonModule,
		CheckboxModule,
		ColorPickerModule,
		StepsModule,
		CarouselModule,
		GalleriaModule
	],
	exports: [
		DropdownModule,
		CalendarModule,
		TabViewModule,
		TableModule,
		DialogModule,
		InputTextModule,
		AccordionModule,
		InputTextareaModule,
		MultiSelectModule,
		FileUploadModule,
		TooltipModule,
		InputSwitchModule,
		RadioButtonModule,
		CheckboxModule,
		ColorPickerModule,
		StepsModule,
		CarouselModule,
		GalleriaModule,
		OverlayPanelModule,
		AutoCompleteModule
	]
})
export class PrimengModule { }
