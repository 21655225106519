<form (ngSubmit)="onSubmit()">
	<div class="row">
		<div class="col-12 row_title">
			<i class="pi pi-lock"></i>
			<div class="private_public_indicator">Private project information</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-md-6">
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Zintro Project ID <span>*</span></span>
					<input pInputText type="text" value="{{newProjectModel.zid}}" ngModel
						[(ngModel)]="newProjectModel.zid" name="zid">
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 col_info">
					<span class="p-element fixed_label">Published at</span>
					<app-datepicker [isDisabled]="true" [date]="newProjectModel.creation"
						(dateEmition)="captureDate($event, 'creation')"></app-datepicker>
				</div>
				<div class="col-12 col-md-6 col_info">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">Interview deadline
						<span>*</span></span>
					<app-datepicker [date]="newProjectModel.deadline"
						(dateEmition)="captureDate($event, 'deadline')"></app-datepicker>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Industry <span>*</span>
					</span>
					<input pInputText type="text" value="{{newProjectModel.experience_industries}}" ngModel
						[(ngModel)]="newProjectModel.experience_industries" name="experience_industries">
				</div>
			</div>
		</div>

		<div class="col-12 col-md-6">
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label">Organizations to avoid</span>
					<input pInputText type="text" value="{{newProjectModel.organizations_to_avoid}}" ngModel
						[(ngModel)]="newProjectModel.organizations_to_avoid" name="organizations_to_avoid">
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-4 col_info">
					<span class="fixed_label"># Participants Needed</span>
					<input pInputText type="text" value="{{newProjectModel.expected_transactions}}" ngModel
						[(ngModel)]="newProjectModel.expected_transactions" name="expected_transactions">
				</div>
				<div class="col-12 col-md-4 col_info">
					<span class="fixed_label">Recruiter commission</span>
					<input pInputText type="text" value="{{newProjectModel.recruiter_commission_amount}}" ngModel
						[(ngModel)]="newProjectModel.recruiter_commission_amount" name="recruiter_commission_amount">
				</div>
				<div class="col-12 col-md-4 col_info">
					<span class="fixed_label">Currency</span>
					<p-dropdown [options]="currencies" placeholder="" optionLabel="type" ngModel
						[(ngModel)]="newProjectModel.currency" name="currency" optionValue="type">
					</p-dropdown>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label">
						Important client updates
					</span>
					<input pInputText type="text" value="{{newProjectModel.client_updates}}" ngModel
						[(ngModel)]="newProjectModel.client_updates" name="client_updates">
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12 row_title">
			<i class="pi pi-lock-open"></i>
			<div class="private_public_indicator">Public project information</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-md-6">
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Subject <span>*</span></span>
					<input pInputText type="text" [(ngModel)]="newProjectModel.subject" name="subject">
				</div>
			</div>
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Description <span>*</span></span>
						<p-editor [(ngModel)]="newProjectModel.ideal_experts" [style]="{'height':'130px'}" name="ideal_experts" id="ideal"></p-editor>
					<!-- <textarea pInputTextarea value="{{newProjectModel.ideal_experts}}" ngModel
						[(ngModel)]="newProjectModel.ideal_experts" name="ideal_experts" style="height: 130px;"></textarea> -->
				</div>
			</div>
		</div>

		<div class="col-12 col-md-6">
			<div class="row">
				<div class="col-12 col_info">
					<span class="fixed_label">Screening Link</span>
					<input pInputText type="text" name="screening"
						[(ngModel)]="newProjectModel.screening_questions_link" ngModel>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-4 col_info dropsEdit">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Type of Engagement <span>*</span></span>
					<p-dropdown [options]="methodologies" placeholder="" optionLabel="name" ngModel optionValue="name"
						[(ngModel)]="newProjectModel.methodology" name="methodology">
					</p-dropdown>
				</div>
				<div class="col-12 col-md-4 col_info dropsEdit">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Length of interview <span>*</span>
					</span>
					<p-dropdown [options]="times" placeholder="" optionLabel="label" ngModel optionValue="time"
						[(ngModel)]="newProjectModel.call_duration" name="call_duration"> </p-dropdown>
				</div>
				<div class="col-12 col-md-4 col_info dropsEdit">
					<span class="fixed_label" pTooltip="Required" tooltipPosition="top">
						Participant incentive <span>*</span></span>
					<input pInputText type="text" value="{{newProjectModel.incentive}" ngModel
						[(ngModel)]="newProjectModel.incentive" name="incentive">
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 col_info dropsEdit">
					<span class="fixed_label">
						Country
					</span>
					<app-country-selector [selectedCountryCode]="newProjectModel.work_country"
						(changedCountryCode)="bindCountry($event)"></app-country-selector>
				</div>
				<!-- <div class="col-12 col-md-6 col_info" style="align-items: flex-start;">
					<span class="fixed_label">Recording & Transcription</span>
					<p-inputSwitch [(ngModel)]="newProjectModel.recording_and_transcription" ngModel
						name="recording" class="inputswitch"></p-inputSwitch>
				</div> -->
			</div>
		</div>
	</div>

	<div class="row" *ngIf="!isEdit">
		<div class="col-12">
			<div class="btn_container">
				<button class="customButton" name="submitButton" type="submit" style="float: right;">CREATE</button>
			</div>
		</div>
	</div>
</form>