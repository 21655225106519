import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
	providedIn: 'root'
})
export class AreaManagementService {
	area?: string = '';

	constructor(private httpClient: HttpService) { }

	loadAreaPage(areaName?: string): Observable<any> {
		console.log("AreaManagementService >> loadAreaPage");

		return this.httpClient.get('/area/load_new_area_page/' + areaName);

		/*
				return this.httpClient.get(this.employee_api_url + '/read')
					.pipe(map((resp: any) => resp.json()),
					catchError(error => this.throwError(error))
				)
		*/
	}

	setArea(area: string): void {
		this.area = area;
	}
	getArea() {
		return this.area;
	}

	throwError(error: any) {
		console.error(error);
		return Observable.throw(error.json().error || 'Server error');
	}
}