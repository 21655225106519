import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InquiryConversationListResponse } from '../models/inquiry-conversation-list-response.model';

@Injectable({
	providedIn: 'root'
})
export class InquiryTwoEventService {

	private emitChangeSource = new Subject<any>();

	changeEmitted$ = this.emitChangeSource.asObservable();

	emitChange(response: InquiryConversationListResponse) {
        this.emitChangeSource.next(response);
    }
}