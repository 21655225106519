import { Component } from '@angular/core';
import { ThemeService } from './services/theme.service';
import { Title } from '@angular/platform-browser';
import { IntercomService } from './services/intercom.service';
import { NavigationEnd, Router } from '@angular/router';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {

	title = 'angular-webclient';
	favIcon: HTMLLinkElement = document.querySelector("#appIcon");

	constructor(
		private themeService: ThemeService,
		private titleService: Title,
		private intercomService: IntercomService,
		private router: Router) {

		this.favIcon.href = this.themeService.favicon;
		this.titleService.setTitle(this.themeService.name);
		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				this.titleService.setTitle(`${this.themeService.name}  ${this.processTitleUrL(this.router.url)}`);
			}
		});
	}

	processValidTitles(title: string): string {
		title = title.toLocaleLowerCase();
		let res: string;
		let findSub: boolean;
		let sub_projects = [`active`, `finished`,`new_project`,`invite`,`completed_calls`];
		if (title.startsWith('p-') || title.toLowerCase().startsWith('i-')) {
			res = 'Survey';
		} else {
			sub_projects.forEach( el => {
				if (title.indexOf(el) >= 0){
					findSub = true;
				}
			});
			if(findSub){
				res = `Projects`;
			}else{
				res = title;
			}
		}
		return res;
	}

	processTitleUrL(url: string): string {
		let splitted = url.split('/');
		console.log("SPLITTED: ", splitted);
		return splitted.length > 0 && splitted[splitted.length - 1] != '' ? ` - ${this.processValidTitles(splitted[splitted.length - 1].toString()).toUpperCase()}` : '';
	}

	ngOnInit(): void {
		this.router.events.subscribe(evt => {
			if (evt instanceof NavigationEnd) {
				this.intercomService.bootIntercom();
			}
		});
	}
}
