import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-logged-header',
	templateUrl: './logged-header.component.html',
	styleUrls: ['./logged-header.component.scss']
})
export class LoggedHeaderComponent implements OnInit {


	name;
	imgLogo;
	items: any;
	user;
	timestamp;
	userFullInformation;
	showProfileMenu: boolean;
	displayChangePw: boolean;
	constructor(private router: Router,
		private userService: UserService,
		private themeService: ThemeService,
		private authService: AuthService,
		private elementRef: ElementRef) {
		this.items = [
			{ label: "projects", url: "content/projects" },
			{ label: "my account", url: "content/myAccount" },
			//{ label: "help center", url: "content/helpCenter" },
			//{ label: 'about me', url: 'content/aboutMe' }
		]
	}
	@HostListener('document:click', ['$event'])
	onClick(event: MouseEvent) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.showProfileMenu = false;
		}
	}

	ngOnInit(): void {
		this.name = this.userService.getUserLocalInfo().first_name + " " + this.userService.getUserLocalInfo().last_name;
		this.user = this.userService.getUserLocalInfo();

		this.userService.getProfileUserInfo().subscribe(data => {
			this.userFullInformation = data;
			this.userService.setSkipWizard(data);
			this.userService.setIntercomHash(data);
		});

		this.imgLogo = this.themeService.logoNavBar;
		this.userService.showUserName.subscribe(data => {
			this.name = data;
		});

		this.userService.updateImage.subscribe(info => {
			this.timestamp = info;
		});
	}

	goTo(url: string) {
		this.showProfileMenu = false;
		this.router.navigate([url]);
	}

	getLinkPicture() {
		let link = "/api/profile/picture";
		if (this.timestamp) {
			return link + "?" + this.timestamp;
		} else {
			return link;
		}
	}

	logout() {
		this.authService.logout().subscribe(() => { this.goTo('/login') });
	}

	captureModalAction(action) {
		this.displayChangePw = false;
	}

}
