import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { SpecificProjectModelRequest } from '../core/models/specific-project-request-model'
import NewProject from '../core/models/new-project-model';
import { environment as env } from '../../environments/environment';
import CompletedProject from '../core/models/completed-project-model';
import SyncToVendorRequest from '../core/models/sync-vendor-model';
import { Observable } from 'rxjs';

const endpoint: string = env.apiUrl + "/content/projects";

@Injectable({
	providedIn: 'root'
})
export class ProjectService {

	constructor(private httpService: HttpService) { }

	getSpecificProjectInfo(req: SpecificProjectModelRequest): Observable<NewProject> {
		let action = "/load/" + req.project_id;
		return this.httpService.get(endpoint + action);
	}

	getAllActiveProjectsAdmin() {
		let action = "/all_open_projects";
		return this.httpService.get(endpoint + action);
	}

	getInvitesForProject(project_id: string) {
		let action = "/all_invites/" + project_id;
		return this.httpService.get(endpoint + action);
	}

	setParticipantNotSelected(req) {
		let action = "/particpant_not_selected";
		return this.httpService.post(endpoint + action, req);
	}

	setParticipantSelectedCompleted(req: CompletedProject) {
		let action = "/participant_complete_inviation";
		return this.httpService.post(endpoint + action, req);
	}

	saveNewProject(req: NewProject) {
		let action = "/save_new_project";
		return this.httpService.post(endpoint + action, req);
	}

	updateProject(req: NewProject) {
		let action = `/update_project/${req.id}`;
		return this.httpService.put(endpoint + action, req);
	}

	getActiveProjects(request) {
		let action = "/active";
		return this.httpService.post(endpoint + action, request);
	}

	getCompletedProjects(request) {
		let action = "/completed";
		return this.httpService.post(endpoint + action, request);
	}

	syncToVendor(req: SyncToVendorRequest) {
		let action = "/sync_to_vendor";
		return this.httpService.post(endpoint + action, req);
	}

	getProjectVendorExists(project_id: string) {
		let action = "/exists/" + project_id;
		return this.httpService.get(endpoint + action);
	}

	closeProject(project) {
		let action = "/close";
		return this.httpService.post(endpoint + action, { project_id: project.id });
	}
	reopenProject(project) {
		let action = "/reopen";
		return this.httpService.post(endpoint + action, { project_id: project.id });
	}

	/*
		columns: [
			'zId',
			'domain_id',
			'subject',
			'ideal_experts',
			'creation',
			'last_client_interaction',
			'project_status',
			'project_timezone',
			'experience_industries',
			'methodology',
			'organizations_of_intereset',
			'anonymity_compliance_requirements',
			'work_city',
			'work_state',
			'work_country',
			'deadline',
			'involvement_level',
			'recording_and_transcription',
			'title_or_role',
			'currency',
			'organizations_to_avoid',
			'client_updates',
			'expected_transactions',
			'candidates_screened',
			'candidates_passed_screening',
			'approved_by_client',
			'recruiter_commission_amount',
			'incentive',
			'target_geography'
		]
	*/
}
