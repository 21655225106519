import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CookiesManagementService } from '../../../core/services/cookies-management.service';
import { HttpService } from '../../../core/services/http.service';
import { ModalService } from 'src/app/modules/shared/modal/modal.service';
import { ActivatedRoute } from '@angular/router';
import { AreaManagementService } from '../../../core/services/area-management.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AreaRequest } from 'src/app/core/models/area-request.model';
import { HttpHeaders } from '@angular/common/http';
import { fromEvent } from 'rxjs';

const SIGNIN_MODAL = "signin_modal";

@Component({
	selector: 'app-area',
	templateUrl: './area.component.html',
	styleUrls: ['./area.component.scss'],
	providers: [AreaManagementService]
})
export class AreaComponent implements OnInit {

	@ViewChild('salesForce') iframe: ElementRef;
	areaSubmitForm = this.formBuilder.group({
		firstName: '',
		lastName: '',
		email: '',
		company: '',
		noteToresearch_participants: ''
	}, { updateOn: 'submit' });

	submitted: Boolean = false;
	areaName: string = '';
	left_title = ''; left_subtitle = ''; right_title = ''; right_subtitle = '';
	isLoaded: boolean = false;
	textAI?: string;

	constructor(
		private route: ActivatedRoute,
		@Inject(DOCUMENT) private document: Document,
		private formBuilder: UntypedFormBuilder,
		private httpClient: HttpService,
		private cookieService: CookiesManagementService,
		private modalService: ModalService,
		private areaService: AreaManagementService) {

	}

	ngOnInit(): void {
		// This data is loaded in AreasResolver
		let page_texts = this.route.snapshot.data.areas.page_texts;

		this.left_title = page_texts[0].block_text;
		this.left_subtitle = page_texts[1].block_text;
		this.right_title = page_texts[2].block_text;
		this.right_subtitle = page_texts[3].block_text;
		this.areaName = this.route.snapshot.data.areas.area;
		this.textAI = this.route.snapshot.data.areas.textAI;
	}


	onSubmit(): void {
		this.cookieService.deleteCookie(GlobalConstants.INQUIRY_1_DESCRIPTION);
		if (this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN).length > 5) {
			this.submitted = true;
			if (this.areaSubmitForm.valid) {
				console.log("VALID 2");

				console.log("FORM VALUES 1 > ", this.areaSubmitForm.controls);
				let request = new AreaRequest(
					this.areaSubmitForm.controls.firstName.value,
					this.areaSubmitForm.controls.lastName.value,
					this.areaSubmitForm.controls.email.value,
					this.areaSubmitForm.controls.company.value,
					this.areaSubmitForm.controls.noteToresearch_participants.value
				);
				console.log("FORM VALUES 2 > ", request);

				let headers = new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'mode': 'no-cors'
				});
				let salesforce_url = 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';

				this.httpClient.directPostWithHeader(salesforce_url, request, '', headers).subscribe(data => {
					console.log("INFO RETURNED OK FROM SALESFORCE > ", data);
				}, error => {
					console.error("ERROR ON SALESFORCE > ", error);
				});
			} else {
				this.areaSubmitForm.markAllAsTouched();
			}
		} else {
			this.modalService.open(SIGNIN_MODAL);
		}
	}

	uponSignin(): void {
		window.location.reload();
	}
}
