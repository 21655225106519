import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout'
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
	selector: 'app-content-layout',
	templateUrl: './content-layout.component.html',
	styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit {

	user: any;
	isMobile: boolean;
	items;
	displayMenu: boolean = false;
	theme;
	skipTutorial: boolean = false;
	displayChangePw: boolean = false;
	maxWidthGallery: string = "640px";
	maxOptions: string = `{'max-width' : '${this.maxWidthGallery}'}`;
	
	constructor(
		private userService: UserService,
		private router: Router,
		private themeService: ThemeService,
		private breakpointObserver: BreakpointObserver) { }

	ngOnInit(): void {
		setTimeout(() => {
			this.user = this.userService.getUserLocalInfo();
		}, 500);

		let accesor = navigator.userAgent;
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(res => {
			this.maxWidthGallery = '640px';
		});
		this.breakpointObserver.observe(Breakpoints.Medium).subscribe(res => {
			if (res.matches) {
				this.maxWidthGallery = '950px';
			}
		})
		this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(res => {
			if (res.matches) {
				this.maxWidthGallery = '1500px';
			}
		})
		this.items = [
			{ label: "projects", url: "content/projects" },
			{ label: "my account", url: "content/myAccount" },
			{ label: "profile", url: "content/profile" },
			{ label: "change password", url: "changePw" },
			{ label: "logout", url: "/logout" },
		]
		this.manageViews(accesor);

		this.theme = this.themeService.contentTheme;
		//console.log("SOY EL THEME: >>>>>>>>>>>>>>>>>>>>", this.theme);
		// this.themeService.contentTheme.subscribe(activeTheme => {
		//   console.log("ACTIVE THEME", activeTheme)
		//   this.theme = activeTheme;
		// });
	}

	captureModalAction(action) {
		this.displayChangePw = false;
	}
	manageViews(accesor) {
		if (accesor.toString().toLowerCase().includes("android") || accesor.toString().toLowerCase().includes("iphone") || accesor.toString().toLowerCase().includes("ipad")) {
			this.isMobile = true;
		}
	}

	goTo(url: string) {
		if (url != 'changePw') {
			this.router.navigate([url]);
			this.displayMenu = false;
		} else {
			this.displayChangePw = true;
			this.displayMenu = false;
		}
	}

	showMenu() {
		this.displayMenu = !this.displayMenu;
	}

/*
	closeWizard() {
		if (this.skipTutorial) {
			console.log("SKIP");
			this.userService.skipWizard({
				"always_skip_date": true,
				"always_skip_my_account": true,
				"always_skip_projects": true,
				"always_skip_profile": true 
			}).subscribe(data => {
				console.log("DATA ", data);
			});
		} else {
			console.log("NO SKIP")
		}
		this.displayWizard = false;
	}
*/

}
