import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ProposalService } from '../services/proposal.service';

@Injectable({
	providedIn: 'root'
})
export class SurveyInformationResolver implements Resolve<any> {

	constructor(private proposalService: ProposalService, private route: ActivatedRoute) { }

	resolve(activatedRoute: ActivatedRouteSnapshot): Observable<any> {
		let propId = activatedRoute.paramMap.get('prop_id') || '0';
		return this.proposalService.getSurveyInformation(propId);
	}
}