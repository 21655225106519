<div class="screenning_container">
    <div *ngFor="let q of questions ; let i = index">
        <div [ngClass]=" (i%2==0) ? 'question' : 'answer'">
            <span class="title">{{q.question}}</span>
            <span class="">{{q.answer}}</span>
        </div>
    </div>
    <!-- <div class="answer">
        <span class="title">Answer</span>
        <span>this is the answer to the question</span>
    </div> -->
</div>