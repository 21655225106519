import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit(): void {
		console.info("LogoutComponent - ngOnInit");
		this.goTo('signin');
	}

	goTo(url: any) {
		console.log("URL:", url);
		this.router.navigate([url]);
	}

}
