import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';


@Component({
	selector: 'app-country-selector',
	templateUrl: './country-selector.component.html',
	styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit, OnChanges {


	countries;
	selectedCountry = undefined;
	@Input() selectedCountryCode = undefined;
	@Input() disabled: boolean = false;


	@Output() changedCountryCode = new EventEmitter<string>();





	constructor(
		private renderer: Renderer2,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		this.countries = this.newFlags();
	}


	ngOnInit(): void {

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.selectedCountryCode) {
			this.selectedCountry = this.searchCountry(this.selectedCountryCode);
		}
		console.log("SELECTED COUNTRY", this.selectedCountry)
	}


	private searchCountry(code: string) {
		let c = undefined;
		this.countries.forEach(country => {
			if (country.code == code) {
				{
					c = country;
				}
			}
		});
		return c;
	}

	selectCountry() {
		this.selectedCountryCode = this.selectedCountry.code;
		this.changedCountryCode.emit(this.selectedCountry.code);
	}

	private newFlags() {
		return [
			{ name: "Afghanistan", dial_code: "+93", code: "AF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/af.svg" },
			{ name: "Aland Islands", dial_code: "+358", code: "AX", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ax.svg" },
			{ name: "Albania", dial_code: "+355", code: "AL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/al.svg" },
			{ name: "Algeria", dial_code: "+213", code: "DZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/dz.svg" },
			{ name: "AmericanSamoa", dial_code: "+1684", code: "AS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/as.svg" },
			{ name: "Andorra", dial_code: "+376", code: "AD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ad.svg" },
			{ name: "Angola", dial_code: "+244", code: "AO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ao.svg" },
			{ name: "Anguilla", dial_code: "+1264", code: "AI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ai.svg" },
			{ name: "Antarctica", dial_code: "+672", code: "AQ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/aq.svg" },
			{ name: "Antigua and Barbuda", dial_code: "+1268", code: "AG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ag.svg" },
			{ name: "Argentina", dial_code: "+54", code: "AR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ar.svg" },
			{ name: "Armenia", dial_code: "+374", code: "AM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/am.svg" },
			{ name: "Aruba", dial_code: "+297", code: "AW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/aw.svg" },
			{ name: "Australia", dial_code: "+61", code: "AU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/au.svg" },
			{ name: "Austria", dial_code: "+43", code: "AT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/at.svg" },
			{ name: "Azerbaijan", dial_code: "+994", code: "AZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/az.svg" },
			{ name: "Bahamas", dial_code: "+1242", code: "BS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bs.svg" },
			{ name: "Bahrain", dial_code: "+973", code: "BH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bh.svg" },
			{ name: "Bangladesh", dial_code: "+880", code: "BD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bd.svg" },
			{ name: "Barbados", dial_code: "+1246", code: "BB", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bb.svg" },
			{ name: "Belarus", dial_code: "+375", code: "BY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/by.svg" },
			{ name: "Belgium", dial_code: "+32", code: "BE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/be.svg" },
			{ name: "Belize", dial_code: "+501", code: "BZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bz.svg" },
			{ name: "Benin", dial_code: "+229", code: "BJ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bj.svg" },
			{ name: "Bermuda", dial_code: "+1441", code: "BM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bm.svg" },
			{ name: "Bhutan", dial_code: "+975", code: "BT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bt.svg" },
			{ name: "Bolivia, Plurinational State of", dial_code: "+591", code: "BO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bo.svg" },
			{ name: "Bosnia and Herzegovina", dial_code: "+387", code: "BA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ba.svg" },
			{ name: "Botswana", dial_code: "+267", code: "BW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bw.svg" },
			{ name: "Brazil", dial_code: "+55", code: "BR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/br.svg" },
			{ name: "British Indian Ocean Territory", dial_code: "+246", code: "IO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/io.svg" },
			{ name: "Brunei Darussalam", dial_code: "+673", code: "BN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bn.svg" },
			{ name: "Bulgaria", dial_code: "+359", code: "BG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bg.svg" },
			{ name: "Burkina Faso", dial_code: "+226", code: "BF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bf.svg" },
			{ name: "Burundi", dial_code: "+257", code: "BI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bi.svg" },
			{ name: "Cambodia", dial_code: "+855", code: "KH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kh.svg" },
			{ name: "Cameroon", dial_code: "+237", code: "CM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cm.svg" },
			{ name: "Canada", dial_code: "+1", code: "CA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ca.svg" },
			{ name: "Cape Verde", dial_code: "+238", code: "CV", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cv.svg" },
			{ name: "Cayman Islands", dial_code: "+ 345", code: "KY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ky.svg" },
			{ name: "Central African Republic", dial_code: "+236", code: "CF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cf.svg" },
			{ name: "Chad", dial_code: "+235", code: "TD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/td.svg" },
			{ name: "Chile", dial_code: "+56", code: "CL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cl.svg" },
			{ name: "China", dial_code: "+86", code: "CN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cn.svg" },
			{ name: "Christmas Island", dial_code: "+61", code: "CX", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cx.svg" },
			{ name: "Cocos (Keeling) Islands", dial_code: "+61", code: "CC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cc.svg" },
			{ name: "Colombia", dial_code: "+57", code: "CO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/co.svg" },
			{ name: "Comoros", dial_code: "+269", code: "KM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/km.svg" },
			{ name: "Congo", dial_code: "+242", code: "CG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cg.svg" },
			{ name: "Congo, The Democratic Republic of the Congo", dial_code: "+243", code: "CD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cd.svg" },
			{ name: "Cook Islands", dial_code: "+682", code: "CK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ck.svg" },
			{ name: "Costa Rica", dial_code: "+506", code: "CR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cr.svg" },
			{ name: "Cote d'Ivoire", dial_code: "+225", code: "CI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ci.svg" },
			{ name: "Croatia", dial_code: "+385", code: "HR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/hr.svg" },
			{ name: "Cuba", dial_code: "+53", code: "CU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cu.svg" },
			{ name: "Cyprus", dial_code: "+357", code: "CY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cy.svg" },
			{ name: "Czech Republic", dial_code: "+420", code: "CZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/cz.svg" },
			{ name: "Denmark", dial_code: "+45", code: "DK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/dk.svg" },
			{ name: "Djibouti", dial_code: "+253", code: "DJ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/dj.svg" },
			{ name: "Dominica", dial_code: "+1767", code: "DM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/dm.svg" },
			{ name: "Dominican Republic", dial_code: "+1849", code: "DO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/do.svg" },
			{ name: "Ecuador", dial_code: "+593", code: "EC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ec.svg" },
			{ name: "Egypt", dial_code: "+20", code: "EG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/eg.svg" },
			{ name: "El Salvador", dial_code: "+503", code: "SV", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sv.svg" },
			{ name: "Equatorial Guinea", dial_code: "+240", code: "GQ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gq.svg" },
			{ name: "Eritrea", dial_code: "+291", code: "ER", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/er.svg" },
			{ name: "Estonia", dial_code: "+372", code: "EE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ee.svg" },
			{ name: "Ethiopia", dial_code: "+251", code: "ET", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/et.svg" },
			{ name: "Falkland Islands (Malvinas)", dial_code: "+500", code: "FK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/fk.svg" },
			{ name: "Faroe Islands", dial_code: "+298", code: "FO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/fo.svg" },
			{ name: "Fiji", dial_code: "+679", code: "FJ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/fj.svg" },
			{ name: "Finland", dial_code: "+358", code: "FI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/fi.svg" },
			{ name: "France", dial_code: "+33", code: "FR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/fr.svg" },
			{ name: "French Guiana", dial_code: "+594", code: "GF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gf.svg" },
			{ name: "French Polynesia", dial_code: "+689", code: "PF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pf.svg" },
			{ name: "Gabon", dial_code: "+241", code: "GA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ga.svg" },
			{ name: "Gambia", dial_code: "+220", code: "GM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gm.svg" },
			{ name: "Georgia", dial_code: "+995", code: "GE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ge.svg" },
			{ name: "Germany", dial_code: "+49", code: "DE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/de.svg" },
			{ name: "Ghana", dial_code: "+233", code: "GH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gh.svg" },
			{ name: "Gibraltar", dial_code: "+350", code: "GI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gi.svg" },
			{ name: "Greece", dial_code: "+30", code: "GR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gr.svg" },
			{ name: "Greenland", dial_code: "+299", code: "GL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gl.svg" },
			{ name: "Grenada", dial_code: "+1473", code: "GD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gd.svg" },
			{ name: "Guadeloupe", dial_code: "+590", code: "GP", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gp.svg" },
			{ name: "Guam", dial_code: "+1671", code: "GU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gu.svg" },
			{ name: "Guatemala", dial_code: "+502", code: "GT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gt.svg" },
			{ name: "Guernsey", dial_code: "+44", code: "GG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gg.svg" },
			{ name: "Guinea", dial_code: "+224", code: "GN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gn.svg" },
			{ name: "Guinea-Bissau", dial_code: "+245", code: "GW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gw.svg" },
			{ name: "Guyana", dial_code: "+595", code: "GY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gy.svg" },
			{ name: "Haiti", dial_code: "+509", code: "HT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ht.svg" },
			{ name: "Holy See (Vatican City State)", dial_code: "+379", code: "VA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/va.svg" },
			{ name: "Honduras", dial_code: "+504", code: "HN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/hn.svg" },
			{ name: "Hong Kong", dial_code: "+852", code: "HK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/hk.svg" },
			{ name: "Hungary", dial_code: "+36", code: "HU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/hu.svg" },
			{ name: "Iceland", dial_code: "+354", code: "IS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/is.svg" },
			{ name: "India", dial_code: "+91", code: "IN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/in.svg" },
			{ name: "Indonesia", dial_code: "+62", code: "ID", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/id.svg" },
			{ name: "Iran, Islamic Republic of Persian Gulf", dial_code: "+98", code: "IR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ir.svg" },
			{ name: "Iraq", dial_code: "+964", code: "IQ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/iq.svg" },
			{ name: "Ireland", dial_code: "+353", code: "IE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ie.svg" },
			{ name: "Isle of Man", dial_code: "+44", code: "IM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/im.svg" },
			{ name: "Israel", dial_code: "+972", code: "IL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/il.svg" },
			{ name: "Italy", dial_code: "+39", code: "IT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/it.svg" },
			{ name: "Jamaica", dial_code: "+1876", code: "JM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/jm.svg" },
			{ name: "Japan", dial_code: "+81", code: "JP", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/jp.svg" },
			{ name: "Jersey", dial_code: "+44", code: "JE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/je.svg" },
			{ name: "Jordan", dial_code: "+962", code: "JO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/jo.svg" },
			{ name: "Kazakhstan", dial_code: "+77", code: "KZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kz.svg" },
			{ name: "Kenya", dial_code: "+254", code: "KE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ke.svg" },
			{ name: "Kiribati", dial_code: "+686", code: "KI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ki.svg" },
			{ name: "Korea, Democratic People's Republic of Korea", dial_code: "+850", code: "KP", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kp.svg" },
			{ name: "Korea, Republic of South Korea", dial_code: "+82", code: "KR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kr.svg" },
			{ name: "Kuwait", dial_code: "+965", code: "KW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kw.svg" },
			{ name: "Kyrgyzstan", dial_code: "+996", code: "KG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kg.svg" },
			{ name: "Laos", dial_code: "+856", code: "LA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/la.svg" },
			{ name: "Latvia", dial_code: "+371", code: "LV", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lv.svg" },
			{ name: "Lebanon", dial_code: "+961", code: "LB", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lb.svg" },
			{ name: "Lesotho", dial_code: "+266", code: "LS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ls.svg" },
			{ name: "Liberia", dial_code: "+231", code: "LR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lr.svg" },
			{ name: "Libyan Arab Jamahiriya", dial_code: "+218", code: "LY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ly.svg" },
			{ name: "Liechtenstein", dial_code: "+423", code: "LI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/li.svg" },
			{ name: "Lithuania", dial_code: "+370", code: "LT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lt.svg" },
			{ name: "Luxembourg", dial_code: "+352", code: "LU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lu.svg" },
			{ name: "Macao", dial_code: "+853", code: "MO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mo.svg" },
			{ name: "Macedonia", dial_code: "+389", code: "MK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mk.svg" },
			{ name: "Madagascar", dial_code: "+261", code: "MG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mg.svg" },
			{ name: "Malawi", dial_code: "+265", code: "MW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mw.svg" },
			{ name: "Malaysia", dial_code: "+60", code: "MY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/my.svg" },
			{ name: "Maldives", dial_code: "+960", code: "MV", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mv.svg" },
			{ name: "Mali", dial_code: "+223", code: "ML", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ml.svg" },
			{ name: "Malta", dial_code: "+356", code: "MT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mt.svg" },
			{ name: "Marshall Islands", dial_code: "+692", code: "MH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mh.svg" },
			{ name: "Martinique", dial_code: "+596", code: "MQ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mq.svg" },
			{ name: "Mauritania", dial_code: "+222", code: "MR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mr.svg" },
			{ name: "Mauritius", dial_code: "+230", code: "MU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mu.svg" },
			{ name: "Mayotte", dial_code: "+262", code: "YT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/yt.svg" },
			{ name: "Mexico", dial_code: "+52", code: "MX", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mx.svg" },
			{ name: "Micronesia, Federated States of Micronesia", dial_code: "+691", code: "FM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/fm.svg" },
			{ name: "Moldova", dial_code: "+373", code: "MD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/md.svg" },
			{ name: "Monaco", dial_code: "+377", code: "MC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mc.svg" },
			{ name: "Mongolia", dial_code: "+976", code: "MN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mn.svg" },
			{ name: "Montenegro", dial_code: "+382", code: "ME", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/me.svg" },
			{ name: "Montserrat", dial_code: "+1664", code: "MS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ms.svg" },
			{ name: "Morocco", dial_code: "+212", code: "MA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ma.svg" },
			{ name: "Mozambique", dial_code: "+258", code: "MZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mz.svg" },
			{ name: "Myanmar", dial_code: "+95", code: "MM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mm.svg" },
			{ name: "Namibia", dial_code: "+264", code: "NA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/na.svg" },
			{ name: "Nauru", dial_code: "+674", code: "NR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/nr.svg" },
			{ name: "Nepal", dial_code: "+977", code: "NP", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/np.svg" },
			{ name: "Netherlands", dial_code: "+31", code: "NL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/nl.svg" },
			{ name: "New Caledonia", dial_code: "+687", code: "NC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/nc.svg" },
			{ name: "New Zealand", dial_code: "+64", code: "NZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/nz.svg" },
			{ name: "Nicaragua", dial_code: "+505", code: "NI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ni.svg" },
			{ name: "Niger", dial_code: "+227", code: "NE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ne.svg" },
			{ name: "Nigeria", dial_code: "+234", code: "NG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ng.svg" },
			{ name: "Niue", dial_code: "+683", code: "NU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/nu.svg" },
			{ name: "Norfolk Island", dial_code: "+672", code: "NF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/nf.svg" },
			{ name: "Northern Mariana Islands", dial_code: "+1670", code: "MP", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mp.svg" },
			{ name: "Norway", dial_code: "+47", code: "NO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/no.svg" },
			{ name: "Oman", dial_code: "+968", code: "OM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/om.svg" },
			{ name: "Pakistan", dial_code: "+92", code: "PK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pk.svg" },
			{ name: "Palau", dial_code: "+680", code: "PW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pw.svg" },
			{ name: "Palestinian Territory, Occupied", dial_code: "+970", code: "PS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ps.svg" },
			{ name: "Panama", dial_code: "+507", code: "PA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pa.svg" },
			{ name: "Papua New Guinea", dial_code: "+675", code: "PG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pg.svg" },
			{ name: "Paraguay", dial_code: "+595", code: "PY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/py.svg" },
			{ name: "Peru", dial_code: "+51", code: "PE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pe.svg" },
			{ name: "Philippines", dial_code: "+63", code: "PH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ph.svg" },
			{ name: "Pitcairn", dial_code: "+872", code: "PN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pn.svg" },
			{ name: "Poland", dial_code: "+48", code: "PL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pl.svg" },
			{ name: "Portugal", dial_code: "+351", code: "PT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pt.svg" },
			{ name: "Puerto Rico", dial_code: "+1939", code: "PR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pr.svg" },
			{ name: "Qatar", dial_code: "+974", code: "QA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/qa.svg" },
			{ name: "Romania", dial_code: "+40", code: "RO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ro.svg" },
			{ name: "Russia", dial_code: "+7", code: "RU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ru.svg" },
			{ name: "Rwanda", dial_code: "+250", code: "RW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/rw.svg" },
			{ name: "Reunion", dial_code: "+262", code: "RE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/re.svg" },
			{ name: "Saint Barthelemy", dial_code: "+590", code: "BL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/bl.svg" },
			{ name: "Saint Helena, Ascension and Tristan Da Cunha", dial_code: "+290", code: "SH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sh.svg" },
			{ name: "Saint Kitts and Nevis", dial_code: "+1869", code: "KN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/kn.svg" },
			{ name: "Saint Lucia", dial_code: "+1758", code: "LC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lc.svg" },
			{ name: "Saint Martin", dial_code: "+590", code: "MF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/mf.svg" },
			{ name: "Saint Pierre and Miquelon", dial_code: "+508", code: "PM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/pm.svg" },
			{ name: "Saint Vincent and the Grenadines", dial_code: "+1784", code: "VC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/vc.svg" },
			{ name: "Samoa", dial_code: "+685", code: "WS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ws.svg" },
			{ name: "San Marino", dial_code: "+378", code: "SM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sm.svg" },
			{ name: "Sao Tome and Principe", dial_code: "+239", code: "ST", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/st.svg" },
			{ name: "Saudi Arabia", dial_code: "+966", code: "SA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sa.svg" },
			{ name: "Senegal", dial_code: "+221", code: "SN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sn.svg" },
			{ name: "Serbia", dial_code: "+381", code: "RS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/rs.svg" },
			{ name: "Seychelles", dial_code: "+248", code: "SC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sc.svg" },
			{ name: "Sierra Leone", dial_code: "+232", code: "SL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sl.svg" },
			{ name: "Singapore", dial_code: "+65", code: "SG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sg.svg" },
			{ name: "Slovakia", dial_code: "+421", code: "SK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sk.svg" },
			{ name: "Slovenia", dial_code: "+386", code: "SI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/si.svg" },
			{ name: "Solomon Islands", dial_code: "+677", code: "SB", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sb.svg" },
			{ name: "Somalia", dial_code: "+252", code: "SO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/so.svg" },
			{ name: "South Africa", dial_code: "+27", code: "ZA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/za.svg" },
			{ name: "South Sudan", dial_code: "+211", code: "SS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ss.svg" },
			{ name: "South Georgia and the South Sandwich Islands", dial_code: "+500", code: "GS", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gs.svg" },
			{ name: "Spain", dial_code: "+34", code: "ES", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/es.svg" },
			{ name: "Sri Lanka", dial_code: "+94", code: "LK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/lk.svg" },
			{ name: "Sudan", dial_code: "+249", code: "SD", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sd.svg" },
			{ name: "Suriname", dial_code: "+597", code: "SR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sr.svg" },
			{ name: "Svalbard and Jan Mayen", dial_code: "+47", code: "SJ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sj.svg" },
			{ name: "Swaziland", dial_code: "+268", code: "SZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sz.svg" },
			{ name: "Sweden", dial_code: "+46", code: "SE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/se.svg" },
			{ name: "Switzerland", dial_code: "+41", code: "CH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ch.svg" },
			{ name: "Syrian Arab Republic", dial_code: "+963", code: "SY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/sy.svg" },
			{ name: "Taiwan", dial_code: "+886", code: "TW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tw.svg" },
			{ name: "Tajikistan", dial_code: "+992", code: "TJ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tj.svg" },
			{ name: "Tanzania, United Republic of Tanzania", dial_code: "+255", code: "TZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tz.svg" },
			{ name: "Thailand", dial_code: "+66", code: "TH", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/th.svg" },
			{ name: "Timor-Leste", dial_code: "+670", code: "TL", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tl.svg" },
			{ name: "Togo", dial_code: "+228", code: "TG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tg.svg" },
			{ name: "Tokelau", dial_code: "+690", code: "TK", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tk.svg" },
			{ name: "Tonga", dial_code: "+676", code: "TO", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/to.svg" },
			{ name: "Trinidad and Tobago", dial_code: "+1868", code: "TT", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tt.svg" },
			{ name: "Tunisia", dial_code: "+216", code: "TN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tn.svg" },
			{ name: "Turkey", dial_code: "+90", code: "TR", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tr.svg" },
			{ name: "Turkmenistan", dial_code: "+993", code: "TM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tm.svg" },
			{ name: "Turks and Caicos Islands", dial_code: "+1649", code: "TC", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tc.svg" },
			{ name: "Tuvalu", dial_code: "+688", code: "TV", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/tv.svg" },
			{ name: "Uganda", dial_code: "+256", code: "UG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ug.svg" },
			{ name: "Ukraine", dial_code: "+380", code: "UA", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ua.svg" },
			{ name: "United Arab Emirates", dial_code: "+971", code: "AE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ae.svg" },
			{ name: "United Kingdom", dial_code: "+44", code: "GB", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/gb.svg" },
			{ name: "United States", dial_code: "+1", code: "US", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/us.svg" },
			{ name: "Uruguay", dial_code: "+598", code: "UY", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/uy.svg" },
			{ name: "Uzbekistan", dial_code: "+998", code: "UZ", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/uz.svg" },
			{ name: "Vanuatu", dial_code: "+678", code: "VU", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/vu.svg" },
			{ name: "Venezuela, Bolivarian Republic of Venezuela", dial_code: "+58", code: "VE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ve.svg" },
			{ name: "Vietnam", dial_code: "+84", code: "VN", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/vn.svg" },
			{ name: "Virgin Islands, British", dial_code: "+1284", code: "VG", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/vg.svg" },
			{ name: "Virgin Islands, U.S.", dial_code: "+1340", code: "VI", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/vi.svg" },
			{ name: "Wallis and Futuna", dial_code: "+681", code: "WF", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/wf.svg" },
			{ name: "Yemen", dial_code: "+967", code: "YE", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/ye.svg" },
			{ name: "Zambia", dial_code: "+260", code: "ZM", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/zm.svg" },
			{ name: "Zimbabwe", dial_code: "+263", code: "ZW", flag: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.0/flags/4x3/zw.svg" }
		]
	}
}