import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GlobalConstants } from 'src/app/common/global-constants';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subject } from 'rxjs';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { BannerNotificationType } from 'src/app/modules/shared/components/error-banner/notification-banner.component';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-verification',
	templateUrl: './verification.component.html',
	styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

	constructor(private router: Router, private formBuilder: UntypedFormBuilder, private userService: UserService,
		private cookieService: CookiesManagementService, private authService: AuthService) { }

	eventsSubject: Subject<NotificationData> = new Subject<NotificationData>();

	verificationState = "NotSent";
	title = 'research_participants';
	submitted = false;

	verificationForm = this.formBuilder.group({
		code: ''
	}, {
		updateOn: 'submit'
	});

	ngOnInit(): void {
		let first_step = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_VERIFICATION_KEY);
		let user_id = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_USER_ID);

		if (first_step && user_id) {
			console.info("First step logged successfully");
		} else {
			console.info("Verification not allowed, missing signin");
			this.goTo(GlobalConstants.URL_SIGNIN_SLASH);
		}
	}

	sendSMSCode(): void {
		let user_id = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_USER_ID);
		this.userService.sendPhoneSMS({ user_id: user_id }).subscribe(response => {
			this.verificationState = "Sent";
			console.info("SMS SEND");
		}, error => {
			console.error("SMS ERROR RESPONSE: ", error);
		});
	}

	onSubmit(): void {
		console.info("ON SUBMIT");

		this.submitted = true;
		if (this.verificationForm.valid) {
			console.info("Form valid valid");

			let user_id = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_USER_ID);
			this.authService.twoStepCode(this.verificationForm.controls.code.value, user_id).subscribe(data => {
				console.info("VALIDATED CODE: ", data);

				this.cookieService.setLoginPostVerification(data.sessionToken);
				this.goTo(GlobalConstants.URL_PROJECTS);
			}, error => {
				console.info("Signin validateCode Error: " + JSON.stringify(error));
				this.eventsSubject.next(new NotificationData(BannerNotificationType.Error, error.error.briefError));
			});
		} else {
			console.info("Form invalid");
			this.verificationForm.markAllAsTouched();
		}
	}

	goTo(url: any) {
		this.router.navigateByUrl(url);
	}

}
