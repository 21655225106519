import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SecureLayoutComponent } from './secure-layout/secure-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FooterMainLayoutComponent } from './footer-main-layout/footer-main-layout.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild([])
	],
	exports: [
		FooterMainLayoutComponent,
		MainLayoutComponent,
		SecureLayoutComponent
	],
	declarations: [
		FooterComponent,
		SideBarComponent,
		NavBarComponent,
		FooterMainLayoutComponent,
		MainLayoutComponent,
		SecureLayoutComponent
	]
})
export class LayoutModule { }
