<!-- <div class="new_login_container">
	<div class="row">
		<div [ngClass]="loginFormContainer">
			<div class="logo_container">
				<img [src]="imgLogo" style="max-width: 80px;">
				<span class="new_logo_text">{{brandName}}</span>
			</div>
			<div class="new_info_container">
				<div *ngIf="information?.param == 'success'">
					<div class="welcome_container">
						<h3 class="new_form_sub_title">Welcome</h3>
						<img src="assets/new_check.png" class="img_check">
					</div>
					<div class="new_form_sub_title" style="margin-bottom:15px;"> {{information.msg}} </div>
				</div>

				<div *ngIf="information?.param == 'warning'">
					<div class="new_form_sub_title" style="margin-bottom:15px;"> {{information.msg}} </div>
				</div>

				<div *ngIf="information?.param == 'error'">
					<div class="welcome_container" style="margin-bottom:15px;">
						<div class="new_form_sub_title"> {{information.msg}} </div>
					</div>
				</div>

				<div class="btn_with_icon">
					<button (click)="goTo('signin')" class="btn_signin">Sign In</button>
				</div>
			</div>
		</div>
		<div class="col-7 banner" [style.background]="'url(' + backgroundUrl + ')'" *ngIf="showImage">
			<div class="marketing-banner-text">
			</div>
		</div>
	</div>
</div> -->


<main class="main">
	<div class="container">
		<div class="forms">
			<div class="sign__blog">

			</div>

		</div>

		<div class="panels__blog">

			<div class="panel left__panel">

				<div class="content">
					<div class="register-left">
						<img [src]="imgLogo" style="max-width: 80px;">
						<div>
							<span class="new_logo_text">{{brandName}}</span>
						</div>
					</div>
					<div style="z-index: 11;">
						<div *ngIf="information?.param == 'success'">
							<div class="welcome_container">
								<h3 class="panel__title">Welcome</h3>
								<img src="assets/new_check.png" class="img_check">
							</div>
							<div class="panel__text" style="margin:0 15px;"> {{information.msg}} </div>
						</div>
						<div *ngIf="information?.param == 'error'">
							<div class="welcome_container" style="margin: 0 15px;">
								<div class="panel__text" style="width: 100%; text-align: center;"> {{information.msg}} </div>
							</div>
						</div>

						<div class="btn_with_icon">
							<input type="button" value="Signin" class="btn login nbtn"
							style="background-color: #333840; width: 80%;" (click)="goTo('signin')">
						</div>
					</div>

				</div>
				<img src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_Login_re_4vu2.svg"
					alt="" class="panel__img">
			</div>



		</div>

	</div>
</main>