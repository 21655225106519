import { Component, OnInit } from '@angular/core';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AppService } from 'src/app/core/services/app.service';
import { ThemeService } from 'src/app/services/theme.service';


@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
	brandName: string;
	domainName: string;
	show: Boolean = false;
	isMobile: boolean;
	constructor(private cookieService: CookiesManagementService, private appService: AppService, private themeService: ThemeService) {
		this.brandName = this.themeService.name;
	}

	ngOnInit(): void {
		this.isMobile = this.appService.isMobile();
		let cookieresearch_participantsModalCookie = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_BANNER_COOKIE);
		if (!cookieresearch_participantsModalCookie) {
			this.show = true;
		}
		this.domainName = this.appService.getDomain();
		this.brandName = this.themeService.name;
	}



	setModalCookieClosed(): void {
		this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_BANNER_COOKIE, 'modal_closed_accepted_cookies');
		this.show = false;
	}
}
