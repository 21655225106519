import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/core/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ThemeService } from 'src/app/services/theme.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppService } from 'src/app/core/services/app.service';

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['../login/login.component.scss', '../register/register.component.scss']
})
export class UnsubscribeComponent implements OnInit {
	loginFormContainer: string = 'col-5 new_form_container';
	showImage: boolean = true;
	brandName: string = '';
	imgLogo: string;
	backgroundUrl: string;
	isMobile: boolean = false;
	constructor(
		private router: Router, private messageService: MessageService,
		private route: ActivatedRoute,
		private userService: UserService, private themeService: ThemeService,
		private breakpointObserver: BreakpointObserver, private appService: AppService) {
		this.brandName = this.themeService.name;
		this.imgLogo = this.themeService.logoLogin;
		this.backgroundUrl = this.themeService.imageLogin;
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(res => {
			if (res.matches) {
				this.showImage = false;
				this.loginFormContainer = 'col-12 new_form_container';
			} else {
				this.showImage = true;
				this.loginFormContainer = 'col-5 new_form_container';
			}
		});
	}

	submitted = false;
	unsubscribeState = "NotSent";

	otherReason: string;
	emailAddress: string;
	unsubscribeReason: any = "Direct Unsubscribe";;
	reasons: any[] = [
		{ value: "op1", reason: "I do not think this content is relevant", selected: true },
		{ value: "op2", reason: "I am having trouble receiving or viewing emails", selected: false },
		{ value: "op3", reason: "I am receiving too many emails", selected: false },
		{ value: "op4", reason: "I do not remember signing up for this mailing list", selected: false },
		{ value: "other", reason: "Other (Fill in reason below)", selected: false }
	];

	ngOnInit(): void {
		this.isMobile = this.appService.isMobile();
		this.unsubscribeReason = this.reasons.find((r: any) => r.selected === true).value;
		this.route.queryParams.subscribe(params => {
			if (params.email){
				this.emailAddress = params.email;
				this.unsubscribeEmail(true);
			}
		});	
	}

	clearFields(): void {
		this.unsubscribeReason = "Direct Link Unsubscribe";
		this.emailAddress = "";
		this.otherReason = "";
	}

	unsubscribeEmail(def = false): void {
		if (this.emailAddress && this.isValidEmail(this.emailAddress)) {
			this.userService.unsubscribeEmail({
				email: this.emailAddress.toLowerCase(),
				unsubscribe: def ? `Direct Link Unsubscribe` : this.unsubscribeReason,
				unsubscribe_other: this.otherReason
			}).subscribe(res => {
				//console.log("RESPONSE: <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", res);
				this.messageService.showMessageTitle("success", "Email unsubscribed", "You have been successfully unsubscribed from our email communications.").then((result: any) => {
					console.log("RESULT: ", result);
					this.clearFields();
				});
			});
		} else {
			this.messageService.showMessageTitle("error", "Validation Error", "The email is required");
		}
	}

	goTo(url: any) {
		this.router.navigateByUrl(url);
	}

	isValidEmail(email: string) {
		const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return expression.test(email);
	}

	fieldsWidth(): string {
		let design = this.isMobile ? 'web_fields_full' : 'web_fields';
		return design;
	}

}
