import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import NewProject from 'src/app/core/models/new-project-model';
import { AlertType, MessageService } from 'src/app/core/services/message.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
	selector: 'app-project-information',
	templateUrl: './project-information.component.html',
	styleUrls: ['./project-information.component.scss']
})
export class ProjectInformationComponent implements OnInit, OnChanges {

	@Input() newProjectModel: NewProject;
	@Input() isEdit: boolean;
	//ZID 110882 - Can sync with this zintro id
	methodologies = [
		{ name: 'Focus Group Discussion', code: 'fgd' },
		{ name: 'Job', code: 'j' },
		{ name: 'Multi-Hour Projects', code: 'mp' },
		{ name: 'Online Buletin Board', code: 'obb' },
		{ name: 'Project', code: 'pj' },
		{ name: 'Phone Consult', code: 'pc' },
		{ name: 'Survey', code: 's' },
		{ name: 'Webcam Interview', code: 'wi' },
		{ name: 'In-person focus group discussion + Survey', code: 'fds' }
	];

	times = new Array();
	/*
	[
		{ code: 1, time: 5, label: "5 minutes" },
		{ code: 2, time: 10, label: "10 minutes" },
		{ code: 3, time: 15, label: "15 minutes" },
		{ code: 4, time: 20, label: "20 minutes" },
		{ code: 5, time: 25, label: "25 minutes" },
		{ code: 6, time: 30, label: "30 minutes" },
		{ code: 7, time: 45, label: "45 minutes" },
		{ code: 8, time: 60, label: "60 minutes" },
		{ code: 9, time: 90, label: "90 minutes" },
		{ code: 10, time: 120, label: "120 minutes" }
	];
	*/
	currencies = [
		{ code: 1, type: "USD" },
		{ code: 2, type: "EUR" }
	];

	submitted: Boolean = false;
	to: Date = new Date();
	from: Date = new Date();

	constructor(private projectService: ProjectService, private messageService: MessageService) { }

	ngOnInit(): void {
		this.generateTimes();
	}

	generateTimes() {
		for (var i = 1; i <= 36; i++) {
			let obj = {
				code: i,
				time: i * 5,
				label: `${i * 5} minutes`
			};
			this.times.push(obj);
		}
	}

	bindCountry(country: string): void {
		this.newProjectModel.work_country = country;
	}

	cleanDate(dateString): Date {
		let date = new Date(dateString);
		//date.setHours(date.getHours() + 12);
		return date;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.newProjectModel.creation) {
			this.newProjectModel.creation = this.cleanDate(this.newProjectModel.creation);
		}
		if (this.newProjectModel.deadline) {
			this.newProjectModel.deadline = this.cleanDate(this.newProjectModel.deadline);
		}
		if (this.newProjectModel.recording_and_transcription) {
			this.newProjectModel.recording_and_transcription = true;
		}
	}

	isValidForm(): boolean {
		let rta = true;
		if (
			this.newProjectModel.subject == `` ||
			this.newProjectModel.subject == undefined ||
			this.newProjectModel.ideal_experts == `` ||
			this.newProjectModel.ideal_experts == undefined ||
			this.newProjectModel.incentive == undefined ||
			this.newProjectModel.deadline == undefined 
		) {
			rta = false;
		}
		return rta;
	}
	onSubmit() {
		this.submitted = true;
		console.info("ProjectModel:", this.newProjectModel);

		if (this.isValidForm()) {
			if (this.newProjectModel.zid) {
				this.projectService.getProjectVendorExists(this.newProjectModel.zid).subscribe(response => {

					if (response.exists) {
						this.projectService.saveNewProject(this.newProjectModel).subscribe(response => {
							this.messageService.showMessageTitle("success", "", "Project Created Ok").then((result: any) => {
								console.log("RESULT: ", result);
								this.newProjectModel = new NewProject();
								this.submitted = false;
							});
						}, error => {
							console.error("ERROR ON SAVE PROJECT: ", error);
							this.messageService.showMessage(AlertType.Error, "Internal Server Error");
						});
					} else {
						this.messageService.showMessage(AlertType.Error, "Zintro Project ID does not exists");
					}

				}, error => {
					console.error("ERROR GETTING IF PROJECT EXISTS: ", error);
					this.messageService.showMessage(AlertType.Error, "Internal Server Error");
				});

			} else {
				this.messageService.showMessage(AlertType.Error, "Zintro Project ID is Required");
			}
		} else {
			this.messageService.showMessage(AlertType.Error, 'Please complete all the required fields');
		}
	}

	captureDate(date: Date, type: string) {
		console.log("CaptureDate DATE: " + date + " - " + type);

		switch (type) {
			case 'from':
				this.from = date;
				break;
			case 'to':
				this.to = date;
				break;
			case 'creation':
				this.newProjectModel.creation = date;
				break;
			case 'deadline':
				this.newProjectModel.deadline = date;
				break;
		}
	}


}
