  <!-- MAIN CONTENT -->
<section class="m-inquiry m-content clear-fix">
	<section class="m-content-header m-inquiry-header">
		<h1 class="test-css">Create Inquiry</h1>
	</section> <!-- .m-content-header -->

	<section class="m-steps-content">
		<h3>Step 1 <span>of</span> 3</h3>
		<p>You will still be able to review your inquiry before it is submitted.</p>
	</section> <!-- .m-steps-content -->

	<section class="m-inquiry-content">
		<h3 class="form-title">Have a need? Explain it here</h3>

		<form [formGroup]="inquirySubmitForm" class="inquiry-step-1" (ngSubmit)="onSubmit()" id="inquiryForm">
			<section class="inquiry-form clear-fix">
				<!-- Presentation toolbar code -->
				<div class="inquiry-presentation-box">
				</div>

				<ul class="form-list clear-fix">
					<li class="clear-fix">
						<label class="form-sub-title">Inquiry</label>
						<textarea required class="inquiry-textarea" name="projectDescription" formControlName="projectDescription" minlength="50"></textarea>
						<div *ngIf="submitted && inquirySubmitForm.controls.projectDescription.errors?.required" class="m-form-error error-a" style="display: block">Project description is required</div>
						<div *ngIf="submitted && inquirySubmitForm.controls.projectDescription.errors?.minlength" class="m-form-error error-a" style="display: block">Project description is too short (min 50 characters)</div>
						<div class="m-form-error error-a">Inquiry is Required</div>
					</li>
				</ul>
			</section> <!-- .inquiry-form -->

			<div class="inquiry-terms">
				<p>By clicking Create Inquiry you agree to our Terms <a href="https://www.research_participants.com/resources/research_participants-terms-of-service.pdf" target="_blank" class="inquiry-terms-link">VIEW</a></p>
			</div>

			<input type="submit" class="orange-btn inquiry-submit" value="create inquiry">
		</form> <!-- .inquiry-step-1 -->

		<!-- <jw-modal id="signin_modal">
			<signin-form [uponSignin]="uponSignin"></signin-form>
		</jw-modal> -->
	</section> <!-- .m-inquiry-content -->
</section> <!-- .m-inquiry -->
<style>
	.m-form-container {
		background-color: white;
	}
</style>
