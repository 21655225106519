import { ITheme } from '../interfaces/itheme.interface';

export const THEMES: ITheme = {
    default: {
        primaryColor:'#1b548d',
        primaryColorHover:'#3b82f6',
        primaryColorActive: "#1D4ED8",
        primaryColorOpacity:'#1b548dd1',
        borderLogin:'white',
        white:'white',
        redGoogle:'#ce5643',
        blueLinkedin:'#007ab5',
        blueOTLogin:'#2C95FF',
        blueSignIn:'#162B40',
        cancelColor:'red',
        cancelColorHover:'rgb(120, 56, 56)',
        btnInactive:'#D9D9D9',
        btnDisabled:'#a8abad',
        backgroundInput:'#f7f7f7',
        buttonTextColor: '#ffffff',
        boxShadowActive: '#bfdbfe',
        backgroundSelection: '#2196f329'
    },
    green: {
        primaryColor:'#4caf50',
        primaryColorHover:'#5ac85f',
        primaryColorActive: "#359b39",
        primaryColorOpacity:'#4caf507a',
        borderLogin:'white',
        white:'white',
        redGoogle:'#ce5643',
        blueLinkedin:'#007ab5',
        blueOTLogin:'#2C95FF',
        blueSignIn:'#162B40',
        cancelColor:'red',
        cancelColorHover:'rgb(120, 56, 56)',
        btnInactive:'#D9D9D9',
        btnDisabled:'#a8abad',
        backgroundInput:'#f7f7f7',
        buttonTextColor: '#ffffff',
        boxShadowActive: '#d2ff9e',
        backgroundSelection: '#d2ff9e3b'
    }
}