import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { ForgotPasswordRequest } from 'src/app/core/models/forgot-request-model';
import { MessageService } from 'src/app/core/services/message.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['./forgot.component.scss', '../login/login.component.scss']
})
export class ForgotComponent implements OnInit {
	imgLogo: string;
	brandName: string;
	backgroundUrl: string = '/assets/server1.jpg';

	loginFormContainer: string = 'col-5 new_form_container';
	showImage: boolean = true;
	constructor(private router: Router, private route: ActivatedRoute,
		private cookieService: CookiesManagementService, private formBuilder: UntypedFormBuilder,
		private messageService: MessageService, private authService: AuthService,
		private breakpointObserver: BreakpointObserver, private themeService: ThemeService) {
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(res => {
			if (res.matches) {
				this.showImage = false;
				this.loginFormContainer = 'col-12 new_form_container';
			} else {
				this.showImage = true;
				this.loginFormContainer = 'col-5 new_form_container';
			}
		});

		this.imgLogo = this.themeService.logoLogin;
		this.backgroundUrl = this.themeService.imageLogin;
		this.brandName = this.themeService.name;
	}

	submitted: Boolean = false;
	forgotForm = this.formBuilder.group({
		email: '',
		pswd: '',
	}, { updateOn: 'submit' });


	ngOnInit(): void {
		let logged = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN);
		if (logged) {
			if (this.forgotForm == undefined) {
				this.goTo(GlobalConstants.URL_PROJECTS);
			} else {
				console.log('Already logged');
			}
		}
	}

	onSubmit(): void {
		this.submitted = true;

		if (this.forgotForm.controls.email.valid) {
			console.info("VALIDATION SUCCESS");

			this.authService.recoverPassword(new ForgotPasswordRequest(this.forgotForm.controls.email.value)).subscribe(data => {
				console.info("recoverPassword ok");
				this.messageService.showMessageTitle("success", "", "A message will be sent to your email address with further instructions");
			}, error => {
				console.error("recoverPassword Error", error);
				this.messageService.showMessageTitle("error", "Internal Server Error", "");
			});

		} else {
			this.forgotForm.markAllAsTouched();
			console.info("Pending validate")
		}
	}

	goTo(url: any) {
		this.router.navigateByUrl(url);
	}

}