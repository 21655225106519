import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class LogoutResolver implements Resolve<any> {
	
	constructor(private authService: AuthService) {}
	
	resolve(): Observable<any>  {
		return this.authService.logout();
	}
}