import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, startWith } from 'rxjs/operators';
import { AutocompleteService } from '../services/autocomplete.service';
import ProjectInterface from "../core/models/project-interface";
import AutocompleteInterface from "../core/models/autocomplete-interface.model";

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Output() valueSelected = new EventEmitter<AutocompleteInterface>();

  keyword = new FormControl();
  suggestions: AutocompleteInterface[] = [];
  isLoading = false;
  constructor(private autocompleteService: AutocompleteService) { }

  ngOnInit(): void {
    this.keyword.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        this.isLoading = true; // Show loading indicator
        return this.autocompleteService.getProjectsSuggestions(value);
      })
    ).subscribe(
      (data: any[]) => {
        this.suggestions = data;
        this.isLoading = false; // Hide loading indicator when suggestions are received
      },
      (error) => {
        console.error('Error fetching suggestions:', error);
        this.isLoading = false; // Hide loading indicator on error
      }
    );
  }

  selectValue(value: AutocompleteInterface): void {
    this.keyword.setValue(value.label);
    this.valueSelected.emit(value);
    this.suggestions = [];
  }
}
