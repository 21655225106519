import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalConstants } from 'src/app/common/global-constants';
import { InquiryConversationListResponse } from 'src/app/core/models/inquiry-conversation-list-response.model';
import { NavBarData } from 'src/app/core/models/navbar-data.model';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { InquiryTwoEventService } from 'src/app/core/services/inquiry-two-event.service';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html'
})
export class NavBarComponent implements OnInit {
	constructor(private cookieService: CookiesManagementService, private inquiryTwoEventService: InquiryTwoEventService) { }

	showConversationLink: boolean = false;
	showMenu: boolean = false;
	loggedUser: boolean = false;
	premiumUser: boolean = false;
	isAdmin: boolean = false;
	isJitRecruiter: boolean = false;
	conversationCount: any;

	profilePictureSrc: string | undefined;
	profileSrc: string | undefined;

	ngOnInit(): void {
		this.inquiryTwoEventService.changeEmitted$.subscribe(data => {
			let response = <InquiryConversationListResponse> data;

			this.showConversationLink = true;
			if (response.item.length > 0) {
				this.conversationCount = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_NOT_READ);
				// this.conversationCount = response.item.reduce((a, b) => a + b.unReadNotification, 0);
			} else {
				this.conversationCount = 0;
			}
		});

		let userdata = this.cookieService.getNavBarLoginResponse();
		if (userdata.token) {
			this.loggedUser = true;
			this.premiumUser = (userdata.premiumUser) ? JSON.parse(userdata.premiumUser) : false;
			this.isAdmin = (userdata.isAdmin) ? JSON.parse(userdata.isAdmin) : false;
			this.isJitRecruiter = (userdata.isJitRecruiter) ? JSON.parse(userdata.isJitRecruiter) : false;

			this.profilePictureSrc = "https://www.research_participants.com/profilePicture/" + userdata.userName.toLowerCase();
			this.profileSrc = "/profile/" + userdata.userName.toLowerCase();
		} else {
			this.loggedUser = false;
		}
	}

}