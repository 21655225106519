export class GlobalConstants {

  // public static apiURL: string = "https://itsolutionstuff.com/";

	public static HTTP	: string				 					= "http://";
	public static HTTPS : string									= "https://";

	public static URL_BACKEND										= "/api";
	public static URL_PROFILE : string 								= "profile";
	public static URL_BASE_CONVERSATION : string 					= "baseconversation";
	public static URL_MATCHES : string								= "matches";
	public static URL_CONTENT_SLASH : string 						= "/content";
	public static URL_BROWSE_SLASH : string 						= "/browse";
	public static URL_PROJECTS_SLASH : string 						= "/projects";
	public static URL_SIGNIN_SLASH : string 						= "/signin";
	public static URL_VERIFICATION_SLASH : string 					= "/verification";
	public static URL_SIGNUP_SLASH : string 						= "/new_signup";
	public static URL_LOGOUT_SLASH : string 						= "/new_logout";
	public static URL_ALCHEMER_SLASH : string 						= "/app/survey";

	public static URL_PROJECTS : string 							= GlobalConstants.URL_CONTENT_SLASH + GlobalConstants.URL_PROJECTS_SLASH;
	public static URL_POST_ACTIVE_PROJECTS : string					= GlobalConstants.URL_BACKEND + this.URL_PROJECTS + "/active";

	public static URL_SIGNIN : string 								= GlobalConstants.URL_BACKEND + GlobalConstants.URL_SIGNIN_SLASH;
	public static URL_SIGNUP : string 								= GlobalConstants.URL_BACKEND + GlobalConstants.URL_SIGNUP_SLASH;
	public static URL_LOGOUT : string 								= GlobalConstants.URL_BACKEND + GlobalConstants.URL_LOGOUT_SLASH;
	public static URL_BROWSE : string 								= GlobalConstants.URL_BACKEND + GlobalConstants.URL_BROWSE_SLASH;

	public static URL_SEND_SMS : string 							= GlobalConstants.URL_BACKEND + "/verification/send_sms";
	public static URL_VALIDATE_PHONE : string 						= GlobalConstants.URL_BACKEND + "/verification/validate_phone";

	//INQUIRY STEP 1
	public static URL_INQUIRY_STEP_1 : string						= "inquiry1";
	public static URL_INQUIRY1_SUBMIT								= "/backend-rest/inquiry_step_one/inquiry_step_one_update_inq";
	public static INQUIRY_1_DESCRIPTION: string						= "Inquiry1Description"
	public static SIGNUP_SHOW_MESSAGGE : string						= "Show Welcome";

	//INQUIRY STEP 2
	public static URL_INQUIRY_STEP_2 : string						= "/inquiry2";
	public static URL_INQUIRY2_GET_INFO : string					= "/backend-rest/inquiry_step_two/inquiry_step_two_get_inq";
	public static URL_CONVERSATION_LEFT_PANEL : string				= "/backend-rest/conversations/conversations_left_panel";
	public static URL_INQUIRY2_SUBMIT : string						= "/backend-rest/inquiry_step_two/inquiry_step_two_update_inq";

	//INQUIRY STEP 3
	public static URL_INQUIRY_STEP_3 : string						= "/inquiry3";

	// Cookies
	public static RESEARCH_APP_AFFILIATE : string					= "research_participantsAffiliate";
	public static RESEARCH_APP_AFFILIATE_VALID : string				= "research_participantsAffiliateValid";
	public static RESEARCH_APP_USER_ID : string						= "research_participantsUserId";
	public static RESEARCH_APP_USERNAME : string					= "research_participantsUsername";
	public static RESEARCH_APP_TOKEN : string						= "research_participantsLogin";
	public static RESEARCH_DEVICE : string							= "research_participantsDevice";
	public static RESEARCH_APP_LANGUAGE : string					= "research_participantsLanguage";
	public static RESEARCH_APP_FIRST_NAME	: string				= "research_participantsFirstName";
	public static RESEARCH_APP_LAST_NAME : string					= "research_participantsLastName";
	public static RESEARCH_APP_HOME_PATH : string					= "research_participantsHomePath";
	public static RESEARCH_APP_PREMIUM : string						= "research_participantsPremium";
	public static RESEARCH_APP_CLIENT : string						= "research_participantsIsClient";
	public static RESEARCH_APP_EXPERT : string						= "research_participantsIsExpert";
	public static RESEARCH_APP_USER_TYPE : string					= "research_participantsUserType";
	public static RESEARCH_APP_FEE_DURATION : string				= "research_participantsLengthFee";
	public static RESEARCH_APP_HOPS : string						= "research_participantsHops";
	public static RESEARCH_APP_TIMEZONE : string					= "research_participantsTimeZone";
	public static RESEARCH_APP_EMAIL : string						= "research_participantsEmail";
	public static RESEARCH_APP_VERIFICATION_KEY : string			= "research_participantsVerificationKey";
	public static RESEARCH_APP_LAST_TEMPLATE_ZNOTE : string			= "research_participantsTemplateZnote";
	public static RESEARCH_APP_ADMIN : string						= "research_participantsAdmin";
	public static RESEARCH_PREMIUM_EXPERT_LIMIT : string			= "research_participantsPremiunExpertLimit";
	public static RESEARCH_USER_VOICE_TOKEN : string				= "research_participantsUserVoiceToken";
	public static RESEARCH_APP_PACKAGE_NAME : string				= "research_participantsPackageName";
	public static RESEARCH_APP_SIGNUP_STATUS : string				= "research_participantsSignUpStatus";
	public static RESEARCH_APP_NOT_READ : string					= "research_participantsAppNotReadNotification";
	public static RESEARCH_APP_SKIP_ADMIN_NOTICE : string			= "research_participantsSkipAdminNotice";
	public static RESEARCH_APP_LATEST_NOTICE_SHOWN : string			= "research_participantsLastNoticeShown";
	public static RESEARCH_APP_EXPERT_AREAS : string				= "research_participantsExpertAreas";
	public static RESEARCH_APP_MATCHES_PRECISSION : string			= "research_participantsMatchesPrecission";
	public static RESEARCH_APP_RESPOND_ONLY_PROPOSAL : string		= "research_participantsAppRespondOnlyProposal";
	public static RESEARCH_APP_IS_RELOADED : string					= "research_participantsIsReloaded";
	public static RESEARCH_APP_NEW_PROPOSAL_NOTE_TEXT : string		= "research_participantsNewProposalNoteText";
	public static RESEARCH_APP_NEW_INQUIRY_TEXT : string			= "research_participantsNewInquiryText";
	public static RESEARCH_APP_NEW_USER_PASS : string				= "research_participantsTokenLB";
	public static RESEARCH_APP_URL_SIGNUP_OLD : string				= "research_participantsSignUpExpert";
	public static RESEARCH_APP_URL_SIGNUP_NEW	 : string			= "research_participantsSignUpWelcome";
	public static RESEARCH_APP_AREA_OF_EXPERTISE : string			= "research_participantsAreaOfExpertise";
	public static RESEARCH_REFER_USER_ID : string					= "research_participantsReferUserId";
	public static RESEARCH_SHOW_MESSAGE : string					= "research_participantsShowMessage";
	public static RESEARCH_APP_EXPERT_PROFILR_LIMIT : string		= "research_participantsNumberOfExpertProfileSaw";
	public static RESEARCH_APP_BACK_TO_ADMIN : string 				= "research_participantsBackToAdmin";
	public static RESEARCH_APP_GCLID : string 						= "research_participantsGCLID";
	public static RESEARCH_APP_PROFILE_PERCENT : string 			= "research_participantsProfilePercent";
	public static RESEARCH_APP_PROFILE_ALERT : string 				= "research_participantsProfileAlert";
	public static RESEARCH_APP_JIT_RECRUITER : string 				= "research_participantsIsJitRecruiter";
	public static RESEARCH_APP_CLIENT_PREMIUM : string 				= "research_participantsClientPremium";
	public static RESEARCH_APP_EXPERT_PREMIUM : string 				= "research_participantsExpertPremium";
	public static RESEARCH_APP_BANNER_COOKIE : string				= "research_participantsModalCookie";

	// @ts-ignore
	public static GG(): string {
		return './screening-viewer.component.html';
	}
}
