<div>
  <input type="text" [formControl]="keyword" placeholder="Type to search..." class="modal_claim_input">
  <!-- Loading indicator -->
  <div *ngIf="isLoading" class="loading-indicator">
    Loading...
  </div>
  <!-- Suggestions dropdown -->
  <ul *ngIf="!isLoading && suggestions.length > 0" class="suggestions-list">
    <li *ngFor="let suggestion of suggestions" (click)="selectValue(suggestion)">
      {{ suggestion.label }}
    </li>
  </ul>
</div>
