import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer-main-layout',
	templateUrl: './footer-main-layout.component.html'
})
export class FooterMainLayoutComponent implements OnInit {

	constructor() {
		console.log('Main layout WITH FOOTER constructor called');
	}

	ngOnInit() {
	}
}