import { BannerNotificationType } from "src/app/modules/shared/components/error-banner/notification-banner.component"

export class NotificationData {
    type: BannerNotificationType;
    message: string;
  
    constructor(type:BannerNotificationType, message: string) {
        this.type = type;
        this.message = message;
    }
  }
  