import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-secure-layout',
	templateUrl: './secure-layout.component.html'
})
export class SecureLayoutComponent implements OnInit {

	constructor() {
		console.log('Secure layout constructor called');
	}

	ngOnInit() {
	}
}