import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';
@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['../../../../root/pages/login/login.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnChanges {

	@Input() login_container_class: string = "login_container";
	@Input() form_container_class: string = "form_container";
	@Input() showOld: boolean;
	@Input() hideBrandAndLogo: boolean = false;
	@Input() hideHr: boolean = false;
	pwError: string = "Passwords do not match";
	oldPw: string;
	password: string;
	rePassword: string;
	dinamicClass: string = '';
	@Output() emitAction: EventEmitter<any> = new EventEmitter<any>();
	@Input() type: string = "normal";
	isMobile: boolean;
	imgLogo: string;
	brandName: string;
	oldPwType: string = 'password';
	newPwType: string = 'password';
	conPwType: string = 'password';
	
	constructor(
		private authService: AuthService, private router: Router,
		private userService: UserService, private messageService: MessageService,
		private themeService: ThemeService
	) {
		this.imgLogo = this.themeService.logoLogin;
		this.brandName = this.themeService.name;
	}

	ngOnInit(): void {
		let accesor = navigator.userAgent;
		this.manageViews(accesor);
	}

	manageViews(accesor) {
		if (accesor.toString().toLowerCase().includes("android") || accesor.toString().toLowerCase().includes("iphone") || accesor.toString().toLowerCase().includes("ipad")) {
			this.isMobile = true;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.type == 'modal') {
			this.dinamicClass = 'new_form_container_modal';
		} else {
			this.dinamicClass = '';
		}
	}
	

	canConfirm() {
		let disable = true;
		if (this.password != "" && this.password == this.rePassword) {
			disable = false;
		}
		return disable;
	}

	confirmChangePassword() {
		let req = {
			token: this.authService.recoverToken,
			pwd: this.password
		}

		this.authService.changePassword(req).subscribe(data => {
			let desc = "Your password has been changed successfully";
			this.messageService.showMessageTitle("success", "Password Updated!", desc).then((result: any) => {
				this.router.navigate(["logout"])
			});
		}, error => {
			console.info("UpdatePassword confirmChangePassword Error: " + JSON.stringify(error));
			this.messageService.showMessageTitle("error", "Error updating password", error.error.msg);
		});
	}

	confirmChangePwProfile() {
		let isRecover: boolean = !this.showOld;
		let req = {
			pwd: this.password,
			oldPwd: this.oldPw,
			isRecover: isRecover
		}

		this.userService.updatePassword(req).subscribe(data => {
			this.emitAction.emit({ action: "close" });

			let desc = "Your password has been changed successfully";
			this.messageService.showMessageTitle("success", "Password Updated!", desc);
		}, error => {
			console.info("UpdatePassword confirmChangePwProfile Error: " + JSON.stringify(error));
			this.emitAction.emit({ action: "close" });

			this.messageService.showMessageTitle("error", "Error on Password Updated!", error.error.msg);
		});
	}

	togglePw(which){
		switch(which){
			case 'old':
				if (this.oldPwType == 'password'){
					this.oldPwType = 'text';
				}else{
					this.oldPwType = 'password';
				}
				break;
			case 'new':
				if (this.newPwType == 'password'){
					this.newPwType = 'text';
				}else{
					this.newPwType = 'password';
				}
				break;
			case 'confirm':
				if (this.conPwType == 'password'){
					this.conPwType = 'text';
				}else{
					this.conPwType = 'password';
				}
				break;
		}
	}
}
