import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
	selector: 'app-confirm-account',
	templateUrl: './confirm-account.component.html',
	styleUrls: ['./confirm-account.component.scss', '../login/login.component.scss', '../register/register.component.scss']
})
export class ConfirmAccountComponent implements OnInit {
	imgLogo: string;
	brandName: string;
	backgroundUrl: string = '/assets/server1.jpg';

	loginFormContainer: string = 'col-5 new_form_container';
	showImage: boolean = true;
	information;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private breakpointObserver: BreakpointObserver,
		private themeService: ThemeService) {
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(res => {
			if (res.matches) {
				this.showImage = false;
				this.loginFormContainer = 'col-12 new_form_container';
			} else {
				this.showImage = true;
				this.loginFormContainer = 'col-5 new_form_container';
			}
		});
		this.imgLogo = this.themeService.logoLogin;
		this.backgroundUrl = this.themeService.imageLogin;
		this.brandName = this.themeService.name;
	}

	ngOnInit(): void {
		this.information = this.route.snapshot.data['activationInfo'];
		console.log("INFORMATION", this.information)

		// this.route.paramMap.subscribe( params => {
		//   console.log("PARAMS", params);
		//   let user = params.get("user_id");
		//   let token = params.get("token");
		//   if (user && token){
		//     //Go to activation service
		//   }
		// });
	}

	goTo(url: any) {
		window.location.href = `/app/${url}`;
		//this.router.navigateByUrl(url);
	}

}
