export default class NewProject {

	id: string;
	zid: string;
	subject: string;
	domain_id: number;
	ideal_experts: string;
	creation: Date;
	last_client_interaction: Date;
	project_status: string;
	project_timezone: string;
	experience_industries: string;
	methodology: string;
	organizations_of_intereset: string;
	anonymity_compliance_requirements: string;
	work_city: string;
	work_state: string;
	work_country: string;
	deadline: Date;
	involvement_level: string;
	recording_and_transcription: boolean;
	title_or_role: string;
	currency: string
	organizations_to_avoid: string;
	client_updates: string;
	expected_transactions: number;
	cancidates_screened: number;
	cancidates_passed_screening: number;
	approved_by_client: number;
	recruiter_commission_amount: number;
	incentive: number;
	call_duration: number;
	screening_questions_link: string;

	constructor() {
		this.zid = ''
		this.subject = '';
		this.domain_id = null;
		this.ideal_experts = '';
		this.creation = new Date();
		this.last_client_interaction = new Date();
		this.project_status = '';
		this.project_timezone = '';
		this.experience_industries = '';
		this.methodology = 'Project';
		this.organizations_of_intereset = '';
		this.anonymity_compliance_requirements = '';
		this.work_city = '';
		this.work_state = '';
		this.work_country = 'US';
		this.deadline = new Date();
		this.involvement_level = '';
		this.recording_and_transcription = true;
		this.title_or_role = '';
		this.currency = ''
		this.organizations_to_avoid = '';
		this.client_updates = '';
		this.expected_transactions = null;
		this.cancidates_screened = null;
		this.cancidates_passed_screening = null;
		this.approved_by_client = null;
		this.recruiter_commission_amount = null;
		this.incentive = null;
		this.call_duration = 30;
		this.screening_questions_link = "";
	}

}