import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { UserService } from 'src/app/services/user.service';
import { CookiesManagementService } from '../services/cookies-management.service';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private router: Router,
		private cookieService: CookiesManagementService,
		private userService: UserService) {}

	canActivate(): boolean {
		var token = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN);

		let userInfo = JSON.parse(localStorage.getItem("userInfo"));
		if (userInfo) {
			this.userService.setUserInfo(userInfo);
		}

		if (token && userInfo) {
			return true;
		}

		this.router.navigate([GlobalConstants.URL_SIGNIN_SLASH]);
		return false;
	}
}
