import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { environment as env } from '../../environments/environment';
import { CompletedCallsAdminRequest } from './request-models/completed-calls-admin-request-model';
import { Observable } from 'rxjs';
import { PaidMarkProposalRequest } from '../core/models/proposal-paid-mark.request.model';
import { PaidMailNotificationRequest } from '../core/models/paid-mail-notification.request.model';

const endpoint: string = env.apiUrl + "/proposal";

@Injectable({
	providedIn: 'root'
})
export class ProposalService {

	constructor(private httpService: HttpService) { }

	updateProposalStatus(req) {
		let action = "/status";
		return this.httpService.post(endpoint + action, req);
	}

	completeProposal(req) {
		let action = "/complete";
		return this.httpService.post(endpoint + action, req);
	}

	markPaidProposal(req: PaidMarkProposalRequest) {
		let action = '/update_single_field';
		return this.httpService.post(endpoint + action, req);
	}

	getAllCompletedCallsAdmin(req: CompletedCallsAdminRequest) {
		let action = '/content/proposals/get_completed_calls'
		return this.httpService.post(endpoint + action, req);
	}

	sendMailAfterPaid(req: PaidMailNotificationRequest) {
		let action = '/send_payment_notification';
		return this.httpService.post(endpoint + action, req);
	}

	getSurveyInformation(prop_id: string): Observable<any> {
		let action = "/survey/" + prop_id;
		return this.httpService.get(endpoint + action);
	}

	getWelcomeSurvey(): Observable<any> {
		let action = '/welcome/survey';
		return this.httpService.get(endpoint + action);
	}

}
