<div class="m-header-container">
	<header class="m-header clear-fix">
		<a href="#"><h1 class="m-logo">research_participants</h1></a>
		<span class="m-tagline">Fueling the expertise economy</span>
			<nav class="header-nav">
				<a class="nav-expand">&#x2261;</a>
				<ul class="m-main-nav">
					<li class="inquiry">
						<a href="/inquiry1">
							<img src="http://s3.amazonaws.com/research_participants-resources/images/new-inquiry-icon.gif" width="31" height="30" alt="An icon with a plus sign">
							new project
						</a>
					</li>

					<!-- <li class="header-link"><a href="#" class="hascounter">zmail <span class="counter">8</span></a></li> -->
					<!-- <li class="header-link"><a href="#" class="active hascounter">matches <span class="counter">888</span></a></li> -->
					<div *ngIf="loggedUser; then loggedTemplate else unloggedTemplate"></div>
					<ng-template #loggedTemplate>
						<li *ngIf="showConversationLink" class="header-link">
							<a href="/baseconversation" class="hascounter">Conversations 
								<span class="counter" [innerText]="conversationCount"></span>
							</a>
						</li>

						<li class="header-link"><a href="/matches">matches</a></li>
						<li class="header-link"><a href="/browse">browse</a></li>

						<li class="avatar-menu header-link" (mouseover)="showMenu=true" (mouseout)="showMenu=false">
							<a class="m-avatar">
								<img [src]="profilePictureSrc" width="30" height="30">
								ME
							</a>
							<img *ngIf="premiumUser" src="http://s3.amazonaws.com/research_participants-resources/images/premium.png" width="24" height="24" class="user-badge">

							<ul [ngStyle]="{'display': showMenu ? 'block' : 'none'}" class="sub-menu">
								<ng-template [ngIf]="isAdmin">
									<!-- {{?it.auth_user.is_admin}} -->
										<li><a href="/node_admin_engagements_curation_view">ADMIN</a></li>
									<!-- {{?}} -->
								</ng-template>

								<ng-template [ngIf]="isJitRecruiter">
									<!-- {{?it.auth_user.is_jit_recruiter}} -->
										<li><a href="/node_admin_engagements_curation_view?refresh=true">Dashboard</a></li>
										<li><a href="/expert_replies_report">Expert Replies</a></li>
										<li><a href="/recruiters/recruiters_email_filtering">Email Filtering</a></li>
									<!-- {{?}} -->
								</ng-template>
<!--
								{{?it.back_to_admin_present}}
									<li><a onclick="backToAdmin()">BACK TO ADMIN</a></li>
								{{?}}
-->
								<li><a [href]="profileSrc">Profile</a></li>
								<li><a href="/myaccount">My Account</a></li>
								<li><a href="/logout">Sign Out</a></li>
							</ul>
						</li>
					</ng-template>
					<ng-template #unloggedTemplate>
						<li class="header-link"><a href="/signin">signin</a></li>
					</ng-template>
				</ul>
			</nav>
	</header>
</div>