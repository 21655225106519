<app-header></app-header>
<div class="login_container">
	<div class="form_container">
		<div class="header_container">
			<div class="title">IT PARTICIPANTS</div>
			<img src="assets/logo_new.png" style="max-width: 30px;">
		</div>
		<div class="formEnSi">
			<div [ngSwitch]="verificationState" style="width: 80%; margin: auto;">
				<div *ngSwitchCase="'NotSent'">
					<div>
						<div class="verification-container">
							<input type="radio" id="phone" name="code_option" value="phone" checked>
							<label name="verification_by" for="phone">Verification code via SMS</label>
						</div>
<!--
						<div class="verification-container">
							<input type="radio" id="email" name="code_option" value="email">
							<label name="verification_by" for="email">Verification code via email</label>
						</div>
-->
					</div>

					<div class="btn_with_icon">
						<button class="btn_signin" (click)="goTo('signin')">Back</button>
						<button class="btn_otlogin" (click)="sendSMSCode()">Send</button>
					</div>
				</div>

				<div *ngSwitchCase="'Sent'">
					<form [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
						<div class="label">
							<label class="form-sub-title">Verification Code</label>
							<input required ngModel type="text" class="sign-input" formControlName="code" />
							<div *ngIf="submitted && verificationForm.controls.code.errors?.required" class="m-form-error error-e" style="display: block">Code is Required</div>
						</div>

						<div class="btn_with_icon">
							<button class="btn_otlogin">Accept</button>
						</div>

						<div class="link" (click)="sendSMSCode()">
							<span>Didn't get the code, resend it.</span>
						</div>
					</form>
				</div>

				<div *ngSwitchDefault>
					<div>DEFAULT</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>