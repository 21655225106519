import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { LoginResponse } from '../models/login-response.model';
import { NavBarData } from '../models/navbar-data.model';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root'
})
export class CookiesManagementService {

	constructor (private cookieService: CookieService, private appService:AppService) { }

	public deleteCookie(key: string) {
		this.cookieService.delete(key);
	}

	public setCookie(key: string, value: string, expired?: Date ) {
		if (value) {
			let expiration_date = expired ? expired : this.getExpirationDate();
			this.cookieService.set( key, value, expiration_date, '/', this.appService.getDomain() );
		}
	}

	public getCookie(key: string) {
		return this.cookieService.get( key );
	}

	public setLoginResponse(loginResponse: LoginResponse) {
		let sessionExpiration = new Date();
		sessionExpiration.setTime(loginResponse.sessionTimeout);

		this.setCookie(GlobalConstants.RESEARCH_APP_USER_ID, loginResponse.userId, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_USERNAME, loginResponse.username, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, loginResponse.sessionToken, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_FIRST_NAME, loginResponse.firstName, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_LAST_NAME, loginResponse.lastName, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_HOME_PATH, loginResponse.clientEvent, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_PREMIUM, loginResponse.isPremium.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_CLIENT_PREMIUM, loginResponse.isClientPremium.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_EXPERT_PREMIUM, loginResponse.isExpertPremium.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_CLIENT, loginResponse.isClient.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_EXPERT, loginResponse.isExpert.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_HOPS, loginResponse.hops, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_TIMEZONE, loginResponse.timeZone, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_EMAIL, loginResponse.emailAddress, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_AFFILIATE, loginResponse.affiliate, sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_NOT_READ, loginResponse.notificationNotRead.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_ADMIN, loginResponse.isAdmin.toString(), sessionExpiration);
		this.setCookie(GlobalConstants.RESEARCH_APP_PROFILE_PERCENT, loginResponse.profileNotificationPercent, sessionExpiration);

		this.setCookie(GlobalConstants.RESEARCH_PREMIUM_EXPERT_LIMIT, loginResponse.premiumExpertLimit);
		this.setCookie(GlobalConstants.RESEARCH_USER_VOICE_TOKEN, loginResponse.userVoiceToken);
		this.setCookie(GlobalConstants.RESEARCH_APP_USER_TYPE, loginResponse.userType);
		this.setCookie(GlobalConstants.RESEARCH_DEVICE, loginResponse.device);
		this.setCookie(GlobalConstants.RESEARCH_APP_FEE_DURATION, loginResponse.lengthPremiumFee);
		this.setCookie(GlobalConstants.RESEARCH_APP_PACKAGE_NAME, loginResponse.packageName);
		this.setCookie(GlobalConstants.RESEARCH_APP_PROFILE_ALERT, loginResponse.showProfileAlert.toString());
		this.setCookie(GlobalConstants.RESEARCH_APP_JIT_RECRUITER, loginResponse.isJitRecruiter.toString(), sessionExpiration);
	}

	public getNavBarLoginResponse() {
		var token = this.getCookie(GlobalConstants.RESEARCH_APP_TOKEN);
		var premiumUser = this.getCookie(GlobalConstants.RESEARCH_APP_PREMIUM);
		var userName = this.getCookie(GlobalConstants.RESEARCH_APP_USERNAME);
		var isAdmin = this.getCookie(GlobalConstants.RESEARCH_APP_ADMIN);
		var isJitRecruiter = this.getCookie(GlobalConstants.RESEARCH_APP_JIT_RECRUITER);

		let data: NavBarData = new NavBarData(token, premiumUser, userName, isAdmin, isJitRecruiter);
		return data;
	}

	public setVerificationLoginResponse(loginResponse: LoginResponse) {
		let sessionExpiration = new Date();
		sessionExpiration.setTime(loginResponse.sessionTimeout);
		this.setCookie(GlobalConstants.RESEARCH_APP_VERIFICATION_KEY, loginResponse.verificationKey, sessionExpiration);
	}

	public setLoginPostVerification(token: string) {
		this.logout();
		this.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, token);
	}

	private getExpirationDate() {
		let date = new Date();
		date.setTime( date.getTime() + (1000 * 60 * 60 * 24 * 14));	// two weeks
		return date;
	}

	public logout() {
		this.cookieService.deleteAll('/app/content', this.appService.getDomain());
		this.cookieService.deleteAll('/', this.appService.getDomain());
	}

}
