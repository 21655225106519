import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, searchTerm: string): string {
    if (!searchTerm) {
      return value;
    }
    const re = new RegExp(searchTerm, 'gi');
    return value.replace(re, `<span class="highlight">$&</span>`);
  }

}
