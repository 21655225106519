import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss']
})
export class StateSelectorComponent implements OnInit, OnChanges {

  states;
  selectedState;
  disabled: boolean;
  @Input() value;
  @Output() emitState: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
    this.states = this.getUsStates();
  }

  selectState() {
    let value;
    try {
      value = `${this.selectedState.name} (${this.selectedState.stateCode})`;
    } catch (e) {
      value = '';
    }
    this.emitState.emit(value);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.value) {
      let arrayValues = this.value.split("(");
      this.selectedState = { name: arrayValues[0].trim(), stateCode: arrayValues[1].substring(0, 2) };
    }
  }
  getUsStates() {
    return [
      { "name": "Alabama", "stateCode": "AL" },
      { "name": "Alaska", "stateCode": "AK" },
      { "name": "American Samoa", "stateCode": "AS" },
      { "name": "Arizona", "stateCode": "AZ" },
      { "name": "Arkansas", "stateCode": "AR" },
      { "name": "California", "stateCode": "CA" },
      { "name": "Colorado", "stateCode": "CO" },
      { "name": "Connecticut", "stateCode": "CT" },
      { "name": "Delaware", "stateCode": "DE" },
      { "name": "District Of Columbia", "stateCode": "DC" },
      { "name": "Federated States Of Micronesia", "stateCode": "FM" },
      { "name": "Florida", "stateCode": "FL" },
      { "name": "Georgia", "stateCode": "GA" },
      { "name": "Guam", "stateCode": "GU" },
      { "name": "Hawaii", "stateCode": "HI" },
      { "name": "Idaho", "stateCode": "ID" },
      { "name": "Illinois", "stateCode": "IL" },
      { "name": "Indiana", "stateCode": "IN" },
      { "name": "Iowa", "stateCode": "IA" },
      { "name": "Kansas", "stateCode": "KS" },
      { "name": "Kentucky", "stateCode": "KY" },
      { "name": "Louisiana", "stateCode": "LA" },
      { "name": "Maine", "stateCode": "ME" },
      { "name": "Marshall Islands", "stateCode": "MH" },
      { "name": "Maryland", "stateCode": "MD" },
      { "name": "Massachusetts", "stateCode": "MA" },
      { "name": "Michigan", "stateCode": "MI" },
      { "name": "Minnesota", "stateCode": "MN" },
      { "name": "Mississippi", "stateCode": "MS" },
      { "name": "Missouri", "stateCode": "MO" },
      { "name": "Montana", "stateCode": "MT" },
      { "name": "Nebraska", "stateCode": "NE" },
      { "name": "Nevada", "stateCode": "NV" },
      { "name": "New Hampshire", "stateCode": "NH" },
      { "name": "New Jersey", "stateCode": "NJ" },
      { "name": "New Mexico", "stateCode": "NM" },
      { "name": "New York", "stateCode": "NY" },
      { "name": "North Carolina", "stateCode": "NC" },
      { "name": "North Dakota", "stateCode": "ND" },
      { "name": "Northern Mariana Islands", "stateCode": "MP" },
      { "name": "Ohio", "stateCode": "OH" },
      { "name": "Oklahoma", "stateCode": "OK" },
      { "name": "Oregon", "stateCode": "OR" },
      { "name": "Palau", "stateCode": "PW" },
      { "name": "Pennsylvania", "stateCode": "PA" },
      { "name": "Puerto Rico", "stateCode": "PR" },
      { "name": "Rhode Island", "stateCode": "RI" },
      { "name": "South Carolina", "stateCode": "SC" },
      { "name": "South Dakota", "stateCode": "SD" },
      { "name": "Tennessee", "stateCode": "TN" },
      { "name": "Texas", "stateCode": "TX" },
      { "name": "Utah", "stateCode": "UT" },
      { "name": "Vermont", "stateCode": "VT" },
      { "name": "Virgin Islands", "stateCode": "VI" },
      { "name": "Virginia", "stateCode": "VA" },
      { "name": "Washington", "stateCode": "WA" },
      { "name": "West Virginia", "stateCode": "WV" },
      { "name": "Wisconsin", "stateCode": "WI" },
      { "name": "Wyoming", "stateCode": "WY" }
    ];
  }

}
