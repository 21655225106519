<!-- <div class="new_login_container">
    <div class="row">
         <div [ngClass]="loginFormContainer">
            <app-reset-password style="width: 100%; margin-left:20%;"></app-reset-password>
        </div>
        <div class="col-7 banner" [style.background]="'url(' + backgroundUrl + ')'" *ngIf="showImage">
            <div class="marketing-banner-text">
            </div>
        </div>
    </div>
</div> -->
<main class="main">
	<div class="container">
		<div class="forms" style="z-index: 10;">
			<div class="sign__blog">
                <app-reset-password [hideBrandAndLogo]="true" [hideHr]="true"></app-reset-password>
			</div>

		</div>

		<div class="panels__blog">

			<div class="panel left__panel">

				<div class="content">
					<div class="register-left">
						<img [src]="imgLogo" style="max-width: 80px;">
						<div>
							<span class="new_logo_text">{{brandName}}</span>
						</div>
					</div>
					<h3 class="panel__title">Recover Password</h3>
					<p class="panel__text" *ngIf="!isMobile">Forgot your password? No worries, we've got you covered. Please enter a new one.</p>

				</div>
				<img src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_Login_re_4vu2.svg"
					alt="" class="panel__img">
			</div>

			<div class="panel right__panel">

				<div class="content">

					<div class="register-left">
					</div>

				</div>
			</div>

		</div>

	</div>
</main>

