<p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [showClear]="false " [filter]="true"
			filterBy="name" placeholder="Select a Country" appendTo="body" [disabled]="disabled" (onChange)="selectCountry()">
	<ng-template pTemplate="selectedItem">
		<div class="country-item country-item-value" *ngIf="selectedCountryCode">
			<img [src]="selectedCountry.flag" style="width:20px">
			<div class="flag_label">{{selectedCountry.name}}</div>
		</div>
	</ng-template>
	<ng-template let-country pTemplate="item">
		<div class="country-item">
			<img [src]="country.flag" style="width:20px">
			<div class="flag_label">{{country.name}}</div>
		</div>
	</ng-template>
</p-dropdown>