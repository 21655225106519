import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../services/user.service";
import { GlobalConstants } from "../../../../common/global-constants";

@Component({
	selector: 'app-screening-viewer',
	templateUrl: GlobalConstants.GG(),
	styleUrls: ['./screening-viewer.component.scss']
})
export class ScreeningViewerComponent implements OnInit {

	questions = []

	constructor(private userService: UserService) { }

	ngOnInit(): void {
		this.userService.getScreening().subscribe((data: any) => {
			console.info("SCREENING DATA: " + data);

			if (data) {
				Object.entries(data).forEach((ent: any) => {
					let q: any = {};
					q.question = ent[1].question;
					q.answer = ent[1].answer;
					this.questions.push(q);
				})
			}
		});
	}

}
