import TransactionInterface from "./transaction-interface";

export class UserInfo {

	logged_with: string;
	industry: string;
	preferred_rate: string;
	permissions: string[];
	is_admin: boolean;
	is_root_admin: boolean;
	is_jit_recruiter: boolean;
	is_am: boolean;
	address: string;
	company: string;
	company_type: string;
	company_title: string;
	country: string;
	current_earning: number;
	email: string;
	email_address: string;
	first_name: string;
	gender: string;
	age: string;
	race: string;
	head_count: string;
	id: number;
	image: string;
	image_type: string;
	language: string;
	last_earning: number;
	last_name: string;
	linkedin: string;
	next_earning: number;
	normal_hourly_rate: number;
	phone_number: string;
	phone_verified: boolean;
	seniority: string;
	state: string;
	title_or_role: string;
	total_earning: number;
	user_name: string;
	zip_code: string;
	payment_method_email: string;
	payment_method_id: number;
	payment_method_type: string;
	always_skip: boolean;
	always_skip_date: string;
	always_skip_my_account: string;
	always_skip_projects: string;
	always_skip_profile: string;
	user_intercom_hash: string;
	transactions: TransactionInterface[];

	public constructor(data?: UserInfo) {
		if (data) {
			this.address = data.address;
			this.company = data.company;
			this.company_type = data.company_type;
			this.company_title = data.company_title;
			this.country = data.country;
			this.current_earning = data.current_earning;
			this.email_address = data.email_address;
			this.first_name = data.first_name;
			this.gender = data.gender;
			this.age = data.age;
			this.race = data.race;
			this.head_count = data.head_count;
			this.id = data.id;
			this.image = data.image;
			this.image_type = data.image_type;
			this.language = data.language;
			this.last_earning = data.last_earning;
			this.last_name = data.last_name;
			this.linkedin = data.linkedin;
			this.next_earning = data.next_earning;
			this.normal_hourly_rate = data.normal_hourly_rate;
			this.phone_number = data.phone_number;
			this.phone_verified = data.phone_verified;
			this.seniority = data.seniority;
			this.state = data.state;
			this.title_or_role = data.title_or_role;
			this.total_earning = data.total_earning;
			this.user_name = data.user_name;
			this.zip_code = data.zip_code;
			this.payment_method_email = data.payment_method_email;
			this.payment_method_id = data.payment_method_id;
			this.payment_method_type = data.payment_method_type;
			this.always_skip = data.always_skip;
			this.always_skip_date = data.always_skip_date;
			this.always_skip_my_account = data.always_skip_my_account;
			this.always_skip_projects = data.always_skip_projects;
			this.always_skip_profile = data.always_skip_profile;
			this.user_intercom_hash = data.user_intercom_hash;
		}
	}
}