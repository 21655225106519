import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AlertType, MessageService} from 'src/app/core/services/message.service';

@Component({
	selector: 'app-upload-file',
	templateUrl: './upload-files.component.html',
	styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

	constructor(private messageService: MessageService) { }

	emailsInfo;
	showSummary: boolean = false;
	@Output() emitEmails: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('inputFile') inputFile: ElementRef;

	ngOnInit() {
	}

	myUploader(event) {
		console.log('myUploader: ' + event);

		let fileReader = new FileReader();
		for (let file of event.files) {
			fileReader.readAsDataURL(file);
			fileReader.onload = function () { }
		}
	}

	getRowInfo(rows: any[], emailIndex: number, firstNameIndex: number) {
		let objects = new Array();

		rows.forEach((row, index) => {
			if (row && index > 0) {
				let arrayLine = row.split(",");

				let is_valid = false;
				let first_name = arrayLine[firstNameIndex].replace(/['"]+/g, '');
				let email = arrayLine[emailIndex].replace(/['"]+/g, '');
				if (this.validateEmail(email)) {
					is_valid = true;
				}
				objects.push({ 'email': email, 'firstName': first_name, 'valid': is_valid });
			}
		});

		let res = {
			validEmails: objects.filter((item: any) => item.valid == true),
			invalidEmails: objects.filter((item: any) => item.valid == false),
			total: objects.length
		}

		return res;
	}

	validateEmail(emailAdress) {
		const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return expression.test(emailAdress);
	}

	readCsv(target) {
		console.log("readCsv:", target);

		let files = target.files;
		let file = files[0];
		if (this.validCsv(file)) {
			let reader = new FileReader();
			reader.readAsText(file);
			reader.onload = (evt: any) => {
				let csv = evt.target.result;
				// console.log("CSV: ", csv);
				let rows = (<string> csv).split(/\r\n|\n/);
				// console.log("ROWS: ", rows);
				let headers = rows[0].split(",");
				// console.log("HEADERS>>>>>>>>>>>>>>>>>>>>>", headers);

				let emailIndex = 0;
				let firstNameIndex = 0;
				let validEmail = false;
				let validFirstName = false;

				headers.forEach((header, i) => {
					header = header.replace(/['"]+/g, '');
					if (header.toLowerCase() == "email") {
						validEmail = true;
						emailIndex = i;
					}
					if (header.toLowerCase() == "first_name") {
						validFirstName = true;
						firstNameIndex = i;
					}
				});

				if (validEmail && validFirstName) {
					this.emailsInfo = this.getRowInfo(rows, emailIndex, firstNameIndex);
				} else {
					this.messageService.showMessage(AlertType.Error, "Email and first_name are required in the CSV");
					this.clearSend();
				}
			}
		} else {
			this.messageService.showMessage(AlertType.Error, "File must be a csv file");
			this.clearSend();
		}
	}

	pepe(event) {
		console.log("pepe EVENTO:", event);
		this.readCsv(event.target)
	}

	validCsv(file) {
		return file.name.endsWith(".csv");
	}

	clearSend() {
		this.emailsInfo = undefined;
		this.inputFile.nativeElement.value = '';
	}

	sendEmails() {
		let req = { emails: this.emailsInfo.validEmails, clearSingle: false }
		this.emitEmails.emit(req);
		this.clearSend();
	}

}
