import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appDefaultImage]'
})

export class DefaultImageDirective implements AfterViewInit {

    constructor(private el: ElementRef) { }

    ngAfterViewInit(): void {
        let imgElement: HTMLImageElement = this.el.nativeElement;

        imgElement.addEventListener('error' , () => {
            imgElement.src = '/assets/profile_default.png'
        });
    }
}