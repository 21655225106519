import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { IntercomService } from 'src/app/services/intercom.service';
import { ThemeService } from 'src/app/services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private themeService: ThemeService,
    private httpService: HttpClient,
    private locationStrategy: LocationStrategy,
    private intercomService: IntercomService) { }

  private domain: string;

  setDomain(domain: string): void {
    this.domain = domain;
  }
  getDomain(): string {
    return this.domain;
  }

  public initApp() {
    let url = window.location.hostname.split(".");
    this.domain = url[url.length - 2] + "." + url[url.length - 1];
    console.log(">>>>>>>>>>>>>>>>>>>>> DOMAIN: ", this.domain);
    //this.themeService.getFileConfig(this.domain);
  }

  public initConfiguration(): Promise<any> {
    let url = window.location.hostname.split(".");
    let protocol = window.location.protocol;
    let entireHost = window.location.hostname;
    entireHost = `${protocol}//${entireHost}`;
    this.domain = url[url.length - 2] + "." + url[url.length - 1];
    console.log(">>>>>>>>>>>>>>>>>>>>> DOMAIN: ", this.domain);
    let urlConfig = `${entireHost}/images/config.json`;
    this.themeService.logoNavBar = `${entireHost}/images/logo.png`;
    this.themeService.logoLogin = `${entireHost}/images/login_logo.png`;
    this.themeService.favicon = `${entireHost}/images/favicon.ico`;
    this.themeService.imageLogin = `${entireHost}/images/login_image.png`;
    this.themeService.proj01 = `${entireHost}/images/proj_01.png`;
    this.themeService.proj02 = `${entireHost}/images/proj_02.png`;
    this.themeService.proj03 = `${entireHost}/images/proj_03.png`;
    this.themeService.proj04 = `${entireHost}/images/proj_04.png`;
    this.themeService.proj05 = `${entireHost}/images/proj_05.png`;


    return this.httpService.get(urlConfig).pipe(
      tap((data: any) => {
        this.themeService.setFileTheme(data.theme);
        this.themeService.setBrandName(data.brandName);
        //this.themeService.brandName.next(data.brandName);
        this.themeService.contentTheme = data.contentTheme;
      })
    ).toPromise();
  }

  getDomainWithoutDot() {
    let dom = this.domain.split('.');
    dom.pop();
    let res = "";
    dom.forEach(d => {
      res += d;
      res += '.';
    });
    res = res.substring(0, res.length - 1);
  }

  isMobile(): boolean {
    let is: boolean;
    let accesor = navigator.userAgent;
    if (accesor.toString().toLowerCase().includes("android") || accesor.toString().toLowerCase().includes("iphone") || accesor.toString().toLowerCase().includes("ipad")) {
      is = true;
    }
    return is;
  }

  updateState(newState: string, informIntercom: boolean = false, state = this.locationStrategy.getState(), title: string = '') {
    // console.log("EXEC UPDATE STATE APP SERVICE: ");
    // console.log("URL", newState);
    // console.log("STATE", state);
    // console.log("UPD INTERCOM", informIntercom);
    // console.log("TITLE", title);
    this.locationStrategy.pushState(state, title, newState, '');
    if (informIntercom) {
      this.intercomService.bootIntercom();
    }
  }

}

