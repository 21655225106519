import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneState } from 'src/app/core/models/phone-state.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'two-factor-phone',
	templateUrl: './two-factor-phone.component.html',
	styleUrls: ['./two-factor-phone.component.scss']
})
export class TwoFactorPhoneComponent implements OnInit, OnChanges, AfterViewChecked {

	constructor(private userService: UserService) { }

	@Output() phoneEmition: EventEmitter<any> = new EventEmitter<any>();
	@Output() actionEmition: EventEmitter<any> = new EventEmitter<any>();
	@Input() state: PhoneState;
	@Input() enablePhone: boolean = true;
	@Input() phone: any;

	SearchCountryField = SearchCountryField;
	PhoneNumberFormat = PhoneNumberFormat;
	CountryISO = CountryISO;
	phoneCode: string;

	canVerify: boolean = false;

	phoneNumber: string;
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required]),
	});
	originalPhone: string = '';
	ngAfterViewChecked(): void {

	}

	ngOnInit(): void {
		this.phoneForm.get("phone").disable();
		this.phoneForm.valueChanges.subscribe(data => {
			// console.log("CHANGE FORM:", data);
			// console.log("ORIGINAL", this.originalPhone);
			// console.log("VALUE FORM : ", data.phone.number);
			//console.log(this.originalPhone === data.phone.number);
			try {
				if (this.originalPhone === data.phone.number) {
					this.enablePhone = false;
				} else {
					this.enablePhone = true;
				}
			} catch (e) {

			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.originalPhone = this.phone;
		this.phoneForm.patchValue({
			phone: this.phone,
		});

		this.enablePhone ? this.phoneForm.get("phone").enable() : this.phoneForm.get("phone").disable();
	}

	editPhone() {
		this.canVerify = false;
		this.phoneForm.get("phone").enable();
	}

	emitPhone(newState: PhoneState) {
		// console.info("Phone New State:", newState);
		// console.info("EMITING PHONE:", this.phone);

		this.state = newState;
		this.phoneEmition.emit(this.phone);
	}

	sendPhoneSMS() {
		this.userService.sendPhoneSMS().subscribe(response => {
			// console.info("SMS RESPONSE: ", response);
			this.state = PhoneState.PENDING;
		}, error => {
			console.error("SMS ERROR RESPONSE: ", error);
		});
	}

	validateCode() {
		if (this.phoneCode) {
			this.userService.validatePhoneCode(this.phoneCode).subscribe(response => {
				// console.info("SMS VALIDATED RESPONSE: ", response);
				this.state = PhoneState.VERIFIED
			}, error => {
				console.error("SMS ERROR RESPONSE: ", error);
			});
		} else {
			console.info("Code is required");
		}
	}

	modifyPhone() {
		this.phoneForm.get("phone").enable();
		this.state = PhoneState.MISSING;
	}

	updatePhone() {
		let phone = this.phoneForm.get("phone").value.e164Number;

		this.userService.updateUserPhone({ phone_number: phone }).subscribe(res => {
			// console.log("RESPONSE: <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", res);
			this.state = PhoneState.MISSING;
			this.saveAction(phone);
			//this.phoneForm.get("phone").disable();
		});
	}

	cancelAction() {
		this.actionEmition.emit({ "action": "cancel" });
		this.state = PhoneState.MISSING;
	}

	saveAction(phone_number: string) {
		this.actionEmition.emit({ action: "accept", phone: phone_number });
	}

	editVerified() {
		this.state = PhoneState.MISSING;
		this.enablePhone = true;
		this.canVerify = false;
		this.phoneForm.get("phone").enable();
	}

}
