import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { BannerNotificationType } from 'src/app/modules/shared/components/error-banner/notification-banner.component';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { Subject } from 'rxjs';
import { Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { SignupResponse } from 'src/app/core/models/signup-response.model';
import { UserService } from 'src/app/services/user.service';
import { ForgotPasswordRequest } from 'src/app/core/models/forgot-request-model';
import { AlertType, MessageService } from 'src/app/core/services/message.service';
import { LoginWithTokenRequest } from 'src/app/core/models/login-with-token-request-model';
import { ThemeService } from 'src/app/services/theme.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	imgLogo: string;
	brandName: string;
	backgroundUrl: string = '/assets/server1.jpg';
	loginFormContainer: string = 'col-5 new_form_container';
	showImage: boolean = true;
	otlError: boolean;

	@Input('uponSignin') uponSignin: () => void;

	eventsSubject: Subject<NotificationData> = new Subject<NotificationData>();
	submitted: Boolean = false;
	passwordType: string = 'password';
	loginRedirectUrl: string = "";

	signinForm = this.formBuilder.group({
		email: '',
		pswd: '',
	}, { updateOn: 'submit' });

	constructor(private router: Router, private route: ActivatedRoute,
		private cookieService: CookiesManagementService, private formBuilder: UntypedFormBuilder,
		private messageService: MessageService, private authService: AuthService,
		private userService: UserService,
		private themeService: ThemeService,
		private breakpointObserver: BreakpointObserver) {
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(res => {
			if (res.matches) {
				this.showImage = false;
				this.loginFormContainer = 'col-12 new_form_container';
			} else {
				this.showImage = true;
				this.loginFormContainer = 'col-5 new_form_container';
			}
		});
		this.imgLogo = this.themeService.logoLogin;
		this.backgroundUrl = this.themeService.imageLogin;
		this.brandName = this.themeService.name;
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			console.log("params on signin: " + JSON.stringify(params));
			this.loginRedirectUrl = params.urlToRedirect;
			if (params.one_time_token) {
				this.authService.loginWithToken(new LoginWithTokenRequest(params.one_time_token)).subscribe(response => {
					this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, response.sessionToken);
					this.userService.setUserInfo(response);

					if (this.loginRedirectUrl) {
						console.info("this.loginRedirectUrl: " + this.loginRedirectUrl);
						this.goTo(this.loginRedirectUrl);
					} else {
						this.goTo(GlobalConstants.URL_PROJECTS);
					}
				});
			}
			if (params.recover_token) {
				this.authService.recoverToken = params.recover_token;
				this.router.navigate(["changePassword"]);
			}
		});

		let logged = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN);
		if (logged) {
			if (this.uponSignin == undefined) {
				this.goTo(GlobalConstants.URL_PROJECTS);
			} else {
				console.log('Already logged');
			}
		}
	}

	oneTimeLogin(): void {
		if (document.getElementsByClassName('emailname')[0]['value'].length > 3) {
			this.authService.oneTimeLogin(new ForgotPasswordRequest(document.getElementsByClassName('emailname')[0]['value'])).subscribe(data => {
				this.messageService.showMessageTitle("success", "", "If you are registered in the app, you will receive an email with further instructions");
				// if (data.exist != undefined && data.exist == false) {
				// 	this.messageService.showMessage(AlertType.Error, data.message);
				// } else {
				// 	this.messageService.showMessageTitle("success", "", "A message will be sent to your email address with further instructions");
				// }
			}, error => {
				this.messageService.showMessageTitle("error", "Internal Server Error", "");
			});
		} else {
			this.otlError = true;
		}
	}

	onSubmit(): void {
		this.submitted = true;

		if (this.signinForm.valid) {
			console.info("VALIDATION SUCCESS");

			this.route.queryParams.subscribe(params => {
				console.log("params on signin: " + JSON.stringify(params));
				this.loginRedirectUrl = params.urlToRedirect;
				this.authService.signin(this.signinForm.controls.email.value, this.signinForm.controls.pswd.value).subscribe(data => {
					let response = <SignupResponse>data;
					console.info("SIGNIN RESP: ", response);

					if ("TwoStepVerification" == response.sessionToken) {
						this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_USER_ID, response.id ? response.id.toString() : "");
						this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_VERIFICATION_KEY, response.verificationKey);
						this.goTo(GlobalConstants.URL_VERIFICATION_SLASH);
					} else {
						this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, response.sessionToken);
						this.userService.setUserInfo(data);

						if (this.loginRedirectUrl) {
							console.info("this.loginRedirectUrl: " + this.loginRedirectUrl);
							this.goTo(this.loginRedirectUrl);
						} else {
							this.goTo(GlobalConstants.URL_PROJECTS);
						}
					}
				}, error => {
					if (error.status == 511) {
						this.messageService.showTitleMessage(AlertType.Info, 'Account pending verification', 'Check your email for a confirmation link.');
					} else {
						this.messageService.showErrorMessage(error);
					}
				});
			});

		} else {
			this.signinForm.markAllAsTouched();
			console.info("Pending validate")
		}
	}

	togglePassword(): void {
		if (this.passwordType === 'password') {
			this.passwordType = 'text';
		} else {
			this.passwordType = 'password';
		}
	}

	goTo(url: any) {
		this.router.navigateByUrl(url);
	}

}
