import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ProposalService } from '../services/proposal.service';

@Injectable({
	providedIn: 'root'
})
export class WelcomeSurveyResolver implements Resolve<any> {

	constructor(private proposalService: ProposalService, private route: ActivatedRoute) { }
	resolve(): Observable<any> {
		return this.proposalService.getWelcomeSurvey();
	}
}