import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit {

	constructor() {
		console.log('Main layout constructor called');
	}

	ngOnInit() {
	}

}