import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { MessageService } from 'src/app/core/services/message.service';
import { ThemeService } from 'src/app/services/theme.service';
import { interval, Subscription } from "rxjs";
import { AppService } from 'src/app/core/services/app.service';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
	selector: 'alchemer-survey',
	templateUrl: './alchemer-survey.component.html',
	styleUrls: ['./alchemer-survey.component.scss']
})
export class AlchemerSurveyComponent implements OnInit {

	constructor(
		public sanitizer: DomSanitizer,
		private messageService: MessageService,
		private router: Router,
		private route: ActivatedRoute,
		private themeService: ThemeService,
		private appService: AppService,
		private cookieService: CookiesManagementService,
		private authService: AuthService) {

		this.imgLogo = this.themeService.logoLogin;
		this.themeService.brandName.subscribe(name => {
			this.brandName = name;
		});
	}

	imgLogo: string;
	brandName: string;
	urlSafe: any;
	subscription: Subscription;
	urlParam: string;
	userState: string;
	surveyError: boolean = false;
	information;
	accountCreated: boolean = false;

	ngOnInit(): void {
		let logged = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN);
		console.log("DATA", this.route.snapshot.data);
		this.information = this.route.snapshot.data.info[0];
		let info = this.route.snapshot.data.info[0];
		if (this.route.snapshot.data.info.isWelcome) {

			this.information = { subject: `Welcome` };
			var welcome_info = this.route.snapshot.data.info;
			let testUrl = `${welcome_info.survey_url}?is_welcome=${welcome_info.isWelcome}&domain=${welcome_info.domain_id}`;
			this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(testUrl);
		} else {
			if (info.url && info.url != '') {
				this.route.params.subscribe(params => {
					if (params.prop_id.startsWith('P-')) {
						if (logged) {
							this.userState = 'registered_logged';
						} else {
							this.userState = 'registered_no_log';
						}
					} else {
						if (info.account_already_created) {
							this.userState = 'registered_no_log';
						} else {
							this.userState = 'unregistered';
						}
					}
				});

				if (info) {
					let testUrl = `${info.url}?email=${info.email}&inquiry_id=${info.inquiry_id}&domain=${info.domain_id}`;
					this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(testUrl);
				} else {
					this.messageService.showMessageTitle("error", "", "Internal Server Error").then((result: any) => {
						this.goTo(GlobalConstants.URL_SIGNIN_SLASH);
					});
				}
			} else {
				this.surveyError = true;
			}
		}
	}

	onLoad(evt: any) {
		try {
			let redirect = evt.contentWindow.location.href;
			if (redirect.search(`${this.appService.getDomainWithoutDot()}`) > -1) {
				this.goTo(GlobalConstants.URL_PROJECTS);
			}
		} catch (error: any) {
		}
	}

	goSignIn() {
		this.goTo(GlobalConstants.URL_SIGNIN_SLASH);
	}

	goProjects() {
		this.goTo(GlobalConstants.URL_PROJECTS);
	}

	goTo(url: any) {
		this.router.navigateByUrl(url);
	}

	reloadAndCheckIfFinished(): void {
		this.subscription = interval(3000).subscribe(() => {
			console.log('Recursive function called: ' + $('html').html());
			if ($('html').html().indexOf('Thank You!') > 0) {
				this.subscription.unsubscribe();
				window.location.href = '/app/content/projects?showNextSteps=true';
			}
		});
	}

	oneClickSignUp() {
		this.authService.oneClickSignUp(this.information.email).subscribe(data => {
			this.accountCreated = true;
		});
	}

}
