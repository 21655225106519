import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import Swal from "sweetalert2";



@Injectable({
	providedIn: 'root'
})
export class MessageService {

	principalColor = "#1b548d";
	constructor(@Inject(DOCUMENT) private document: Document) { }

	updateColor() {
		this.principalColor = this.document.documentElement.style.getPropertyValue(`--primaryColor`);
	}

	showMessage(type: AlertType, message: string) {
		return this.showTimedMessage(type, null, message, 0, 'center');
	}

	showTitleMessage(type: AlertType, title: string, message: string) {
		return this.showTimedMessage(type, title, message, 0, 'center');
	}

	showErrorMessage(httpError: any) {
		let message = '';
		try {
			if ('error' in httpError) {
				if (typeof httpError.error === 'object') {
					if (Array.isArray(httpError.error)) {
						httpError.error.forEach(error => {
							if ('error' in error) {
								message += `${error.error} , `;
							}
							if ('message' in error) {
								message += `${error.message} , `;
							}
							if ('msg' in error) {
								message += `${error.msg} , `;
							}
						});
						message = message.substring(0, message.length - 2);
					}
					if ('error' in httpError.error) {
						message = httpError.error.error;
					}
					if ('message' in httpError.error) {
						message = httpError.error.message;
					}
					if ('msg' in httpError.error) {
						message = httpError.error.msg;
					}
				} else {
					message = httpError.error;
				}
			} else {
				if ('msg' in httpError) {
					message = httpError.msg;
				} else {
					if ('message' in httpError) {
						message = httpError.message;
					}
				}
				if (!message) {
					message = "Generic error";
				}
			}
		} catch (e) {
			//mensaje generico
			message = "An error occurred, please try again later";
		}
		Swal.fire({
			icon: 'error',
			title: "Error",
			text: message,
			confirmButtonColor: this.principalColor
		});
	}

	showTimedMessageProgressBar(type: AlertType, message: string, timer: number, pos: string) {
		this.updateColor();
		let title: string;
		let time: number;
		switch (type) {
			case AlertType.Error:
				title = "Error";
				break;
			case AlertType.Success:
				title = "Ok";
				break;
			case AlertType.Info:
				title = "Information";
				break;
		}
		let formatedHtml = `<div>${message}</div>
						<br>
						<div id="progress-bar" style="background-color:var(--primaryColor) height:15px; border:solid 0.5px var(--primaryColor); width:0px;  transition: width 0.5s ease-in-out;">
						</div>`
		return Swal.fire({
			icon: type,
			title: title,
			html: formatedHtml,
			timer: timer,
			position: pos as any,
			confirmButtonColor: this.principalColor,
			didOpen: () => {
				if (time != 0) {
					const progressBar = document.getElementById('progress-bar');
					let progress = 0;
					const timer = setInterval(() => {
						progress += 2.5;
						progress < 100 ? progressBar.style.width = `${progress}%` : undefined;
						if (progress === 1000) {
							clearInterval(timer);
							Swal.close();
						}
					}, 100);
				}
			}
		}).then(result => { return result });
	}

	showTimedMessage(type: AlertType, modalTitle: string, message: string, timer: number, pos: string) {
		this.updateColor();
		let time = timer;
		let formatedHtml = `<div>${message}</div>
						<br>
						<div id="progress-bar" style="background-color:var(--primaryColor) height:15px; border:solid 0.5px var(--primaryColor); width:0;  transition: width 0.5s ease-in-out;">
						</div>`

		let title: string;

		if (modalTitle) {
			title = modalTitle;
		} else {
			switch (type) {
				case AlertType.Error:
					title = "Error";
					break;
				case AlertType.Success:
					title = "Ok";
					break;
				case AlertType.Info:
					title = "Information";
					break;
			}
		}

		Swal.fire({
			icon: type,
			title: title,
			html: formatedHtml,
			timer: timer,
			position: pos as any,
			confirmButtonColor: this.principalColor,
			didOpen: () => {
				if (time > 0) {
					const progressBar = document.getElementById('progress-bar');
					let progress = 0;
					const timer = setInterval(() => {
						progress += 2.5;
						progress < 100 ? progressBar.style.width = `${progress}%` : undefined;
						if (progress === 1000) {
							clearInterval(timer);
							Swal.close();
						}
					}, 100);
				}
			}
		});
	}

	toastErrorMessage(text: string, timer = 1500) {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			text: text,
			showConfirmButton: false,
			timer: timer
		})
	}

	toastSuccessMessage(text: string, timer = 1500) {
		Swal.fire({
			position: 'top-end',
			icon: 'success',
			text: text,
			showConfirmButton: false,
			timer: timer
		})
	}

	showMessageTitle(type: any, title: string, message: string) {
		return Swal.fire({
			icon: type,
			title: title,
			text: message,
			confirmButtonColor: this.principalColor
		});
	}

	showConfirmMessage(title: string, text: string, btn_success_text: string = "Save") {
		return Swal.fire({
			title: title,
			text: text,
			allowEscapeKey: false,
			allowOutsideClick: false,
			showCancelButton: true,
			confirmButtonText: btn_success_text,
			confirmButtonColor: this.principalColor,
			cancelButtonColor: "red"
		}).then((result) => {
			return result;
		});
	}
	showConfirmHtmlMessage(title: string, html: string, btn_success_text: string = "Save", icon = undefined) {
		return Swal.fire({
			title: title,
			icon: icon,
			html: html,
			allowEscapeKey: false,
			allowOutsideClick: false,
			showCancelButton: true,
			confirmButtonText: btn_success_text,
			confirmButtonColor: this.document.documentElement.style.getPropertyValue(`--primaryColor`),
			cancelButtonColor: "red"
		}).then((result) => {
			return result;
		});
	}

	showConfirmMessageWithText(title: string, text: string, btnSuccessText: string, buttonCancelText: string) {
		return Swal.fire({
			title: title,
			text: text,
			showCancelButton: true,
			confirmButtonText: btnSuccessText,
			confirmButtonColor: this.document.documentElement.style.getPropertyValue(`--primaryColor`),
			cancelButtonText: buttonCancelText,
			cancelButtonColor: "red"
		}).then((result) => {
			return result;
		});
	}

	showToastTimedMessage(title: string ,icon: AlertType = AlertType.Info, position: MessagePosition = MessagePosition.Top, timer = 3000) {
		Swal.fire({
			toast: true,
			position: position,
		icon: icon,
			title: title,
			showConfirmButton: false,
			timer: timer,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			}
		});
	}
}
export enum MessagePosition {
	Top = 'top',
	TopStart = 'top-start',
	TopEnd = 'top-end',
	CenterStart = 'center-start',
	Center = 'center',
	CenterEnd = 'center-end',
	BottomStart = 'bottom-start',
	Bottom = 'bottom',
	BottomEnd = 'bottom-end'
}
export enum AlertType {
	Info = 'info',
	Error = 'error',
	Success = 'success',
	Warning = 'warning'
}


