import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SignupRequest } from '../models/signup-request.model';
import { HttpService } from './http.service';
import { SmsNotification } from '../models/sms-notification.model';
import { ForgotPasswordRequest } from '../models/forgot-request-model';
import { LoginWithTokenRequest } from '../models/login-with-token-request-model';
import { CookiesManagementService } from './cookies-management.service';

const apiUrl: string = env.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private httpClient: HttpService, private cookieService: CookiesManagementService) { }

	public recoverToken;

	public oneClickSignUp(email: string) {
		let action: string = `/one_click_signup`;
		return this.httpClient.post(`${apiUrl}${action}`, { "emailAddress": email });
	}

	public signin(email: string, password: string): Observable<any> {
		let action: string = '/signin';

		let formData: SignupRequest = new SignupRequest(email, password, 'web', false, '');
		return this.httpClient.post(apiUrl + action, formData);
	}

	public signup(formData: SignupRequest): Observable<any> {
		let action: string = '/new_signup';
		return this.httpClient.post(apiUrl + action, formData);
	}

	// public signup(email: string, password: string): Observable<any> {
	// 	let action: string = '/new_signup';

	// 	let formData: SignupRequest = new SignupRequest(email, password, 'web', true, '');
	// 	return this.httpClient.post(apiUrl + action, formData);
	// }

	public twoStepCode(code: string, user_id: string): Observable<any> {
		let action: string = '/verification/accept_code';

		let formData: SmsNotification = new SmsNotification(user_id, code);
		return this.httpClient.post(apiUrl + action, formData);
	}

	public recoverPassword(req: ForgotPasswordRequest): Observable<any> {
		let action: string = '/forgot/recover_password';
		return this.httpClient.post(apiUrl + action, req);
	}

	public changePassword(req: any): Observable<any> {
		let action = "/recover_password";
		return this.httpClient.post(apiUrl + action, req);
	}

	public oneTimeLogin(req: ForgotPasswordRequest): Observable<any> {
		let action: string = '/forgot/one_time_login';
		return this.httpClient.post(apiUrl + action, req);
	}

	public loginWithToken(req: LoginWithTokenRequest): Observable<any> {
		let action: string = '/one_time_login/with_login_token';
		return this.httpClient.post(apiUrl + action, req);
	}

	public logout(): Observable<any> {
		let action: string = '/new_logout';
		localStorage.removeItem("userInfo");
		this.cookieService.logout();
		return this.httpClient.get(apiUrl + action);
	}

	public confirmAccount(id, token): Observable<any> {
		let action: string = '/confirm_account';
		return this.httpClient.get(`${apiUrl}${action}/${id}/${token}`);
	}

}