<!-- <footer style="position: fixed; bottom: 0; width: 100%;" class="m-footer clear-fix"> -->
<footer class="m-footer clear-fix">
	<section class="m-wrapper clear-fix">
		<section class="one-of-five footer-links">
			<h2>Search Experts</h2>
			<nav>
				<a href="http://www.research_participants.com/areasDirectory/by-industry-sector">Industry Sectors</a>
				<a href="http://www.research_participants.com/areasDirectory/by-region-or-geographic-focus">Geographic Regions</a>
				<a href="http://www.research_participants.com/areasDirectory/by-investment-asset-class">Investment Asset Classes</a>
				<a href="http://www.research_participants.com/areasDirectory/by-profession-or-job-function">Expertise Index</a>
			</nav>
		</section>
		<section class="one-of-five footer-links expert-footer">
			<nav>
				<a href="http://www.research_participants.com/areasDirectory/by-profession-or-job-function">Professions</a>
				<a href="http://www.research_participants.com/areasDirectory/hot-areas">Hot Areas</a>
				<a href="http://www.research_participants.com/areasDirectory/by-research-or-academic-discipline">Research Disciplines</a>
				<a href="http://www.research_participants.com/areasDirectory">Directory</a>
			</nav>
		</section>
		<section class="one-of-five footer-links">
			<h2>Resources</h2>
			<nav>
				<a href="http://helpdesk.research_participants.com" target="_blank">Help Desk</a>
				<a href="http://helpdesk.research_participants.com/knowledgebase" target="_blank">Knowledge Base</a>
				<a href="http://helpdesk.research_participants.com/forums/133936-i-think-research_participants-should" target="_blank">Forums</a>
				<a href="http://blog.research_participants.com" target="_blank">Blog</a>
			</nav>
		</section>
		<section class="one-of-five footer-links">
			<h2>research_participants</h2>
			<nav>
				<a href="http://helpdesk.research_participants.com/knowledgebase/articles/117210-1-research_participants-overview" target="_blank">About</a>
				<a href="http://www.research_participants.com/team">Team</a>
				<a href="http://blog.research_participants.com/research_participants-news" target="_blank">News</a>
				<a href="http://helpdesk.research_participants.com/knowledgebase/articles/40927-contact-information" target="_blank">Contact</a>
			</nav>
		</section>
		<section class="one-of-five social-links">
			<a href="http://www.research_participants.com/home"><img class="footer-logo" src="http://s3.amazonaws.com/research_participants-resources/strangler_images/footer-logo.png"></a>
			<a href="http://www.linkedin.com/company/research_participants-inc." target="_blank"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/f-linkedin-icon.jpg"></a>
			<a href="http://twitter.com/research_participants" target="_blank"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/f-twitter-icon.jpg"></a>
			<a href="http://facebook.com/Z1ntr0" target="_blank"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/f-facebook-icon.jpg"></a>
			<a href="http://plus.google.com/u/0/b/106021465896399902285/106021465896399902285" target="_blank"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/f-gplus-icon.jpg"></a>
			<a href="http://blog.research_participants.com/feed/" target="_blank"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/f-rss-icon.jpg"></a>
			<p>Phone/Fax - <a href="tel:+18772367016">1-877-236-7016</a></p>
			<p>Email - <a href="mailto:business@research_participants.com">business@research_participants.com</a></p>
		</section>
	</section>
	<p class="copyright">Copyright 2010-2023 research_participants Inc. <span>|</span> 303 Wyman Street, Suite 300 Waltham, MA 02451 <span>|</span> <a href="http://helpdesk.research_participants.com/knowledgebase/articles/55644-privacy-policy" target="_blank">Privacy Policy</a> <span>|</span> <a href="http://helpdesk.research_participants.com/knowledgebase/articles/55643-terms-of-service" target="_blank">Terms of Service</a><span class="seals"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/comodo-secure-icon.png" class="comodo-icon"><a href="http://www.bbb.org/boston/business-reviews/online-social-media-networking/research_participants-inc-in-waltham-ma-138051" target="_blank"><img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/bbb-icon.png" class="bbb-icon"></a></span></p>
</footer>