<div class="m-header-container">
	<div class="m-notification notification-success" id="intercomSuccessDiv" [hidden]="!show.get('Success')">
		<h3><label id="intercomSuccessLabel">{{message['Success']}}</label><label id="intercomSuccessDetailLabel"></label></h3>
		<!--<span class="btn-bg"><a class="notification-btn" id="intercomSuccessAnchor">{{successButtonLabel}}</a></span>-->
		<a id="intercomSuccessCloseAnchor" class="notification-close" (click)="hideIt('Success')"></a>
	</div>
	<div class="m-notification notification-error" id="intercomErrorDiv" [hidden]="!show.get('Error')">
		<h3><label id="intercomErrorLabel">{{message['Error']}}</label><label id="intercomErrorDetailLabel"></label></h3>
		<!--<span class="btn-bg"><a class="notification-btn" id="intercomErrorAnchor">{{errorButtonLabel}}</a><span>-->
		<a id="intercomErrorCloseAnchor" class="notification-close" (click)="hideIt('Error')"></a>
	</div>
	<div class="m-notification notification-notif" id="intercomNotifDiv" [hidden]="!show.get('Warning')">
		<h3><label id="intercomNotifLabel">{{message['Warning']}}</label><label id="intercomNotifDetailLabel"></label></h3>
		<!--<span class="btn-bg"><a class="notification-btn" id="intercomNotifAnchor">{{warningButtonLabel}}</a></span>-->
		<a id="intercomNotifCloseAnchor" class="notification-close" (click)="hideIt('Warning')"></a>
	</div>
</div> <!-- .m-header-container -->