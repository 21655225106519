import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './core/interceptor/interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { routingComponents } from './routes/main-layouts.routes';
import { ModalModule } from './modules/shared/modal/modal.module';
import { AuthGuard } from './core/guards/auth.guard';
import { MatGridListModule } from '@angular/material/grid-list';
import { SpinnerComponent } from './root/pages/spinner/spinner.component';
import { HomeComponent } from './root/pages/home/home.component';
import { LoginComponent } from './root/pages/login/login.component';
import { RegisterComponent } from './root/pages/register/register.component';
import { ContentLayoutComponent } from './modules/content-layout/content-layout.component';
import { AppService } from './core/services/app.service';
import { VerificationComponent } from './root/pages/verification/verification.component';
import { ForgotComponent } from './root/pages/forgot/forgot.component';
import { RecoverPwComponent } from './root/pages/recover-pw/recover-pw.component';
import { UnsubscribeComponent } from './root/pages/unsubscribe/unsubscribe.component';
import { AlchemerSurveyComponent } from './root/pages/alchemer-survey/alchemer-survey.component';
import { ConfirmAccountComponent } from './root/pages/confirm-account/confirm-account.component';
import { environment } from 'src/environments/environment';
import { IntercomModule } from 'ng-intercom';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';

@NgModule({
	declarations: [
		AppComponent,
		routingComponents,
		SpinnerComponent,
		HomeComponent,
		LoginComponent,
		RegisterComponent,
		UnsubscribeComponent,
		ForgotComponent,
		VerificationComponent,
		ContentLayoutComponent,
		RecoverPwComponent,
		AlchemerSurveyComponent,
		ConfirmAccountComponent,
	],
	imports: [
		LayoutModule,
		SharedModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		ModalModule,
		RecaptchaFormsModule,
		RecaptchaModule,
		MatGridListModule,
		IntercomModule.forRoot({
			appId: environment.intercomKey,
			updateOnRouterChange: true
		})
	],
	providers: [
		AuthGuard,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeConfig,
			deps: [AppService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})



export class AppModule { }


export function initializeConfig(appService: AppService) {
	return () => appService.initConfiguration();
}
