<!-- <div class="new_login_container">
	<div class="row">
		 <div [ngClass]="loginFormContainer">
			<div class="logo_container">
				<img [src]="imgLogo" style="max-width: 80px;">
				<span class="new_logo_text">{{brandName}}</span>
			</div>
			<div class="new_info_container">
				<div [ngSwitch]="unsubscribeState">
					<div>
						<div class="item">
							<div class="new_form_sub_title">Email</div>
							<div class="fields" style="width: 100%;">
								<input pInputText type="email" class="new_input" [(ngModel)]="emailAddress" />
							</div>
						</div>

						<div class="item">
							<div class="new_form_sub_title" style="margin-top: 10px;">Select an unsubscribe reason</div>
							<select class="select-reason new_input" [(ngModel)]="unsubscribeReason">
								<option *ngFor="let item of reasons;" [value]="item.value">{{item.reason}}</option>
							</select>
							<div *ngIf="unsubscribeReason == 'other'" class="fields" style="margin-top: 5px; width: 100%;">
								<input pInputText type="text" class="new_input" [(ngModel)]="otherReason" />
							</div>
						</div>
					</div>
					<hr style="border: solid 1px gray">
					<div class="btn_with_icon">
						<button class="btn_signin" name="submitButton" (click)="unsubscribeEmail()">Unsubscribe</button>
					</div>

				</div>
			</div>
		</div>
		<div class="col-7 banner" [style.background]="'url(' + backgroundUrl + ')'" *ngIf="showImage">
			<div class="marketing-banner-text">
                
            </div>
		</div>
	</div>
</div> -->










<main class="main">
	<div class="container">
		<div class="forms">

			<div class="sign__blog">

				<form id="sign_form" class="signin">
					<h2 *ngIf="!isMobile" class="title"></h2>
					<div [ngClass]="fieldsWidth()">
						<div class="item">
							<div class="new_form_sub_title">Email</div>
							<div class="fields" style="width: 100%;">
								<input pInputText type="email" class="form-control emailname" [(ngModel)]="emailAddress" [ngModelOptions]="{standalone: true}"/>
							</div>
						</div>

						<div class="item">
							<div class="new_form_sub_title" style="margin-top: 10px;">Select an unsubscribe reason</div>
							<select class="select-reason new_input" [(ngModel)]="unsubscribeReason" [ngModelOptions]="{standalone: true}">
								<option *ngFor="let item of reasons;" [value]="item.reason">{{item.reason}}</option>
							</select>
							<div *ngIf="unsubscribeReason == 'Other (Fill in reason below)'" class="fields" style="margin-top: 5px; width: 100%;">
								<input pInputText type="text" class="form-control emailname" [(ngModel)]="otherReason"  [ngModelOptions]="{standalone: true}"/>
							</div>
						</div>
					</div>
					<div [ngClass]="fieldsWidth()">
						<input type="button" value="Unsubscribe" class="btn login nbtn" (click)="unsubscribeEmail()">
					</div>
				</form>

			</div>

		</div>

		<div class="panels__blog">

			<div class="panel left__panel">

				<div class="content">
					<div class="register-left">
						<img [src]="imgLogo" style="max-width: 80px;">
						<div>
							<span class="new_logo_text">{{brandName}}</span>
						</div>
					</div>
					<h3 class="panel__title" *ngIf="!isMobile">Unsubscribe</h3>
					<p class="panel__text" *ngIf="!isMobile">Please let us know why you would like to unsubscribe from our email service. Your feedback helps us improve our communications.
					</p>
				</div>
				<img src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_Login_re_4vu2.svg"
					alt="" class="panel__img">
			</div>

			<div class="panel right__panel">

				<div class="content">

					<div class="register-left">
					</div>

				</div>
			</div>

		</div>

	</div>
</main>