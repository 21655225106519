import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from '../../core/services/loader.service';
import { MessageService } from '../services/message.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

	private reqs = 0;
	private whitelist_code = [511];

	constructor(private loaderService: LoaderService, private messageService: MessageService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.includes("sync_to_vendor") && !req.url.includes('autocomplete')) {
			this.loaderService.show()
		}

		console.log(`I'm the intecerceptor - ${req.url}`);

		let jsonReq: HttpRequest<any> = req.clone({
			setHeaders: { 'Content-Type': 'application/json' }
		});

		this.reqs++;
		return next.handle(jsonReq).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.restartReqs();
				}
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				console.log("ERROR: ", error);
				this.restartReqs();
				this.loaderService.hide();

				const statusCode = error.status;
				if (this.whitelist_code.includes(statusCode)) {
					return throwError(error);
				}

				if (!req.url.includes("sync_to_vendor")) {
					this.messageService.showErrorMessage(error);
				}

				return throwError(error);
			})
			/*
						finalize(() => {
							this.loaderService.hide();
						})
			*/
		);
	}

	restartReqs() {
		this.reqs--;
		if (this.reqs <= 0) {
			this.loaderService.hide();
		}
	}
}
