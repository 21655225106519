import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IThemeProperties } from '../core/interfaces/itheme.interface';
import { THEMES } from '../core/theme/theme-config';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  logoHeaderRoute: string;
  logoNavBar: string;
  logoLogin: string;
  imageLogin: string;
  circleLogo: string;
  brandName: Subject<string> = new Subject<string>();
  favicon: string;
  contentTheme: string;
  name: string;

  proj01: string;
  proj02: string;
  proj03: string;
  proj04: string;
  proj05: string;

  originalThemeColor: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpService: HttpClient) {
  }

  setBrandName(name: string):void {
    this.brandName.next(name);
    this.name = name;
  }

  setTheme(name: string) {
    const theme: IThemeProperties = (THEMES as any)[name];
    Object.keys(theme).forEach((key: string) => {
      console.log(key);
      this.document.documentElement.style.setProperty(
        `--${key}`, (theme as any)[key]
      )
    });
  }

  setFileTheme(theme) {
    Object.keys(theme).forEach((key: string) => {
      this.document.documentElement.style.setProperty(
        `--${key}`, (theme as any)[key]
      )
    });
  }

  resetColor() {
    this.document.documentElement.style.setProperty(`--primaryColor`, this.originalThemeColor);
  }
  changePrincipalColor(color: string) {
    if (!this.originalThemeColor) {
      this.originalThemeColor = this.document.documentElement.style.getPropertyValue(`--primaryColor`);
    }
    this.document.documentElement.style.setProperty(`--primaryColor`, color);
  }

  setLogoHeader(file: string): void {
    this.logoHeaderRoute = "assets/" + file;
  }

  getFileConfig(domain: string) {
    let url = `../../assets/${domain}/config.json`;
    this.logoNavBar = `assets/${domain}/logo.png`; // max 70px height
    this.logoLogin = `assets/${domain}/login_logo.png`; // nuevo login
    this.favicon = `assets/${domain}/favicon.ico`;
    this.imageLogin = `/assets/${domain}/login_image.png`;
    this.circleLogo = `assets/${domain}/circle.png`;
    this.proj01 = `assets/${domain}/proj_01.png`;
    this.proj02 = `assets/${domain}/proj_02.png`;
    this.proj03 = `assets/${domain}/proj_03.png`;
    this.proj04 = `assets/${domain}/proj_04.png`;
    this.proj05 = `assets/${domain}/proj_05.png`;
    
    this.httpService.get(url).subscribe((data: any) => {
      this.setFileTheme(data.theme);
      this.brandName.next(data.brandName);
      this.contentTheme = data.contentTheme;
    });
  }
}
