export class InquiryStepOneRequest {
	recipientId: string | undefined;
	description: string;
	richDescription: string;
	emailAddress: string;
	password: string;
	secret: string;
	device: string;
	area: string;

    constructor (	description: string,
        emailAddress: string,
        password: string,
        secret: string,
        device: string,
        area: string) {

            this.description = description;
            this.emailAddress = emailAddress;
            this.password = password;
            this.secret = secret;
            this.richDescription = description;
            this.device = device;
            this.area = area;
        }    
}