import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { Subject, Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: []
})
export class NotificationBannerComponent implements OnInit {
	eventsSubscription!: Subscription;

	@Input()
	events!: Observable<NotificationData>;

	constructor( ) { }

	show: any = new Map([
        [BannerNotificationType.Error, false],
        [BannerNotificationType.Success, false],
        [BannerNotificationType.Warning, false]
    ]);

	message: any = new Map([
        [BannerNotificationType.Error, ''],
        [BannerNotificationType.Success, ''],
        [BannerNotificationType.Warning, '']
    ]);

	ngOnInit(): void {
		console.log('Initializing Noti Banner');
		this.eventsSubscription = this.events.subscribe((data) => this.onNotificationSend(data));
	}
	
	public onNotificationSend( event: any ): void {
		this.message[event.type] = event.message;
		this.show.set(event.type, true)
	}

	ngOnDestroy(): void {
		this.eventsSubscription.unsubscribe();
	}

	hideIt( type : string ): void {
		this.show.set(type, false);
	}
}

export enum BannerNotificationType {
    Error = 'Error',
    Success = 'Success',
	Warning = 'Warning'
}