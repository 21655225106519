import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpService } from "../core/services/http.service";

@Injectable({
	providedIn: 'root'
})
export class AutocompleteService {

	private apiUrl = '/api/recruiters';

	constructor(private httpService: HttpService) { }

	getSuggestions(type: AutocompleteType, keyword: string): Observable<string[]> {
		if (!keyword.trim()) {
			return of([]);
		}

		const url = `${this.apiUrl}/autocomplete/${type}`;
		console.info("URL", url);

		//const encodedKeyword = encodeURIComponent(keyword);
		return this.httpService.post(url, { "keyword": keyword });
	}

	getProjectsSuggestions(keyword: string): Observable<string[]> {
		return this.getSuggestions(AutocompleteType.Project, keyword);
	}

	getScreeningQuestionSuggestions(keyword: string): Observable<string[]> {
		return this.getSuggestions(AutocompleteType.ScreeningQuestions, keyword);
	}
}

export enum AutocompleteType {
	User = 'user',
	Project = 'project',
	Recruiters = 'recruiters',
	ScreeningQuestions = 'screening'
}
