<div class="new_login_container">
	<div class="row">
		 <div [ngClass]="loginFormContainer">
			<div class="logo_container">
				<img [src]="imgLogo" style="max-width: 80px;">
				<span class="new_logo_text">{{brandName}}</span>
			</div>
			<div class="new_info_container">
				<form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
					<div class="item">
						<label class="new_form_sub_title">Email</label>
						<input required ngModel email=true type="email" class="new_input" formControlName="email"
							name="email" />
						<div *ngIf="submitted && forgotForm.controls.email.errors?.required" class="m-form-error error-e input_error"
							style="display: block">Email is Required</div>
						<div *ngIf="submitted && forgotForm.controls.email.errors?.email" class="m-form-error error-e input_error"
							style="display: block">Enter a valid email</div>
					</div>
	
					<div style="margin-top: 15px;">
						<input class="btn_signin" name="submitButton" type="submit" value="Send Email">
					</div>
					<hr style="border: solid 1px gray">
                    <div class="btn_with_icon">
                        <button (click)="goTo('signin')" class="btn_createAccount">Back to Signin</button>
                    </div>
				</form>
			</div>
		</div>
		<div class="col-7 banner" [style.background]="'url(' + backgroundUrl + ')'" *ngIf="showImage">
			<div class="marketing-banner-text">
			</div>
		</div>
	</div>
</div>