import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { InquiryConversationListResponse } from 'src/app/core/models/inquiry-conversation-list-response.model';
import { InquiryConversationResponse } from 'src/app/core/models/inquiry-conversation-response.model';
import { InquiryTwoEventService } from 'src/app/core/services/inquiry-two-event.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './side-bar.component.html'
})
export class SideBarComponent implements OnInit {

	constructor( private inquiryTwoEventService: InquiryTwoEventService ) { }

	conversations: InquiryConversationResponse[] = [];
	showConversations: boolean = true;
	showConversationSection: boolean = false;
	activeSubject: string = '';

	ngOnInit(): void {
		this.inquiryTwoEventService.changeEmitted$.subscribe(data => {
			let response = <InquiryConversationListResponse> data;

			this.conversations = response.item;
			this.activeSubject = response.subject;
			this.showConversationSection = true;
		});
	}

}
