export class AreaRequest {
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	noteToresearch_participants: string;
	lead_source: string;
	retURL: string;
	oid: string;

	constructor(firstName: string, lastName: string, email: string, company: string, noteToresearch_participants: string) {
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.company = company;
		this.noteToresearch_participants = noteToresearch_participants;
		this.oid = '00Do0000000HaYb';
		this.retURL = 'https://www.research_participants.com/thanks-submit-inquiry';
		this.lead_source = 'Area page';
	}
}