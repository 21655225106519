import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { SignupResponse } from 'src/app/core/models/signup-response.model';
import { GlobalConstants } from 'src/app/common/global-constants';
import { CookiesManagementService } from 'src/app/core/services/cookies-management.service';
import { BannerNotificationType } from 'src/app/modules/shared/components/error-banner/notification-banner.component';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { AlertType, MessageService } from 'src/app/core/services/message.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ThemeService } from 'src/app/services/theme.service';
import { SignupRequest } from 'src/app/core/models/signup-request.model';
import { LoginWithTokenRequest } from 'src/app/core/models/login-with-token-request-model';
import { AppService } from 'src/app/core/services/app.service';
import { ForgotPasswordRequest } from 'src/app/core/models/forgot-request-model';
import { LocationStrategy } from '@angular/common';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss', '../login/login.component.scss',]
})
export class RegisterComponent implements OnInit {

	eventsSubject: Subject<NotificationData> = new Subject<NotificationData>();
	loginFormContainer: string = 'col-5 new_form_container';
	showImage: boolean = true;

	imgLogo: string;
	brandName: string;
	backgroundUrl: string = '/assets/server1.jpg';

	passwordType: string = 'password';
	confirmPasswordType: string = 'password';
	loginPwType: string = 'password';

	activeIndex: number = 1;
	indexes: number[] = [1, 2, 3];

	showForgot: boolean = false;
	signinFormTitle = 'Sign in';
	form_title = "Tell us about yourself";
	registration_country: string = 'US';
	registration_state: string | undefined;
	showLastPw: boolean = false;
	domainName: string;
	showStateSelector: boolean = true;
	selectedSeniority: any;
	seniorityOptions: any[] = [
		{ name: `Entry`, code: 1 },
		{ name: `Team Lead`, code: 2 },
		{ name: `Manager`, code: 3 },
		{ name: `Director`, code: 4 },
		{ name: `VP`, code: 5 },
		{ name: `C-Level/President`, code: 6 },
		{ name: `Board Member/Advisor`, code: 7 },
	];
	selectedIndustry: any;
	industryOptions: any[] = [
		{ "name": "Accounting", "code": 1 },
		{ "name": "Aerospace & Defense", "code": 2 },
		{ "name": "Advertising", "code": 3 },
		{ "name": "Automotive", "code": 4 },
		{ "name": "Business Services", "code": 5 },
		{ "name": "Banking, Financial Services, FinTech", "code": 6 },
		{ "name": "Construction & Building Materials", "code": 7 },
		{ "name": "Consulting", "code": 8 },
		{ "name": "Consumer Goods", "code": 9 },
		{ "name": "Education - For Profit", "code": 10 },
		{ "name": "Education - K-12", "code": 11 },
		{ "name": "Education - 2/4 year College or University", "code": 12 },
		{ "name": "Energy & Utilities", "code": 13 },
		{ "name": "Entertainment", "code": 14 },
		{ "name": "Food & Beverages", "code": 15 },
		{ "name": "Hospital & Health Care", "code": 16 },
		{ "name": "Insurance", "code": 17 },
		{ "name": "IT Services", "code": 18 },
		{ "name": "Government - Local", "code": 19 },
		{ "name": "Government - State", "code": 20 },
		{ "name": "Government - Federal", "code": 21 },
		{ "name": "Law & Legal Services", "code": 22 },
		{ "name": "Logistics & Supply Chain", "code": 23 },
		{ "name": "Manufacturing - Discrete", "code": 24 },
		{ "name": "Manufacturing - Continuous", "code": 25 },
		{ "name": "Market Research", "code": 26 },
		{ "name": "Media & News", "code": 27 },
		{ "name": "Medical Devices", "code": 28 },
		{ "name": "Non-profit organization", "code": 29 },
		{ "name": "On-Demand Delivery Services", "code": 30 },
		{ "name": "Pharmaceutical & Life Sciences", "code": 31 },
		{ "name": "Real Estate", "code": 32 },
		{ "name": "Retail/eCommerce", "code": 33 },
		{ "name": "Software & Technology", "code": 34 },
		{ "name": "Telecommunications", "code": 35 },
		{ "name": "Transportation (Airlines, Trains, Busses)", "code": 36 },
		{ "name": "Travel, Leisure, & Hospitality", "code": 37 },
		{ "name": "Venture Capital & Private Equity", "code": 38 },
		{ "name": "Wholesale", "code": 39 },
		{ "name": "Other", "code": 40 }
	];
	showOtherIndustryField: boolean = false;
	selectedAge: any;
	ages: any[] = new Array();
	over18Check: boolean = false;
	notMatchError: boolean = false;
	showRegImg: boolean = false;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private fBuilder: FormBuilder,
		private userService: UserService,
		private cookieService: CookiesManagementService,
		private authService: AuthService,
		private messageService: MessageService,
		private breakpointObserver: BreakpointObserver,
		private themeService: ThemeService,
		private appService: AppService,
		private locationStrategy: LocationStrategy) {
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(res => {
			if (res.matches) {
				this.showImage = false;
				this.loginFormContainer = 'col-12 new_form_container';
			} else {
				this.showImage = true;
				this.loginFormContainer = 'col-5 new_form_container';
			}
		});
		this.imgLogo = this.themeService.logoLogin;
		this.backgroundUrl = this.themeService.imageLogin;
		this.brandName = this.themeService.name;

	}
	isMobile: boolean = false;
	loginRedirectUrl: string = "";
	title = 'research_participants';
	submitted = false;
	submittedSignin: boolean = false;
	privacyLink = {
		href: 'https://www.research_participants.com/resources/research_participants-privacy-policy.pdf',
		label: 'Privacy Policy'
	};

	userAgreementLink = {
		href: 'https://www.research_participants.com/resources/research_participants-terms-of-service.pdf',
		label: 'User Agreement'
	};

	token = {
		value: '',
		valid: false
	};

	signupForm = this.formBuilder.group({
		firstName: ['', Validators.required],
		lastName: ['', Validators.required],
		age: ['18', Validators.required],
		currentJobTitle: '',
		jobDescription: '',
		seniority: '',
		industry: '',
		otherIndustry: '',
		companyName: '',
		state: '',
		zipCode: '',
		companyHeadcount: '',
		email: ['', [Validators.required, Validators.email]],
		pswd: ['', Validators.required],
		repswd: ['', Validators.required],
		acceptTerms: '',
		// captchaToken: ['', [Validators.required]]
	}, {
		updateOn: 'submit'
	});

	signinForm = this.fBuilder.group({
		em: ['', [Validators.required, Validators.email]],
		pw: ['', Validators.required]
	});
	otlError: boolean = false;
	isAccountForPayment: boolean = false;

	resolved(captchaResponse: string) {
		console.log(`Resolved response token: ${captchaResponse}`);
		//this.signupForm.controls.captchaToken.setValue(captchaResponse);
	}

	pageAnimations(): void {
		const signin__btn = document.querySelector("#signin__btn");
		const signup__btn = document.querySelector("#register__btn");
		const container = document.querySelector(".container");

		signup__btn.addEventListener('click', () => {
			this.signinForm.reset();
			this.signupForm.reset();
			this.appService.updateState('/signup');
			this.submittedSignin = false;
			this.showRegImg = true;
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 200);
			container.classList.add("signup-mode");
		});

		signin__btn.addEventListener('click', () => {
			this.otlError = false;
			this.showRegImg = false;
			this.signinForm.reset();
			this.signupForm.reset();
			this.showForgot = false;
			this.submitted = false;
			this.backToSignin();
			this.activeIndex = 1;
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 500);
			container.classList.remove("signup-mode");
		});

		const buttons = document.querySelector('#register__btn');

		buttons.addEventListener('click', function (e: any) {

			let x = e.clientX - e.target.offsetLeft;
			let y = e.clientY - e.target.offsetTop;

			let ripples = document.createElement('span');
			ripples.style.left = x + 'px';
			ripples.style.top = y + 'px';

			this.appendChild(ripples);

			setTimeout(() => {
				ripples.remove()
			}, 750);
		});
	}

	fieldsWidth(): string {
		let design = this.isMobile ? 'web_fields_full' : 'web_fields';
		return design;
	}

	populateAges() {
		var date = new Date();
		var year = date.getFullYear();
		var limit = year - 18;
		var min = limit - 82;
		var age = limit - min + 18;
		for (min; min <= limit; min++) {
			var ob = { year: min, age: age };
			this.ages.push(ob);
			age--;
		}
		this.ages.reverse();
	}

	registerClass() {
		if (this.isMobile) {
			//return `register_mobile step_${this.activeIndex}`;
			return `register step_${this.activeIndex}`;
		} else {
			return `register step_${this.activeIndex}`;
		}

	}

	ngOnInit(): void {

		this.isMobile = this.appService.isMobile();
		this.populateAges();
		this.domainName = this.appService.getDomain();
		this.route.data.subscribe(data => {
			if (data.fromPaymentSignup) {
				this.isAccountForPayment = true;		
			}
		});
		this.route.url.subscribe(url => {
			let path = url[0]?.path;
			if (path == 'register' || path == 'signup' || path == "payment-signup") {
				this.showRegImg = true;
				const container = document.querySelector(".container");
				container.classList.add("signup-mode");
			}
			if (path == 'forgot') {
				this.showForgotPage();
			}
		});
		let logged = this.cookieService.getCookie(GlobalConstants.RESEARCH_APP_TOKEN);
		if (logged) {
			this.goTo(GlobalConstants.URL_PROJECTS);
		}

		this.route.queryParams.subscribe(params => {
			console.log("params on signin: " + JSON.stringify(params));
			this.loginRedirectUrl = params.urlToRedirect;
			if (params.one_time_token) {
				this.authService.loginWithToken(new LoginWithTokenRequest(params.one_time_token)).subscribe(response => {
					this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, response.sessionToken);
					this.userService.setUserInfo(response);

					if (this.loginRedirectUrl) {
						console.info("this.loginRedirectUrl: " + this.loginRedirectUrl);
						this.goTo(this.loginRedirectUrl);
					} else {
						this.goTo(GlobalConstants.URL_PROJECTS);
					}
				});
			}
			if (params.recover_token) {
				this.authService.recoverToken = params.recover_token;
				this.router.navigate(["changePassword"]);
			}
		});
		this.pageAnimations();
	}

	validatePasswords() {
		console.log(this.signupForm);
		const password = this.signupForm.get('pswd').value;
		const confirmPassword = this.signupForm.get('repswd').value;
		if (password && confirmPassword && password !== confirmPassword) {
			this.notMatchError = false;
		} else {
			this.notMatchError = true;
		}
	}
	canRegister() {
		return !this.over18Check;
	}

	onSubmit(): void {
		console.info("ON SUBMIT");
		this.submitted = true;

		setTimeout(() => {
			if (this.signupForm.valid) {
				if (this.signupForm.controls.pswd.value == this.signupForm.controls.repswd.value) {
					this.notMatchError = false;
					console.info("Form valid valid: " + this.token);
					let formData: SignupRequest = new SignupRequest(this.signupForm.controls.email.value, this.signupForm.controls.pswd.value, 'web', true, '');
					formData.first_name = this.signupForm.controls.firstName.value;
					formData.last_name = this.signupForm.controls.lastName.value;
					formData.age = parseInt(this.signupForm.controls.age.value);
					formData.current_job_title = this.signupForm.controls.currentJobTitle.value;
					formData.seniority = this.signupForm.controls.seniority.value;
					formData.job_description = this.signupForm.controls.jobDescription.value;
					if (this.showOtherIndustryField) {
						formData.industry = this.signupForm.controls.otherIndustry.value;
					} else {
						formData.industry = this.signupForm.controls.industry.value;
					}
					formData.company_name = this.signupForm.controls.companyName.value;
					formData.country = this.registration_country;
					formData.state = this.signupForm.controls.state.value;
					formData.zipCode = this.signupForm.controls.zipCode.value;
					formData.accountForPayment = this.isAccountForPayment;
					this.authService.signup(formData).subscribe(data => {
						let response = <SignupResponse>data;
						console.info("SIGNUPRESP: ", response);
						this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, response.sessionToken);
						this.userService.setUserInfo(data);
						this.goTo(GlobalConstants.URL_PROJECTS);
					}, error => {
						console.info("Signup error: " + JSON.stringify(error));

						if (error.status == 511) {
							this.signupForm.reset();
							this.activeIndex = 1;
							const container = document.querySelector(".container");
							container.classList.remove("signup-mode");
							this.messageService.showTitleMessage(AlertType.Info, 'Verification required', 'Check your email for a confirmation link.');
						} else {
							let error_msg = error.error.briefError ? error.error.briefError : error.message;
							this.eventsSubject.next(new NotificationData(BannerNotificationType.Error, error_msg));
						}
					});
				} else {
					this.notMatchError = true;
					//this.messageService.showMessage(AlertType.Error, "Passwords do not match");
				}
			} else {
				console.log("ERRORES", this.signupForm);
				console.info("Form invalid");
				//this.activeIndex = 1;
				this.signupForm.markAllAsTouched();
				if (this.signupForm.controls.firstName.errors || this.signupForm.controls.lastName.errors || this.signupForm.controls.email.errors) {
					this.activeIndex = 1;
				}
			}
		}, 100);
	}

	goTo(url: any) {
		this.router.navigateByUrl(url);
	}

	showHidepw(which) {
		switch (which) {
			case 'pw':
				if (this.passwordType == 'password') {
					this.passwordType = 'text';
				} else {
					this.passwordType = 'password';
				}
				break;
			case 'confirm':
				if (this.confirmPasswordType == 'password') {
					this.confirmPasswordType = 'text';
				} else {
					this.confirmPasswordType = 'password';
				}
				break;
			case 'login':
				if (this.loginPwType == 'password') {
					this.loginPwType = 'text';
				} else {
					this.loginPwType = 'password';
				}
				break;

		}
	}
	captureState(state) {
		console.log("STATE", state);
		this.signupForm.patchValue({ state: state });
	}
	captureCountry(country) {
		console.log("country: ", country)
		this.registration_country = country;
		if (this.registration_country == 'US') {
			this.showStateSelector = true;
		} else {
			this.showStateSelector = false;
		}
	}
	switchIndex(index: number): void {
		this.activeIndex = index;
		this.activeClass(index);
	}
	activeClass(index: number): string {
		let style: string;
		if (index == this.activeIndex) {
			style = `step active`;
		} else {
			style = `step normal`;
		}
		return style;
	}
	changeStep(which: string): void {
		if (which == 'next') {
			if (this.activeIndex != 4) {
				if (this.activeIndex == 1) {
					if (this.signupForm.valid) {
						this.activeIndex++;
					} else {
						setTimeout(() => {
							if (this.signupForm.controls.firstName.valid &&
								this.signupForm.controls.lastName.valid &&
								this.signupForm.controls.email.valid) {
								this.activeIndex++;
								this.submitted = false;
							} else {
								this.submitted = true;
							}
						}, 10);
					}
				} else {
					this.activeIndex++;
				}
			}
		} else {
			if (which == 'prev') {
				if (this.activeIndex > 1) {
					this.activeIndex--;
				}
			} else {
				if (which == 'skip') {
					this.activeIndex = 4;
				}
			}
		}
		this.activeIndex == 4 ? this.form_title = 'Final Step' : this.form_title = 'Tell us about yourself';
	}

	oneTimeLogin(): void {
		if (document.getElementsByClassName('emailname')[0]['value'].length > 3) {
			this.otlError = false;
			this.authService.oneTimeLogin(new ForgotPasswordRequest(document.getElementsByClassName('emailname')[0]['value'])).subscribe(data => {
				this.messageService.showMessageTitle("success", "", "If you are registered in the app, you will receive an email with further instructions");
			}, error => {
				this.messageService.showMessageTitle("error", "Internal Server Error", "");
			});
		} else {
			this.otlError = true;
			this.signinForm.controls['em'].setErrors({ 'required': true });
		}
	}
	recoverPassword(): void {
		this.submittedSignin = true;

		if (this.signinForm.controls.em.valid) {
			console.info("VALIDATION SUCCESS");

			this.authService.recoverPassword(new ForgotPasswordRequest(this.signinForm.controls.em.value.toLowerCase())).subscribe(data => {
				console.info("recoverPassword ok");
				this.messageService.showMessageTitle("success", "", "A message will be sent to your email address with further instructions");
			}, error => {
				console.error("recoverPassword Error", error);
				this.messageService.showMessageTitle("error", "Internal Server Error", "");
			});

		} else {
			this.signinForm.markAllAsTouched();
			console.info("Pending validate")
		}
	}

	signin() {
		this.submittedSignin = true;

		if (this.signinForm.valid) {
			console.log(this.signinForm.controls);
			console.log(this.signinForm.controls.em.value);
			this.authService.signin(this.signinForm.controls.em.value.toLowerCase(), this.signinForm.controls.pw.value).subscribe(data => {
				let response = <SignupResponse>data;
				console.info("SIGNIN RESP: ", response);

				if ("TwoStepVerification" == response.sessionToken) {
					this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_USER_ID, response.id ? response.id.toString() : "");
					this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_VERIFICATION_KEY, response.verificationKey);
					this.goTo(GlobalConstants.URL_VERIFICATION_SLASH);
				} else {
					this.cookieService.setCookie(GlobalConstants.RESEARCH_APP_TOKEN, response.sessionToken);
					this.userService.setUserInfo(data);

					if (this.loginRedirectUrl) {
						console.info("this.loginRedirectUrl: " + this.loginRedirectUrl);
						this.goTo(this.loginRedirectUrl);
					} else {
						this.goTo(GlobalConstants.URL_PROJECTS);
					}
				}
			}, error => {
				if (error.status == 511) {
					this.messageService.showTitleMessage(AlertType.Info, 'Account pending verification', 'Check your email for a confirmation link.');
				} else {
					this.messageService.showErrorMessage(error);
				}
			});

		} else {
			this.signinForm.markAllAsTouched();
			console.info("Pending validate", this.signinForm)
			console.info("EM", this.signinForm.controls.em.errors)
			console.info("PW", this.signinForm.controls.pw.errors)
		}
	}

	getChange() {
		if (this.selectedIndustry.code == 40) {
			this.showOtherIndustryField = true;
		} else {
			this.showOtherIndustryField = false;
			this.signupForm.controls.industry.setValue(this.selectedIndustry.name);
		}
		// if (evt.toString().toLowerCase() == 'other') {
		// 	this.showOtherIndustryField = true;
		// } else {
		// 	this.showOtherIndustryField = false;
		// }
	}

	backToSignin() {
		this.submittedSignin = false;
		this.signinForm.reset();
		this.showForgot = false;
		this.signinFormTitle = 'Sign in';
		this.appService.updateState('/signin');
	}
	showForgotPage() {
		this.signinForm.reset();
		this.submittedSignin = false;
		this.showForgot = true;
		this.signinFormTitle = 'Recover Password';
		this.appService.updateState('/forgot');
	}
	showOrHide() {
		return this.showRegImg ? 'showReg' : 'hideReg'
	}
	selectSeniority() {
		this.signupForm.controls.seniority.setValue(this.selectedSeniority.name);
	}
	birthYearChange() {
		this.signupForm.controls.age.setValue(this.selectedAge.age);
	}
}
