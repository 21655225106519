import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-recover-pw',
  templateUrl: './recover-pw.component.html',
  styleUrls: ['../login/login.component.scss', './recover-pw.component.scss', '../register/register.component.scss']
})
export class RecoverPwComponent implements OnInit {
  backgroundUrl: string;
  loginFormContainer: string = 'col-5 new_form_container';
  showImage: boolean = true;
  brandName: string;
  imgLogo;
  isMobile: boolean = false;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private themeService: ThemeService,
    private appService: AppService
  ) {
    this.brandName = this.themeService.name;
    this.imgLogo = this.themeService.logoLogin;
    this.backgroundUrl = this.themeService.imageLogin;
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(res => {
      if (res.matches) {
        this.showImage = false;
        this.loginFormContainer = 'col-12 new_form_container';
      } else {
        this.showImage = true;
        this.loginFormContainer = 'col-5 new_form_container';
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = this.appService.isMobile();
  }


}
