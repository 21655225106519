<!-- <p-fileUpload name="myfile[]" [customUpload]="true" (uploadHandler)="myUploader($event)">
</p-fileUpload> -->

<label for="fileUploader" style="width: 100%;">
	<div style="cursor: pointer;" class="btn_csv">Select csv file</div>
</label>
<input #inputFile type="file" class="file-upload" (change)="pepe($event)" id="fileUploader" style="display: none;">
<div *ngIf="!emailsInfo">
	<div style="padding: 5px; margin-top:5px;">
		<span style="color: black;">Note: </span>
		The csv file must contain the columns "email" and "first_name"
	</div>
</div>
<div *ngIf="emailsInfo">
	<span class="link_summary" (click)="showSummary=true">
		Will send {{emailsInfo.validEmails.length}} valid emails of {{emailsInfo.total}}
		<i class="pi pi-search"></i>
	</span>
	<div class="send_cancel_container">
		<button pButton (click)="sendEmails()">Send</button>
		<button pButton class="p-button-danger" (click)="clearSend()">Clear</button>
	</div>
</div>

<p-dialog [(visible)]="showSummary" [breakpoints]="{'960px': '50vw'}" [style]="{width: '60vw'}" [draggable]="false"
	[resizable]="false" [modal]="true" *ngIf="emailsInfo">

	<ng-template pTemplate="header">
		<div class="row al100">
			<div class="col-12 centrado">
				<span class="modal_title">List of emails</span>
			</div>
		</div>
	</ng-template>
	<div class="row">
		<div class="col-6 center">
			<div class="row_subtitle">Valid emails</div>
			<div *ngFor="let valid of emailsInfo.validEmails">
				{{valid.email}}
				<i class="pi pi-check" style="color:green;"></i>
			</div>
		</div>
		<div class="col-6 center">
			<div class="row_subtitle">Invalid emails</div>
			<div *ngFor="let invalid of emailsInfo.invalidEmails">
				{{invalid.email}}
				<i class="pi pi-times" style="color:red;"></i>
			</div>
		</div>
	</div>

	<ng-template pTemplate="footer">
		<span (click)="showSummary=false" style="cursor: pointer;">Close</span>
	</ng-template>
</p-dialog>