import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

	@Input() maxDate = undefined;
	@Input() minDate = undefined;
	@Input() readOnlyInput = false;
	@Input() isDisabled: boolean = false;
	@Input() date: Date = new Date();

	@Output() dateEmition: EventEmitter<any> = new EventEmitter<any>();

	constructor() { }

	ngOnInit(): void {
	}

	emitDate(event: any) {
		if (event) {
			this.date = event;
			//this.date.setHours(12, 0, 0, 0);
			this.dateEmition.emit(this.date);
		}
	}

}
