import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../../core/services/loader.service'

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor(private loaderService: LoaderService) { }

  showSpinner: Subject<boolean> = this.loaderService.isLoading;
  ngOnInit(): void {
  }

}
