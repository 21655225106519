import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  brandName: string = '';
  actualYear: Date = new Date();

  constructor(private themeService: ThemeService) { }

  ngOnInit(): void {
    this.brandName = this.themeService.name;
  }

}
