<app-header></app-header>

<div class="content">
    <div class="row_container">
        <div class="interactive_container">
            <div class="title_container">
                <span class="title">
                    Where Businesses Come To Speak With IT Professionals
                </span>
                <br>
                <div style="margin-top: 15px">
                    <span class="subTitle">
                        Supporting you in research projects across the IT sector
                    </span>
                </div>
            </div>
            <div class="btns_container" style="margin-bottom: 10%">
                <button class="btn_home">I´M A RESEARCHER</button>
                <button class="btn_home">I´M AN IT PROFESSIONAL</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>