import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AreaManagementService } from '../core/services/area-management.service';

@Injectable({
	providedIn: 'root'
})
export class AreasResolver implements Resolve<any> {

	constructor(private areaService: AreaManagementService) { }

	resolve(activatedRoute: ActivatedRouteSnapshot): Observable<any> {
		let areaName = activatedRoute.paramMap.get('areaName') || '';
		if (areaName) {
			this.areaService.setArea(areaName);
			console.log("AreasResolver AREA > ", areaName);
		}

		return this.areaService.loadAreaPage(areaName);
	}
}