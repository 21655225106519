import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AccountConfirmResolver implements Resolve<any> {

    constructor(private authService: AuthService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        let id = route.paramMap.get('user_id');
        let token = route.paramMap.get('token');
        return this.authService.confirmAccount(id, token);
    }
}
