<!-- MAIN CONTENT -->
<div class="container-fluid">
	<div class="row">
		<div class="col-12" style="padding-left: 2.5%;">
			<h1>{{left_title}}</h1>
		</div>
		<div class="col-12" style="padding-left: 2.5%;">
			<span class="area-b-sub">{{left_subtitle}}</span>
		</div>
	</div>
	<div class="row" style="display: flex; flex-direction: row;">
		<div class="col-6" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
			<div style="width: 90%">
				<h2 class="form-title">Have a need? Explain it here</h2>
				
				 <form ngNoForm action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" id="areaForm" method="POST">
					<input type=hidden name="oid" value="00Do0000000HaYb">
					<input type=hidden name="retURL" value="https://www.research_participants.com/thanks-submit-inquiry">
					<input type=hidden name="lead_source" value="Area page">
					<div class="signin-container">
						<div class="inputs_container">
							<div class="inputs_nopad">
								<input required placeholder="First Name" class="area-input" name="first_name" id="first_name"  maxlength="40" size="20" type="text" style="width: 95%;"/>
								<div *ngIf="submitted && areaSubmitForm.controls.firstName.errors?.required" class="m-form-error" style="display: block; margin-left: 150px;">First Name is required</div>
								<div *ngIf="submitted && areaSubmitForm.controls.firstName.errors?.maxlength" class="m-form-error" style="display: block; margin-left: 150px;">First Name is too long (max 40 characters)</div>
								
								<input required placeholder="Last Name" class="area-input" name="last_name" id="last_name" maxlength="80" size="20" type="text" style="width: 95%;"/>
								<div *ngIf="submitted && areaSubmitForm.controls.lastName.errors?.required" class="m-form-error" style="display: block; margin-left: 150px; margin-top: 2.5%;">Last Name is required</div>
								<div *ngIf="submitted && areaSubmitForm.controls.lastName.errors?.maxlength" class="m-form-error" style="display: block; margin-left: 150px; margin-top: 2.5%;">Last Name is too long (max 80 characters)</div>
							</div>
							
							<div class="inputs_padding">
								<input required placeholder="Email" class="area-input" id="email" name="email" maxlength="80" size="20" type="text" style="width: 100%;"/>
								<div *ngIf="submitted && areaSubmitForm.controls.email.errors?.required" class="m-form-error" style="display: block; margin-left: 150px;">Email is required</div>
								<div *ngIf="submitted && areaSubmitForm.controls.email.errors?.maxlength" class="m-form-error" style="display: block; margin-left: 150px;">Email is too long (max 80 characters)</div>
	
								<input required placeholder="Company" class="area-input"  id="company" name="company"  maxlength="40" size="20" type="text" style="width: 100%;"/> 
								<div *ngIf="submitted && areaSubmitForm.controls.company.errors?.required" class="m-form-error" style="display: block; margin-left: 150px; margin-top: 2.5%;">Company is required</div>
								<div *ngIf="submitted && areaSubmitForm.controls.company.errors?.maxlength" class="m-form-error" style="display: block; margin-left: 150px; margin-top: 2.5%;">Company is too long (max 40 characters)</div>
							</div>
						</div>

						<div class="text_area_content">
							<textarea required placeholder="Note to research_participants" class="area-b-input txtarea" name="description"  minlength="50"></textarea>
							<div *ngIf="submitted && areaSubmitForm.controls.noteToresearch_participants.errors?.required" class="m-form-error" style="display: block; margin-left: 5px; margin-top: 2.5%;">Note to research_participants is required</div>
							<div *ngIf="submitted && areaSubmitForm.controls.noteToresearch_participants.errors?.minlength" class="m-form-error" style="display: block; margin-left: 5px; margin-top: 2.5%;">Note to research_participants is too short (min 50 characters)</div>
						</div>
						
						<div class="submit_content">
							<input type="submit" name="submit" class="area-b-submit orange-btn submitarea">
						</div>
					</div>
					<div class="row">
						<h3>Loved by Thousands</h3>
						<img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/area-loved-logos-inverted.png" alt="Loved by thousands logos" style="width: 100%;">
					</div>
				</form>
			</div>
		</div>
		<div class="col-6 img_content">
			<img src="https://s3.amazonaws.com/research_participants-resources/images/how_it_works.PNG" class="img_howto">
		</div>
	</div>
	<!-- <div class="row" class="area_description">
		<span class="quote">“</span>
		<span style="color: white;">{{textAI}}</span>	
		<span class="quote">”</span>
	</div> -->
	<div class="row">
		<p class="area-b-help">Need Help?
			<img src="http://s3.amazonaws.com/research_participants-resources/strangler_images/nav-phone-icon2.png" alt="A phone icon" height="17" width="17">
			<a href="tel:+18772367016">
				<span>1-877-236-7016</span>
			</a>
		</p>
	</div>
</div>

<!-- <jw-modal id="signin_modal">
	<signin-form [uponSignin]="uponSignin"></signin-form>
</jw-modal> -->
