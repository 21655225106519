<div class="header">
    <div class="content_logged_header">
        <img [src]="imgLogo" class="img_logo">
        <div class="header_options">
            <div class="item" *ngFor="let item of items">
                <span (click)="goTo(item.url)">
                    {{item.label}}
                </span>
            </div>
            <div class="item" *ngIf="user.is_root_admin">
                <span (click)="goTo('content/brandManage')">
                    BRAND MANAGE
                </span>
            </div>
            <div class="item" *ngIf="user.is_root_admin">
                <span (click)="goTo('content/users')">
                    USERS
                </span>
            </div>
            <div class="item" *ngIf="user.is_root_admin">
                <span (click)="goTo('content/claims')">
                    CLAIMS
                </span>
            </div>
        </div>
    </div>
    <!-- <div class="user_content" (click)="goTo('content/profile')"> -->
    <div class="user_content" (click)="showProfileMenu = !showProfileMenu">
        <span style="color: var(--white);">
            
        </span>
        <div class="user_icon">
            <!-- <img src="/api/profile/picture" height="40px" width="40px" alt="Profile Picture" /> -->
            <img [src]="getLinkPicture()" alt="Profile Picture" class="img_profile" />
        </div>
    </div>
</div>

<div class="usr_menu" *ngIf="showProfileMenu">
    <div class="content_usr_menu">
        <div class="row">
            <div class="col-12 item_menu_content">
                <img [src]="getLinkPicture()" alt="Profile Picture" class="img_profile img_usr_profile_img" />
                <div style="margin-left: 15px;" class="user_info_trunk">
                    <div>{{name}}</div>
                    <div style="font-size:small;">{{userFullInformation.company_title}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 separator"></div>
        </div>
        <div class="row row_usr_menu">
            <div class="col-12 item_menu_content pad_10" (click)="goTo('content/profile')">
                <div><i class="pi pi-user"></i></div>
                <div class="item_menu_usr">Profile</div>
            </div>
        </div>
        <div class="row row_usr_menu" (click)="displayChangePw = true">
            <div class="col-12 item_menu_content pad_10">
                <div><i class="pi pi-unlock"></i></div>
                <div class="item_menu_usr">Change Password</div>
            </div>
        </div>
        <div class="row row_usr_menu" (click)="logout()">
            <div class="col-12 item_menu_content pad_10">
                <div><i class="pi pi-sign-out"></i></div>
                <div class="item_menu_usr">Logout</div>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displayChangePw" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false"
	[resizable]="false" [modal]="true">
	<ng-template pTemplate="header">
        Change Password
    </ng-template>
	<app-reset-password [showOld]="true" [type]="'modal'" (emitAction)="captureModalAction($event)" [login_container_class]="'login_container_modal'" [form_container_class]="'form_container_modal'"></app-reset-password>
</p-dialog>