<div #form_container [ngClass]="dinamicClass" class="signin">
    <div class="logo_container" *ngIf="!isMobile && !hideBrandAndLogo">
        <img [src]="imgLogo" style="max-width: 80px;">
        <span class="new_logo_text dark">{{brandName}}</span>
    </div>
    <div class="new_info_container" style="display: flex; align-items: center; justify-content: center;">
        <div class="formEnSi" style="width: 40%;">
            <div>
                <div class="item" *ngIf="showOld" style="position:relative;">
                    <label class="new_form_sub_title">Old Password</label>
                    <input [type]="oldPwType" #pw3 [(ngModel)]="oldPw"
                        class="new_input">
                    <i [ngClass]="oldPwType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="togglePw('old')"></i>
                </div>

                <div class="item" style="position: relative;">
                    <label class="new_form_sub_title">New Password</label>
                    <input [type]="newPwType" #pw1 [(ngModel)]="password"
                        class="new_input">
                    <i [ngClass]="newPwType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="togglePw('new')"></i>
                </div>
    
                <div class="item" style="position: relative;">
                    <label class="new_form_sub_title">Confirm Password</label>
                    <input [type]="conPwType" #pw2 [(ngModel)]="rePassword"
                        class="new_input">
                    <i [ngClass]="conPwType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="togglePw('confirm')"></i>
                </div>

                <div class="error_message_container" *ngIf="canConfirm()">
                    <span class="error_message">{{pwError}}</span>
                </div>
                <hr style="border: solid 1px gray" *ngIf="!hideHr">
                <div class="btn_with_icon">
                    <button *ngIf="type == 'normal'" class="btn_signin" [disabled]="canConfirm()" (click)="confirmChangePassword()">Confirm</button>
                    <button *ngIf="type == 'modal'" class="btn_signin"  [disabled]="canConfirm()" (click)="confirmChangePwProfile()">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>