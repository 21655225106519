<div [ngSwitch]="state">
	<div *ngSwitchCase="'Missing'">
		<form #f="ngForm" [formGroup]="phoneForm">
			<div class="phone_container" style="display: flex;">
				<ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="['us', 'gb']" [enablePlaceholder]="true"
					[enableAutoCountrySelect]="true" [separateDialCode]="false" [customPlaceholder]="'+1 555-555-5555'"
					[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [searchCountryFlag]="true"
					name="phone" formControlName="phone"></ngx-intl-tel-input>
				<span class="action-container" *ngIf="enablePhone" style="align-items: center; justify-content: center;">
					<i class="pi pi-save green" pTooltip="Save Phone" tooltipPosition="top" (click)="updatePhone()" *ngIf="!f.form.controls['phone'].errors"></i>
					<i class="pi pi-save gray" *ngIf="f.form.controls['phone'].errors"></i>
				</span>
				<span class="action-container" *ngIf="phone && !enablePhone" style="align-items: center; justify-content: center;">
					<a class="verify-link" style="font-size: medium !important;" (click)="sendPhoneSMS()">Verify</a>
				</span>
			</div>
		</form>
	</div>
	<div *ngSwitchCase="'Pending'">
		<div class="phone_container">
			<input class="input-lenght" [maxLength]="15" [(ngModel)]="phoneCode" />
			<span class="action-container">
				<i class="pi pi-check green" (click)="validateCode()"></i>
				<i class="pi pi-times red" (click)="cancelAction()"></i>
			</span>
		</div>
		<div style="margin-top: 15px;">
			<span style="font-style: oblique;">
				Please enter the code sent to your phone
			</span>
		</div>
	</div>
	<div *ngSwitchCase="'Verified'">
		<form #f="ngForm" [formGroup]="phoneForm">
			<div class="phone_container">
				<ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="['us', 'gb']" [customPlaceholder]="'+1 555-555-5555'"
					[enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
					[separateDialCode]="false" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
					name="phone" formControlName="phone">
				</ngx-intl-tel-input>
				<span class="action-container" *ngIf="!enablePhone" style="align-items: center; justify-content: center;">
					<i class="pi pi-verified green" style="font-size:1.5rem;" pTooltip="Phone Verified" tooltipPosition="top"></i>
				</span>
				<span class="action-container" *ngIf="enablePhone" style="align-items: center; justify-content: center;">
					<i class="pi pi-save green" pTooltip="Save Phone" tooltipPosition="top" (click)="updatePhone()" *ngIf="!f.form.controls['phone'].errors"></i>
					<i class="pi pi-save gray" *ngIf="f.form.controls['phone'].errors"></i>
				</span>
			</div>
		</form>
	</div>
	<div *ngSwitchDefault>
		<div>DEFAULT</div>
	</div>
</div>