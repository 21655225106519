<main class="main">
	<div class="container">
		<div class="forms">

			<div class="sign__blog">

				<form id="sign_form" class="signin" [formGroup]="signinForm">
					<div class="profile__img__blog" *ngIf="!isMobile">
						<img src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_profile_pic_ic5t.svg"
							alt="" class="profile">
					</div>
					<h2 *ngIf="!isMobile" class="title">{{signinFormTitle}}</h2>
					<div [ngClass]="fieldsWidth()">
						<div class="form-group">
							<label for="em">Email</label>
							<input type="text" class="form-control emailname" formControlName="em" (keydown.enter)="showForgot ? recoverPassword() : ''"/>
							<div *ngIf="(submittedSignin && signinForm.controls.em.errors?.required) || otlError"
								class="m-form-error error-e input_error" style="display: block;">Email is Required</div>
							<div *ngIf="submittedSignin && signinForm.controls.em.errors?.email"
								class="m-form-error error-e input_error" style="display: block;">Enter a valid email
							</div>
							<!-- <div *ngIf="otlError" class="m-form-error error-e input_error" style="display: block; margin-top: -20px;">Email is Required</div> -->
						</div>
						<div class="form-group" *ngIf="!showForgot" style="position: relative;">
							<label for="pw">Password</label>
							<input [type]="loginPwType" class="form-control" formControlName="pw" (keydown.enter)="signin()"/>
							<i [ngClass]="loginPwType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="showHidepw('login')"></i>
						</div>
						<div *ngIf="submittedSignin && signinForm.controls.pw.errors?.required && !showForgot"
							class="m-form-error error-e input_error" style="display: block;">Password is Required
						</div>
					</div>
					<div [ngClass]="fieldsWidth()">
						<input type="button" value="Login" class="btn login nbtn" (click)="signin()"
							*ngIf="!showForgot">
						<input type="button" value="One Time Login" class="btn login nbtn btn_otlogin"
							(click)="oneTimeLogin()" *ngIf="!showForgot">

						<input type="button" value="Send Email" class="btn login nbtn" (click)="recoverPassword()"
							*ngIf="showForgot">
						<input type="button" value="Back to Signin" class="btn login nbtn"
							style="background-color: #333840;" (click)="backToSignin()"
							*ngIf="showForgot">
					</div>
					<div class="forgot" style="margin:0;">
						<span (click)="showForgotPage()"
							*ngIf="!showForgot">Forgot password?</span>

					</div>
				</form>

				<form [ngClass]="registerClass()" [formGroup]="signupForm">

					<!-- <div class="profile__img__blog">
                        <img src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_profile_pic_ic5t.svg" alt="" class="profile">
                    </div> -->
					<h4 class="title">{{form_title}}</h4>
					<div *ngIf="activeIndex == 1" class="form-cont">
						<p class="registration-sub-title">Welcome to our expert community! As you embark on your
							registration journey, we'd love to learn more about you.</p>
						<div class="form-group">
							<div>
								<label for="" style="margin-top:10px;">First Name <strong><span
											style="color:var(--primaryColor)">*</span></strong> </label>
								<input required type="text" class="form-control" formControlName="firstName" />
								<span class="m-form-error error-e input_error"
									*ngIf="submitted &&signupForm.controls.firstName.errors">First Name is
									required</span>
							</div>
							<div>
								<label for="" style="margin-top:10px;">Last Name <strong><span
											style="color:var(--primaryColor)">*</span></strong></label>
								<input type="text" class="form-control" formControlName="lastName" />
								<span class="m-form-error error-e input_error"
									*ngIf="submitted && signupForm.controls.lastName.errors">Last Name is
									required</span>
							</div>
						</div>

						<div class="form-group">
							<label for="" style="margin-top:10px;">Email <strong><span
										style="color:var(--primaryColor)">*</span></strong></label>
							<input type="email"
								[ngClass]="{ 'field_required': signupForm.controls.email.errors?.required }"
								class="form-control" formControlName="email" />
							<div *ngIf="submitted && signupForm.controls.email.errors?.required"
								class="m-form-error error-e input_error" style="display: block">Email is Required</div>
							<div *ngIf="submitted && signupForm.controls.email.errors?.email"
								class="m-form-error error-e input_error" style="display: block">Enter a valid email
							</div>
						</div>
						<div class="form-group">
							<span style='font-size:10px;'>By clicking the Next button below, you agree to the <a
									[href]="'https://' + domainName +'/terms-of-service'" target="_blank">Terms & Conditions</a> and <a
									[href]="'https://' + domainName +'/privacy-policy'" target="_blank">Privacy Policy</a>.</span>
						</div>

					</div>
					<div *ngIf="activeIndex == 2" class="form-cont">
						<p class="registration-sub-title">
							Welcome to our expert community! As you embark on your
							registration journey, we'd love to learn more about you.
						</p>

						<div class="form-group register-dropdown" style="margin-bottom: 10px;">
							<label for="" style="margin-top:10px;">Country of Residence</label>
							<app-country-selector style="width: 100%" [selectedCountryCode]="'US'"
								(changedCountryCode)="captureCountry($event)">
							</app-country-selector>
							<label for="" style="margin-top:10px;">State</label>
							<app-state-selector *ngIf="showStateSelector"
								(emitState)="captureState($event)"></app-state-selector>
							<input type="text" class="form-control" formControlName="state"
								*ngIf="!showStateSelector" />
						</div>

						<div class="form-group">
							<label for="" style="margin-top:10px;">Zip Code</label>
							<input type="text" class="form-control" formControlName="zipCode" />
						</div>

					</div>
					<div *ngIf="activeIndex == 3" class="form-cont">
						<p class="registration-sub-title">
							Your expertise is valuable, and this information will help us tailor your experience and
							connect you with relevant opportunities. Please provide details about your skills,
							experiences, and interests to ensure a personalized journey on our platform.
						</p>
						<div class="form-group">
							<label for="" style="margin-top:10px;">Current Job Title </label>
							<input type="text" class="form-control" formControlName="currentJobTitle" />
						</div>
						<div class="form-group register-dropdown">
							<label for="" style="margin-top:10px;">Seniority </label>
							<p-dropdown [options]="seniorityOptions" [(ngModel)]="selectedSeniority" [ngModelOptions]="{standalone: true}" optionLabel="name" [showClear]="false" [filter]="true"
								filterBy="name" placeholder="Select a Seniority" (onChange)="selectSeniority()" appendTo="body">
							</p-dropdown>
						</div>
						<div class="form-group register-dropdown">
							<label for="" style="margin-top:10px;">Industry</label>
							<p-dropdown [options]="industryOptions" [(ngModel)]="selectedIndustry" [ngModelOptions]="{standalone: true}" optionLabel="name" [showClear]="false" [filter]="true"
								filterBy="name" placeholder="Select an Industry" (onChange)="getChange()" appendTo="body">
							</p-dropdown>
							<label *ngIf="showOtherIndustryField" style="margin-top:10px;">Please Share Industry</label>
							<input class="form-control" *ngIf="showOtherIndustryField" type="text" formControlName="otherIndustry">
						</div>
					</div>
					<div *ngIf="activeIndex == 4" class="form-cont">
						<p class="registration-sub-title">
							To complete the registration process, we require you to enter your password. Please enter your password below and click 'Register'.
						</p>
						<div class="form-group" style="position:relative;">
							<label for="" style="margin-top:10px;">
								Password <strong><span style="color:var(--primaryColor)">*</span></strong>
							</label>
							<input [type]="passwordType" class="form-control" formControlName="pswd" id="pswd"/>
							<i [ngClass]="passwordType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="showHidepw('pw')"></i>
						</div>
						<span class="m-form-error error-e input_error" *ngIf="submitted &&signupForm.controls.pswd.errors">Password is required</span>
						<div class="form-group" style="position:relative">
							<label for="" style="margin-top:10px;">
								Confirm Password <strong><span style="color:var(--primaryColor)">*</span></strong>
							</label>
							<input [type]="confirmPasswordType" class="form-control" formControlName="repswd" id="repswd" />
							<i [ngClass]="confirmPasswordType == 'password' ? 'pi pi-eye eye_icon' : 'pi pi-eye-slash eye_icon' " (click)="showHidepw('confirm')"></i>
						</div>
						<span class="m-form-error error-e input_error" *ngIf="submitted && signupForm.controls.repswd.errors">Confirm Password is required</span>
						<span class="m-form-error error-e input_error" *ngIf="notMatchError">Passwords do not
							match</span>
						<div class="form-group register-dropdown">
							<div style="margin-top: 10px;">
								<p-checkbox [binary]="true" [(ngModel)]="over18Check"
									[ngModelOptions]="{standalone: true}"></p-checkbox>
								<label for="" style="margin-left: 5px;"> I'm over 18 years old. <strong><span
											style="color:var(--primaryColor)">*</span></strong></label>
							</div>

							<div *ngIf="over18Check">
								<label for="" style="margin-top:10px;">
									Please Select Birth Year
								</label>
								<p-dropdown [options]="ages" [(ngModel)]="selectedAge" [ngModelOptions]="{standalone: true}" optionLabel="year" placeholder="Select Birth Year" (onChange)="birthYearChange()" appendTo="body">
								</p-dropdown>
							</div>
						</div>


					</div>
					<!-- <input type="submit" value="Register" class="btn login"> -->
					<div
						style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
						<button *ngIf="activeIndex != 1" (click)="changeStep('prev')" class="btn login">Prev.</button>
						<button *ngIf="activeIndex != 4" (click)="changeStep('next')" class="btn login" style="margin-left:5px; margin-right:5px;">Next</button>
						<button *ngIf="activeIndex != 1 && activeIndex != 4" (click)="changeStep('skip')"
							class="btn login">Skip</button>
						<button *ngIf="activeIndex == 4" (click)="onSubmit()" class="btn login" [disabled]='canRegister()'>Register</button>
					</div>
				</form>

			</div>

		</div>

		<div class="panels__blog">

			<div class="panel left__panel">

				<div class="content">
					<div class="register-left">
						<img [src]="imgLogo" style="max-width: 80px;">
						<div>
							<span class="new_logo_text">{{brandName}}</span>
						</div>
					</div>
					<h3 class="panel__title" *ngIf="!isMobile">New here?</h3>
					<p class="panel__text" *ngIf="!isMobile">Explore endless opportunities with us. Join now and unlock your potential!
					</p>
					<button class="btn transparent" id="register__btn">Register</button>

				</div>
				<img *ngIf="!showRegImg" src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_Login_re_4vu2.svg"
					alt="" class="panel__img">
			</div>

			<div class="panel right__panel" [ngClass]="showOrHide()">

				<div class="content">

					<div class="register-left">
						<img *ngIf="showRegImg"  [src]="imgLogo" style="max-width: 80px;">
						<div>
							<span class="new_logo_text">{{brandName}}</span>
						</div>
					</div>

					<h3 class="panel__title">Already have account?</h3>
					<!-- <p class="panel__text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex fuga minima iure optio repudiandae ipsum?</p> -->
					<button class="btn transparent" id="signin__btn">Sign in</button>

				</div>
				<img *ngIf="showRegImg" src="https://raw.githubusercontent.com/Uzcoin404/Register-page/9efae3a1f7178386748b478ff47cb3bb118a784e/img/undraw_authentication_fsn5.svg"
					alt="" class="panel__img">
			</div>

		</div>

	</div>
</main>