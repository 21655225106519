<!--MOBILE VIEW-->
<div *ngIf="isMobile">
    <div *ngIf="isMobile">
        <div style="display: none;">
            <app-logged-header></app-logged-header>
        </div>
        <div class="nav_mobile">
            <i class="pi pi-bars icon_menu_mobile" (click)="showMenu()"></i>
            <div class="bg_logo"></div>
        </div>
    </div>
    <div class="content_nobg">
        <div class="menu" #menu [ngClass]="displayMenu ? 'showSidebar' : 'hideSidebar'">
            <div>
                <div class="item" *ngFor="let item of items" (click)="goTo(item.url)">
                    <span>
                        {{item.label}}
                    </span>
                </div>
            </div>

        </div>
        <div [ngClass]="isMobile ? 'content_mobile' : ''">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer></app-footer>
</div>


<!--WEB VIEWS-->
<div *ngIf="!isMobile">
    <div #webContent [ngSwitch]="theme">
        <div *ngSwitchCase="1">
            <app-logged-header></app-logged-header>
            <div class="content_nobg">
                <div class="menu" #menu [ngClass]="displayMenu ? 'showSidebar' : 'hideSidebar'">
                    <div>
                        <div class="item" *ngFor="let item of items" (click)="goTo(item.url)">
                            <span>
                                {{item.label}}
                            </span>
                        </div>
                        <div class="item" (click)="goTo('content/profile')">
                            <span>
                                Profile
                            </span>
                        </div>
                    </div>
                    <div class="item" style="border-bottom: 0; border-top: solid 2px white;" (click)="goTo('/logout')">
                        <span>
                            Logout
                        </span>
                    </div>
                </div>
                <div [ngClass]="isMobile ? 'content_mobile' : ''">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </div>

        <div *ngSwitchCase="2">
            <div class="nav_mobile">
                <i class="pi pi-bars icon_menu_mobile" (click)="showMenu()"></i>
                <div class="bg_logo"></div>
            </div>
            <div class="content_nobg" style="min-height: calc(100vh - 120px);">
                <div class="menu" #menu [ngClass]="displayMenu ? 'showSidebar' : 'hideSidebar'">
                    <div>
                        <div class="item" *ngFor="let item of items" (click)="goTo(item.url)">
                            <span>
                                {{item.label}}
                            </span>
                        </div>
                    </div>

                </div>
                <div class="content_mobile">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displayChangePw" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false"
    [resizable]="false" [modal]="true">
    <ng-template pTemplate="header">
        Change Password
    </ng-template>
    <app-reset-password [type]="'modal'" (emitAction)="captureModalAction($event)" [showOld]="true"
        [login_container_class]="'login_container_modal'"
        [form_container_class]="'form_container_modal'"></app-reset-password>
</p-dialog>





