<app-header></app-header>
<div class="login_container">
	<div class="form_container" style="background-color: #efefef;" *ngIf="!surveyError ; else error">
		<div *ngIf="urlSafe" class="formEnSi">
			<div class="msg_container" [ngSwitch]="userState" *ngIf="userState && userState == 'unregistered'">
				<div *ngSwitchCase="'unregistered'">
					<div *ngIf="!accountCreated">
						<span>
							Welcome to our platform! We're thrilled to have you here. Ready to share your thoughts and
							make a difference? Take our quick survey now and let your voice be heard! Creating an
							account is as easy as a single click. Let's get started together!
						</span>
						<input type="button" value="Create Account" class="btn login nbtn" (click)="oneClickSignUp()">
					</div>
					<div *ngIf="accountCreated">
						<span>Congratulations! You've successfully registered on our platform. Welcome aboard! Please
							check your email</span>
					</div>
				</div>
				<!-- <div *ngSwitchCase="'registered_no_log'">
					<span>
						Welcome back! Ready to dive back in? Sign in now to access your account and pick up where you
						left off. Let's get started!
					</span>
					<input type="button" value="Sign in" class="btn login nbtn" (click)="goSignIn()">
				</div>
				<div *ngSwitchCase="'registered_logged'">
					<span>
						You're all set! Explore your projects and make progress effortlessly. Click here to dive into
						your projects and start creating!
					</span>
					<input type="button" value="Go to projects" class="btn login nbtn" (click)="goProjects()">
				</div> -->
			</div>
			<div class="msg_container" style="border: none; background-color: transparent;">
				<h3>
					{{information.subject}}
				</h3>
				
			</div>
			<iframe #iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe" (load)="onLoad(iframe)"></iframe>
		</div>
	</div>
	<ng-template #error>
		<div class="msg_container">
			Something went wrong, please try again later. 
		</div>
	</ng-template>
</div>
<app-footer></app-footer>