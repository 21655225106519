<div *ngIf="cols && data && data.length > 0 ; else noData">
    <p-table [columns]="cols" [value]="data" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.header">
                        <ng-container *ngSwitchCase="'Date'">
                            {{ rowData[col.field] | date: 'MM-dd-yyyy' }}
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            {{ rowData[col.field] }}
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<ng-template #noData>
    <p>There's no information to be loaded.</p>
</ng-template>